@import "src/style/partials";

.container {
  background: white;
  // TODO : remettre s'il y a le prix
  //height: 75px
  // TODO : enlever s'il y a le prix
  height: auto;
  position: fixed;
  bottom: 0;
  justify-content: space-between;
  align-items: center;
  font-size: 30px;
  font-weight: $font-semi-bold;
  //padding: 0 100px 0 20px // TODO : remettre s'il y a le prix
  padding: 20px; // TODO : enlever s'il y a le prix
  box-sizing: border-box;
  box-shadow: 10px 0 10px 0 rgba(0,0,0,0.09);
  display: none;
  // because position fixed is only relative to the window
  width: calc(100% - #{$sidebar-width});
  @media #{$media-tablet-portrait} {
    width: 100%; }
  @media #{$media-max-width} {
    display: flex; }
  @media #{$media-phone} {
    height: auto;
    padding: 10px 20px; } }
