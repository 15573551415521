@import "src/style/partials";

%container {
  max-width: 1200px;
  //align-items: center
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column; }

.main-container {
  @extend %container;
  align-items: center; }

.container-chart {
  @extend %container; }

.container-table {
  background: white;
  border-top: 1px solid $color-border;

  &__wrapper {
    @extend %container; } }

.dataStudio {
  max-width: 1200px;
  width: 100%;
  border: 2px solid $color-border;
  border-radius: 6px;
  overflow: hidden;

  & .dataStudio__inner {
    width: 100%;
    position: relative;
    overflow: hidden;
    padding-top: 80%;
    height: 0;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; } } }
