@import "src/style/partials";

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
  text-align: center;
  img {
    width: 150px;
    margin-bottom: 50px; }
  form {
    min-width: 300px;
    max-width: 400px;
    height: auto;
    label {
      width: 100%; } }
  button {
    margin: 50px 0 10px; }
  a {
    font-size: small;
    &:hover {
      text-decoration: underline; } } }
