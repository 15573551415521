@import "src/style/partials";

.container {
  width: 100%;
  padding-bottom: 20px;
  // Header
  >div {
    @media #{$media-tablet-portrait} {
      &:first-child {
        display: none; } } }

  &__logo {
    position: absolute;
    top: 260px;
    left: 20px;
    @media #{$media-max-width} {
      width: 100%;
      display: flex;
      justify-content: center;
      top: 20px;
      left: 0; }
    @media #{$media-tablet-portrait} {
      display: none; }
    img {
      width: 120px; } } }

.free {
  margin: 20px auto 50px auto;
  width: max-content;
  padding: 2px 10px;
  box-sizing: border-box;
  border-radius: 6px;
  background: #28a59c1f;
  color: $color-complementary;
  font-size: 12px; }

.loading {
  height: 100vh; }
