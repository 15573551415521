@import "src/style/partials";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.label {
  font-weight: $font-medium;
  margin-bottom: 5px;
}

.textarea {
  box-sizing: border-box;
  width: 100%;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: inherit;
  border: 1px solid $color-light-grey;
  color: inherit;
  border-radius: 6px;
  resize: none;
  &::placeholder {
    opacity: 0.5;
  }
  &:focus {
    outline: 0;
    border: 1px solid $color-grey;
  }

  &--error {
    border: 1px solid $color-primary;
    &:focus {
      border: 1px solid $color-primary;
    }
  }
}

.error {
  align-self: flex-end;
  color: $color-primary;
}
