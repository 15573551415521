@charset "UTF-8";
.Toastify__toast {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.Toastify__toast--success {
  background: #27A49B; }

.Toastify__toast--error {
  background: #F24D4B; }

.wrapper {
  background: white;
  padding: 20px 0 50px 0; }
  @media (max-width: 1190px) {
    .wrapper {
      padding: 20px 20px 100px 20px; } }

.container {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 80px;
  max-width: 920px;
  margin: 0 auto; }
  @media (max-width: 979px) {
    .container {
      grid-gap: 50px;
      grid-template-columns: 1fr;
      grid-template-rows: 200px 1fr; } }
  @media (max-width: 979px) {
    .container__image {
      width: 100%;
      height: 100%; } }
  .container__desc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start; }
    .container__desc .desc__title {
      margin-bottom: 20px; }
    .container__desc .desc__list {
      padding-left: 1em;
      margin-left: 0;
      list-style: none; }
      .container__desc .desc__list > li {
        text-indent: -1em; }
        .container__desc .desc__list > li:not(:last-child) {
          margin-bottom: 5px; }
        .container__desc .desc__list > li:before {
          content: "•";
          margin-right: 10px;
          color: #F24D4B; }
    .container__desc .desc__sentence {
      margin-top: 10px;
      font-weight: 500;
      font-size: 18px;
      color: #27A49B;
      text-align: center;
      align-self: center; }
