@import "src/style/partials";

.container {
  box-sizing: border-box;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.14);
  padding: 30px 100px;
  background: $color-primary;
  color: white;
  width: 100%;
  min-height: 150px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  border-radius: 6px;

  p {
    font-size: 21px;
    font-weight: $font-semi-bold;
    line-height: 1.8;
  }

  button {
    min-width: max-content;
    margin-left: 20px;
    color: $color-primary;
    background: white;
    svg {
      fill: $color-primary;
    }
  }
}

@media #{$media-max-width} {
  .container {
    p {
      font-size: 18px;
    }
  }
}

@media #{$media-tablet-landscape} {
  .container {
    flex-direction: column;
    padding: 30px;
    p {
      text-align: center;
      margin-bottom: 20px;
    }
    button {
      margin-left: 0;
    }
  }
}
