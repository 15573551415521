@import "src/style/partials";

.container {
  box-sizing: border-box;
  font-size: 12px;
  color: $color-light-grey;
  fill: $color-light-grey;
  font-weight: $font-regular;
  height: 60px;
  display: flex;
  align-items: center;
  max-width: $max-width;
  margin: 0 auto;
  width: 100%;
  @media #{$media-max-width} {
    padding: 0 20px; }

  %back {
    cursor: pointer;
    display: flex;
    align-items: center;
    >svg {
      margin-right: 10px;
      width: 12px; } }


  &__back {
    @extend %back;
    &--with-title {
      @extend %back;
      &:after {
        content: "/";
        margin: 0 20px; } } }

  &__title {
    text-decoration: underline; } }
