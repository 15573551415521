@import "src/style/partials";

.wrapper {
  display: flex;
  flex-direction: column; }

.container {
  padding: 0 20px; }


