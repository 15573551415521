@import "src/style/partials";
@import "utils";

@keyframes skeleton {
  0%, 100% {
    opacity: 0.5; }
  50% {
    opacity: 1; } }

$spacing-button-arrow: 40px;

.wrapper {
  max-width: $max-width;
  margin: 0 auto;
  padding: 20px;
  h2 {
    margin-bottom: 20px; } }

.container {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  grid-gap: 25px; }

.main {
  background: white;
  border-left: 2px solid $box-border;
  border-right: 2px solid $box-border;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  padding: 20px 20px 20px 140px;

  &__kpis {
    display: flex;
    flex-direction: column;
    //min-width: 220px
    width: 100%;

    .kpis__line {
      display: flex;
      justify-content: space-between;
      line-height: 40px; }

    .kpis__title {
      font-size: 17px;
      color: $color-light-grey;
      font-weight: $font-medium;
      max-width: 200px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap; }

    .kpis__num {
      font-size: 23px;
      font-weight: $font-medium;
      span {
        font-size: 0.6em; } } }

  &__progress {
    border-left: 1px solid #D8D8D8;
    margin-left: 20px;
    padding-left: 20px;

    .progress__num {
      font-size: 14px;
      font-weight: $font-medium;
      line-height: 40px;
      &--red {
        color: $color-primary; }
      &--green {
        color: $color-complementary; } } }

  &__illu {
    position: absolute;
    bottom: -10px; }

  @mixin text-only {
    p {
      line-height: 1.8;
      font-size: 17px;
      font-weight: $font-regular;
      color: $color-light-grey;
      span {
        color: $color-complementary;
        font-weight: $font-medium; } } }

  &--dokey {
    padding: 30px 50px;
    justify-content: center;
    img {
      height: 100%;
      z-index: 1; } }

  &--empty {
    padding: 20px;
    justify-content: center;
    img {
      animation: skeleton 2s infinite ease-in-out; } }

  &--seo {
    padding: 20px 60px 20px 120px;
    img {
      left: -30px; } }
  &--ranking {
    padding: 20px 20px 20px 120px;
    img {
      bottom: 10px;
      left: -150px; } }
  &--sea {
    img {
      left: -30px; }
    @include text-only; }
  &--netlinking {
    img {
      left: -10px; }
    @include text-only; } }


.footer {
  min-height: 100px;
  background: $box-background;
  margin-top: auto;
  border-radius: 0 0 6px 6px;
  display: flex;
  align-items: center;
  padding: 10px 25px;
  border: 2px solid $box-border;
  font-size: 15px;
  color: $color-light-grey;
  a {
    display: contents;
    text-decoration: underline;
    color: $color-grey;
    font-weight: $font-medium; }

  &--text-only {
    justify-content: center;
    text-align: center; }

  &--text-with-button {
    display: flex;
    justify-content: space-between;
    button {
      margin-left: 20px;
      white-space: nowrap; } }

  &--button {
    min-height: 80px;
    cursor: pointer;
    background: $color-primary;
    color: white;
    font-size: 18px;
    font-weight: $font-semi-bold;
    position: relative;
    padding: 0 calc(25px + #{$spacing-button-arrow}) 0 25px;
    &:hover {
      &::after {
        transform: translateX(10px); } }
    &::after {
      position: absolute;
      content: "";
      background: url("data:image/svg+xml;base64,PHN2ZyBpZD0iQm9sZCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgMjQgMjQiPjx0aXRsZT5hcnJvdy10aGljay1yaWdodC0yPC90aXRsZT48cGF0aCBkPSJNMjMuNiwxMS4yODlsLTkuNzkzLTkuN2EyLjYwNywyLjYwNywwLDAsMC0zLjY3OS4wNzUsMi42MzgsMi42MzgsMCwwLDAtLjA2OCwzLjY4OWwzLjg3MSwzLjcxNGEuMjUuMjUsMCwwLDEtLjE3My40M0gyLjEzNUEyLjI4LDIuMjgsMCwwLDAsLjEsMTJjMCwuODE1LjQ0OCwyLjUxLDIsMi41MUgxMy43NzlhLjI1LjI1LDAsMCwxLC4xNzcuNDI3bC0zLjczMSwzLjczM2EyLjY2LDIuNjYsMCwwLDAsMy43NTgsMy43NTRsOS42MjUtOS43MkExLDEsMCwwLDAsMjMuNiwxMS4yODlaIi8+PC9zdmc+") no-repeat center center;
      filter: brightness(0) invert(1); // turns it white
      width: 20px;
      height: 20px;
      right: $spacing-button-arrow;
      transition: transform 0.2s ease-in-out; } } }


@media (max-width: 1100px) {
  .container {
    grid-template-rows: auto;
    grid-template-columns: 1fr; } }

@media #{$media-phone} {
  .footer {
    min-height: auto;
    &--text-only {
      padding: 20px; }
    &--text-with-button {
      padding: 20px;
      text-align: center;
      flex-direction: column;
      justify-content: center;
      button {
        margin-top: 10px;
        margin-left: 0; } }
    &--button {
      padding-top: 20px;
      padding-bottom: 20px;
      padding-left: 20px; } }

  .main {
    &__progress {
      display: none; } } }

@media (max-width: 420px) {
  .main {
    padding: 20px;
    &__illu {
      display: none; } } }
