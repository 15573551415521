@import "src/style/partials";

$height-button: 40px;
$padding: 20px;
$elastic-animation: cubic-bezier(0.68, -0.55, 0.265, 1.55);

@keyframes progressBarAnimation {
  from {
    width: 0; } }

@keyframes shake-top {
  0%,
  100% {
    transform: rotate(0deg);
    transform-origin: 50% 0; }

  10% {
    transform: rotate(2deg); }

  20%,
  40%,
  60% {
    transform: rotate(-4deg); }

  30%,
  50%,
  70% {
    transform: rotate(4deg); }

  80% {
    transform: rotate(-2deg); }

  90% {
    transform: rotate(2deg); } }

%card {
  height: 242px;
  cursor: pointer;
  width: 206px;
  background: white;
  border: 2px solid $color-border;
  border-radius: 6px;
  overflow: hidden; // so the button has a border radius
  position: relative;
  transition: box-shadow 0.3s ease;
  padding: $padding;
  box-sizing: border-box;
  display: grid;
  grid-template-rows: 2fr 1fr $height-button - $padding;
  grid-template-columns: 2fr 1fr;
  grid-template-areas: "icon icon" "title title" "progressBar progressNumber";
  grid-row-gap: $padding;
  &:hover {
    box-shadow: 0 10px 15px 0 rgba(0,0,0,0.1);
    .card__button {
      opacity: 1;
      height: 40px; }
    .card__price {
      opacity: 1; }
    .card__lock {
      animation: shake-top 0.8s cubic-bezier(0.455, 0.030, 0.515, 0.955) both; }
    // TODO : remettre s'il y a les prix
    //.card__ribbon
 } }    //  opacity: 0

.card--complete {
  @extend %card;
  >div {
    opacity: 0.6; } }

.card {
  @extend %card;

  &__cart {
    position: absolute;
    left: 15px;
    top: 10px;
    svg {
      width: 15px;
      fill: $color-primary; } }

  &__lock {
    position: absolute;
    left: 15px;
    top: 15px;
    svg {
      animation: shake-top 0.8s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
      width: 15px;
      fill: $color-primary; } }

  &__ribbon {
    width: 200px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $color-secondary;
    position: absolute;
    top: 20px;
    right: -65px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    letter-spacing: 1px;
    color: white;
    text-transform: uppercase;
    font-weight: $font-semi-bold;
    font-size: 10px;
    transition: opacity 0.3s ease; }

  &__icon {
    grid-area: icon;
    display: flex;
    justify-content: center;
    align-items: center;
    >svg {
      stroke: $color-complementary;
      width: 60px; } }

  &__title {
    grid-area: title;
    margin-top: auto;
    font-size: 17px;
    font-weight: $font-regular; }

  &__price {
    position: absolute;
    opacity: 0;
    transition: opacity .3s ease;
    color: $color-primary;
    right: 15px;
    top: 10px;
    font-size: 15px;
    font-weight: $font-medium; }

  &__price--disabled {
    display: none; }

  &__progressWrapper {
    grid-area: progressBar;
    display: flex;
    align-items: center;
    .card__progressBar {
      width: 100%;
      height: 2px;
      background: $color-border;

      %progress {
        animation: progressBarAnimation .3s ease 1;
        height: 100%; }

      .card__progress {
        @extend %progress;
        background: $color-primary; }
      .card__progress--complete {
        @extend %progress;
        background: $color-complementary; } } }

  &__progressNumber {
    grid-area: progressNumber;
    display: flex;
    align-items: center;
    padding-left: $padding;
    font-size: 11px;
    >svg {
      fill: $color-complementary;
      width: 12px;
      margin-left: auto; } }

  // button-disabled will never appear because opacity will always be 0
  &__button, &__button--disabled {
    position: absolute;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: $font-semi-bold;
    letter-spacing: 1.5px;
    opacity: 0;
    bottom: 0;
    left: 0;
    background: $color-primary;
    width: 100%;
    height: 0;
    color: white;
    border: none;
    cursor: pointer;
    transition: all .3s $elastic-animation;

    &:focus {
      outline: 0; } } }

.disabled {
  cursor: default;
  &:hover {
    box-shadow: none; } }
