@import "src/style/partials";

.wrapper {
  background: white;
  border-top: 1px solid $color-border;
  border-bottom: 1px solid $color-border;

  &__content {
    max-width: $max-width;
    margin: 0 auto;
    padding: 50px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media #{$media-tablet-landscape} {
      flex-direction: column;
      align-items: flex-start; } }

  &__profile {
    display: flex;
    flex-direction: column;
    max-width: 500px;
    >h2 {
      margin-bottom: 20px; }
    >p {
      line-height: 2;
      color: $color-light-grey; } } }

.sign {
  display: flex;
  flex-direction: column;
  border: 1px solid $color-border;
  border-radius: 6px;
  position: relative;
  margin-left: 20px;
  max-width: 350px;
  @media #{$media-tablet-landscape} {
    max-width: none;
    margin-left: 0;
    width: 100%;
    margin-top: 50px; }

  >img {
    position: absolute;
    top: -20px;
    align-self: center; }

  &__content {
    padding: 40px 40px 20px 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }

  &__info {
    text-align: center;
    margin-top: 20px;
    font-size: 14px; }

  &__list {
    font-size: 14px;
    padding: 0;
    margin-top: 20px;

    li {
      list-style-type: none;
      &:not(:last-child) {
        margin-bottom: 5px; }
      &:before {
        content: '';
        display: inline-block;
        background-image: url("./check-bullet.svg");
        background-size: contain;
        height: 10px;
        width: 10px;
        background-repeat: no-repeat;
        padding-left: 1em; } } }

  &__title {
    font-size: 18px;
    font-weight: $font-medium;
    text-align: center;
    color: $color-primary; }

  &__button {
    font-size: 14px;
    text-transform: uppercase;
    background: $color-primary;
    color: white;
    width: 100%;
    margin-top: auto;
    height: 50px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: $font-medium;
    border-radius: 0 0 5px 5px;
    letter-spacing: 1.5px; } }
