@import "src/style/partials";

.arrow {
  position: relative;
  font-style: italic;
  font-size: 14px;
  color: $color-light-grey;
  &__text--red {
    color: $color-primary; }
  &__img {
    position: absolute;
    left: 25px;
    bottom: -90px; } }
