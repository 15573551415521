@import "src/style/partials";

%checkbox {
  height: 20px;
  width: 20px;
  min-height: 20px;
  min-width: 20px;
  border-radius: 50%;
  border: 2px solid $color-border;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  cursor: pointer;

  svg {
    width: 60%;
    height: auto;
    fill: white; } }

.checkbox {
  @extend %checkbox;

  &__checked {
    @extend %checkbox;
    background-color: $color-complementary; }

  &__text {
    cursor: pointer;
    padding-left: 10px;
    user-select: none; } } // disable select text

.disabled {
  cursor: default;
  opacity: 0.6; }





