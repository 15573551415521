@import "src/style/partials";

.tabs {
  &__items {
    max-width: $max-width;
    margin: 0 auto;
    display: flex;
    align-items: center;
    padding: 20px;

    >a {
      &:not(:last-child) {
        margin-right: 50px; } } }

  &__item {
    $item-height: 35px;
    height: $item-height;
    font-weight: $font-medium;
    &--selected {
      $border-size: 2px;
      color: $color-primary;
      height: $item-height - $border-size;
      border-bottom: $border-size solid $color-primary; } } }
