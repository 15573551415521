@import "src/style/partials";

.slider {
  appearance: none;
  height: 3px;
  border-radius: 5px;
  outline: none;
  background: $color-very-light-grey !important;
  // cursor
  &::-webkit-slider-thumb {
    box-sizing: border-box;
    appearance: none;
    width: 17px;
    height: 17px;
    border-radius: 50%;
    background: white !important;
    border: 2px solid $color-primary;
    cursor: pointer; }
  // cursor (firefox)
  &::-moz-range-thumb {
    box-sizing: border-box;
    appearance: none;
    width: 17px;
    height: 17px;
    border-radius: 50%;
    background: white !important;
    border: 2px solid $color-primary;
    cursor: pointer; }
  // Remove dotted outline from range input element in Firefox
  &::-moz-focus-outer {
    border: 0; } }
