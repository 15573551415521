@import "src/style/partials";

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  box-sizing: border-box;
  padding: 20px;
  text-align: center;
  >img {
    width: 300px;
    margin-bottom: 50px; }

  &__arrow {
    position: absolute;
    bottom: 170px;
    right: 90px;
    text-align: left;
    @media #{$media-tablet-landscape} {
      display: none; }
    &:before {
      position: absolute;
      content: url("../../components/Arrow/arrow.svg");
      transform: rotate(-20deg);
      bottom: -100px;
      right: 20px; } } }
