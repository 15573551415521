@import "src/style/partials";

$padding: 20px;

.modal {
  &__content {
    width: auto;
    height: auto;
    max-width: 100vw;
    max-height: calc(100vh - (#{$padding} * 2)); // minus padding
    position: relative;
    box-sizing: border-box;
    margin: auto;
    border: none;
    background: rgb(255, 255, 255);
    overflow: auto;
    border-radius: 20px;
    outline: none;
    padding: $padding;
    @media #{$media-tablet-portrait} {
      width: 100%;
      height: 100%;
      max-height: 100vh;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border: 0 solid rgb(204, 204, 204);
      background: rgb(255, 255, 255);
      overflow-y: scroll;
      border-radius: 0;
      outline: none; } }

  &__overlay {
    z-index: 999;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 20px;
    background-color: rgba(59,169,198,0.5);
    @media #{$media-tablet-portrait} {
      padding: 0; } }


  &__closeicon {
    width: 35px;
    height: 35px;
    position: absolute;
    top: 30px;
    right: 30px;
    border-radius: 50%;
    font-size: 1px;
    border: 1px solid $color-light-grey;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: white;
    z-index: 1;
    svg {
      width: 10px;
      height: 10px;
      fill: $color-light-grey; }
    &:hover {
      background: $color-light-grey;
      svg {
        fill: white; } } } }
