@import "../../../style/partials";

.footer {
  width: 100%;
  background: white;
  border-top: 1px solid $color-border;
  position: relative; }

.container {
  margin: 0 auto;
  max-width: $max-width;
  padding: 30px 20px 20px 20px;
  display: flex; }

.price {
  position: absolute;
  top: -20px;
  left: 0;
  right: 0;
  margin: 0 auto;
  background: white;
  width: 300px;
  display: flex;
  justify-content: space-between;
  padding: 5px 20px;
  border-radius: 50px;
  border: 1px solid $color-border;
  p {
    font-weight: $font-medium;
    &:first-child {
      text-transform: uppercase; }
    &:last-child {
      margin-left: 20px;
      color: $color-secondary; } } }

.cart {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 50%;
  margin-right: 20px;

  h4 {
    font-weight: $font-light;
    color: $color-blueish-grey;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 1.5px;
    margin-bottom: 10px; }

  &__row {
    display: flex;
    justify-content: space-between;

    p {
      margin-bottom: 5px;
      &:last-child {
        font-weight: $font-semi-bold; } }

    &--total {
      border-top: 1px solid $color-border;
      color: $color-complementary;
      padding-top: 5px; } } }

.cta {
  width: 100%;
  max-width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;

  p {
    color: $color-light-grey;
    font-size: 12px;
    max-width: 75%;
    margin-bottom: 10px; }

  button {
    white-space: nowrap; }

  &__buttons {
    display: flex;
    flex-direction: column;
    align-items: center; }

  &__question {
    margin-top: 5px; } }

@media #{$media-max-width} {
  .cart {
    max-width: 60%; }

  .cta {
    max-width: 40%;
    p {
      max-width: 100%; } } }

@media #{$media-tablet-portrait} {
  .price {
    width: max-content; }

  .container {
    padding: 40px 20px 20px 20px;
    flex-direction: column;
    >div {
      max-width: 100%; } }

  .cart {
    margin: 0 0 20px 0; }

  .cta {
    flex-direction: row;
    justify-content: space-between;
    text-align: left;
    p {
      max-width: 500px;
      margin: 0 20px 0 0; } } }

@media #{$media-phone} {
  .cta {
    flex-direction: column;
    text-align: center;
    p {
      max-width: 300px;
      margin: 0 0 10px 0; } } }
