@import "src/style/partials";

.menu {
  &__top {
    padding: 6px 10px 10px 10px;
    display: flex;
    justify-content: center;

    button {
      background: white;
      border: 1px solid $color-primary;
      color: $color-primary;
      >svg {
        fill: $color-primary; } } } }
