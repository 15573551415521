@import "src/style/partials";

.select-campaign-container {
  z-index: 30 !important;
  display: block !important;
  width: 250px;
  height: 100%;

  .select-campaign {
    &__control {
      height: 100%;
      cursor: pointer;
      background: none;
      border: none !important;
      &--is-focused, &--menu-is-open {
        box-shadow: none !important;
        border: none !important; } }

    &__value-container {
      padding: 0 !important;
      height: 30px; }

    &__placeholder {
      font-size: 14px;
      color: $color-light-grey; }

    &__single-value {
      font-family: "Poppins", sans-serif;
      font-weight: $font-medium;
      color: $color-dark-grey;
      font-size: 21px;
      margin: 0; }

    &__menu {
      width: max-content;
      font-size: 14px; }

    &__option {
      color: $color-grey;
      text-overflow: ellipsis;
      overflow: hidden;
      &--is-selected {
        background: $color-complementary !important;
        color: white !important;
        text-decoration: none !important; }

      &--is-focused {
        background: rgba($color-complementary, 0.2); }

      &--is-disabled {
        color: $color-very-light-grey;
        text-decoration: line-through; }

      &:active {
        background: rgba($color-complementary, 0.4); } }

    &__indicator-separator {
      display: none; }

    &__indicator {
      padding: 0;
      >svg {
        fill: $color-dark-grey;
        width: 15px; } } } }

@media (max-width: 400px) {
  .select-campaign-container {
    width: 200px; } }
