@import "src/style/partials";

@keyframes arrow-jump {
  0% {
    opacity: 0;
    transform: translateX(0) rotate(180deg); }
  100% {
    opacity: 1;
    transform: translateX(-15px) rotate(180deg); } }

.container {
  z-index: 200;
  position: fixed;
  left: $sidebar-width;
  top: $header-height;
  right: 0;
  bottom: 0;
  overflow-y: scroll; // has to be scroll, not auto
  -webkit-overflow-scrolling: touch; // smooth scroll for iOS
  display: flex;
  flex-direction: column;
  align-items: center;
  background: $color-background;
  @media #{$media-tablet-portrait} {
    left: 0;
    top: $header-height-mobile; }

  &__step-wrapper {
    width: 100%;
    height: 100%; } }

.buttons {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: center;
  margin: 20px;
  min-height: max-content;
  button {
    margin-bottom: 10px; }
  &__back {
    cursor: pointer;
    font-size: 12px;
    color: $color-grey;
    &:hover {
      text-decoration: underline; } } }

.arrow {
  position: fixed;
  left: $sidebar-width + 20px;
  animation: arrow-jump 1s ease infinite;
  width: 20px;
  @media #{$media-tablet-portrait} {
    display: none; } }

.close {
  position: absolute;
  top: 30px;
  right: 30px;
  z-index: 1;
  cursor: pointer;
  &:hover {
    top: 29px;
    right: 29px;
    svg {
      width: 22px;
      height: 22px; } } }
