@import "src/style/partials";

.container {
  max-width: 700px;
  margin: 0 auto;
  padding: 20px; }

.steps {
  margin-top: 50px;
  width: 100%;
  display: flex;
  flex-direction: column;

  .step {
    width: 100%;
    display: flex;
    flex-direction: column;
    &:not(:first-child) {
      margin-top: 30px; }

    &__title {
      position: relative;
      display: flex;
      align-items: center;
      &:before {
        content: attr(data-step);
        color: $color-primary;
        font-weight: $font-light;
        margin-right: 45px; }
      &:after {
        content: "";
        width: 1px;
        height: 60%;
        background: $color-very-light-grey;
        position: absolute;
        left: 30px; } }

    &__description {
      margin: 10px 0 20px 0;
      text-align: justify; }

    &__budget {
      display: flex;
      flex-direction: column;
      align-items: center;
      background: white;
      border-radius: 6px;
      border: 2px solid $color-border;
      padding: 20px;
      box-sizing: border-box;
      margin-top: 10px;

      .budget__slider {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        width: 250px;
        @media #{$media-medium} {
          width: 100%; }
        label {
          font-size: small;
          margin-bottom: 10px;
          text-align: center; } }

      .budget__result {
        text-align: center;
        font-size: 1.2em; }

      .budget__number {
        color: $color-complementary;
        font-size: 1.2em;
        cursor: help;
        // dotted underline
        background-image: linear-gradient(to right, $color-complementary 33%, rgba(255,255,255,0) 0%);
        background-position: bottom;
        background-size: 4px 1px;
        background-repeat: repeat-x; }

      .budget__star {
        font-size: small; } } } }
.cta {
  background: white;
  position: sticky;
  bottom: 0;
  padding: 10px 20px;
  box-sizing: border-box;
  box-shadow: 10px 0 10px 0 rgba(0,0,0,0.09);
  width: 100%;

  &__wrapper {
    display: flex;
    max-width: $max-width;
    margin: 0 auto;
    flex-direction: column;
    align-items: center; } }

.annotation {
  font-size: small;
  font-style: italic;
  margin-left: auto;
  margin-top: 5px; }

.formulas {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin-right: -50px;
  >div {
    flex: 1 0 300px;
    margin-right: 50px;
    margin-top: 20px; }

  &__item {
    display: flex;
    justify-content: space-between;
    >p {
      &:first-child {
        font-weight: $font-medium; }
      &:not(:last-child) {
        margin-bottom: 10px; } } } }

.question {
  margin-top: 5px; }

.contact {
  margin-top: 20px;
  position: relative;
  background: white;
  border: 2px solid $color-border;
  border-radius: 6px;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: $color-blueish-grey;
  p:not(:last-child) {
    margin-bottom: 10px; }
  h3 {
    color: $color-complementary;
    margin-bottom: 20px; }
  button {
    margin-top: 20px; } }

@media #{$media-medium} {
  .formulas {
    flex-direction: column;
    >div {
      flex: 1; }
    &__item {
      flex-direction: column;
      &:not(:first-child) {
        margin-top: 10px; }
      p {
        margin-bottom: 0 !important; } } } }
