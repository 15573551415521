.root {
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
  margin: 3rem 2rem 1rem 2rem;
  max-width: 800px;
}

.root input {
  min-width: 200px;
}

.header {
  display: flex;
  justify-content: center;
  flex-grow: 1;
  font-weight: 500;
  margin: 0 2rem 2rem 2rem;
  text-align: center;
}

.footer {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  margin-top: 3rem;
}

.footer button {
  margin-bottom: 1rem;
}
