@import "../../../style/partials";

@-webkit-keyframes slide-in-right {
  0% {
    transform: translateX(100px);
    opacity: 0;
  }
  100% {
    transform: translateX(10px);
    opacity: 1;
  }
}

@-webkit-keyframes slide-out-right {
  0% {
    transform: translateX(-20px);
    opacity: 1;
  }
  100% {
    transform: translateX(100px);
    opacity: 0;
  }
}

.container {
  display: flex;
  flex-direction: column;

  h2 {
    color: $color-dark-grey;
    margin-bottom: 30px;
    span {
      margin-left: 20px;
      font-weight: $font-medium;
      color: $color-light-grey;
    }
  }
}

.table {
  overflow: auto;
  table {
    min-width: 600px;
    overflow-x: scroll;
    tbody {
      display: block;
      max-height: 300px;
      overflow-x: hidden;
      overflow-y: scroll;
    }
    thead,
    tbody tr,
    tfoot {
      display: table;
      width: 100%;
      table-layout: fixed;
    }

    th:first-child,
    td:first-child {
      width: 50%;
    }

    th:nth-child(2),
    td:nth-child(2) {
      width: 10%;
    }

    th:nth-child(3),
    td:nth-child(3) {
      width: 10%;
    }

    th:nth-child(4),
    td:nth-child(4) {
      width: 15%;
    }

    th:nth-child(5),
    td:nth-child(5) {
      width: 15%;
    }

    tr:hover {
      .date__button-container {
        animation: slide-in-right 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      }
    }
  }
}

.url {
  display: flex;
  flex-direction: column;
  word-break: break-all;

  a {
    line-height: 1.4;
    &:hover {
      text-decoration: underline;
    }
    &:first-child {
      font-weight: $font-medium;
    }
    &:last-child {
      font-size: 12px;
      color: $color-blueish-grey;
      position: relative;
      padding-left: 30px;
      margin-top: 5px;
      &:before {
        position: absolute;
        content: "";
        background: no-repeat center center
          url("data:image/svg+xml,%0A%3Csvg width='14px' height='12px' viewBox='0 0 14 12' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='18---NETLINKING-(dashboard)' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cg transform='translate(-346.000000, -666.000000)' id='Articles' stroke='%2378909C'%3E%3Cg transform='translate(336.000000, 560.000000)'%3E%3Cg id='list' transform='translate(0.000000, 55.000000)'%3E%3Cg id='url' transform='translate(0.420613, 30.000000)'%3E%3Cg id='diagram-increase' transform='translate(16.579387, 27.000000) rotate(-285.000000) translate(-16.579387, -27.000000) translate(9.579387, 22.000000)'%3E%3Cpath d='M11.8172222,0.416666667 L10.3066667,6.25277778 L10.3066668,6.25277733 C9.79898623,8.21378288 8.02955012,9.58333288 6.00388901,9.58333288 L0.277777894,9.58333288' id='Path'%3E%3C/path%3E%3Cpolyline id='Path' points='9.81166667 1.655 11.8172222 0.416666667 13.0555556 2.42222222'%3E%3C/polyline%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
        background-size: 15px 15px;
        width: 15px;
        height: 15px;
        left: 5px;
        top: -3px;
      }
    }
  }
}

.date {
  position: relative;
  &__button-container {
    display: flex;
    position: absolute;
    // animation to 0s so it's not triggered ono refresh
    animation: slide-out-right 0s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 1)
    );
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto 0;
    width: calc(100% + 50px);
    height: 75px;
    justify-content: flex-end;
    align-items: center;
    padding-right: 20px;

    &--animate {
      animation: slide-out-right 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    }

    button {
      border: none;
      background: white;
      color: $color-dark-grey;
      text-decoration: underline;
      cursor: pointer;
      font-size: 14px;
      font-weight: $font-semi-bold;
      padding: 0;
      height: 50px;
      &:focus {
        outline: 0;
      }
    }
  }
}

@media #{$media-tablet-portrait} {
  .container {
    h2 {
      font-size: 25px;
    }
  }
}
