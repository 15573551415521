@import "src/style/partials";

$widthIllu: 250px;

.wrapper {
  background: white;
  height: 150px;
  min-height: 150px;
  width: 100%;
  position: relative;
  overflow: hidden;
  border-bottom: 1px solid $color-border;
  @media #{$media-max-width} {
    min-height: auto;
    height: auto;
    background-color: #062f36;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='52' height='26' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 10c0-2.21-1.79-4-4-4a6 6 0 01-6-6h2c0 2.21 1.79 4 4 4a6 6 0 016 6c0 2.21 1.79 4 4 4a6 6 0 016 6c0 2.21 1.79 4 4 4v2a6 6 0 01-6-6c0-2.21-1.79-4-4-4a6 6 0 01-6-6zm25.464-1.95l8.486 8.486-1.414 1.414-8.486-8.486 1.414-1.414z' fill='%23fff' fill-opacity='.05' fill-rule='evenodd'/%3E%3C/svg%3E");
    color: white; }

  &__content {
    height: 100%;
    max-width: $max-width;
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    align-items: center;

    %text-container {
      margin-left: $widthIllu;
      display: flex;
      flex-direction: column;
      justify-content: center;
      @media #{$media-max-width} {
        width: 100%;
        margin-left: 0;
        align-items: center;
        text-align: center; } }

    .content__text-container {
      @extend %text-container;
      &--white {
        @extend %text-container;
        color: white; }
      .content__title {
        @media #{$media-phone} {
          font-size: 25px; } }
      .content__description {
        margin-top: 10px;
        line-height: 1.8;
        @media #{$media-max-width} {
          font-size: 14px; }
        @media #{$media-medium} {
          display: none; } } } } }

.image {
  position: absolute;
  bottom: -100px;
  width: $widthIllu;
  @media #{$media-max-width} {
    display: none; }
  &--netlinking {
    bottom: 0px !important;
    height: 90%; }
  &--sea {
    height: 120px;
    bottom: initial !important; }
  &--seolocal {
    bottom: 20px;
    height: 120px; }
  &--content {
    bottom: 0;
    width: 200px;
    height: 100%; } }
