@import "../../../style/partials";

.container {
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  h2 {
    color: $color-dark-grey;
  }
  button {
    min-width: max-content;
    border: none;
    background: white;
    color: $color-dark-grey;
    text-decoration: underline;
    cursor: pointer;
    font-size: 14px;
    font-weight: $font-semi-bold;
    padding: 0;
    &:focus {
      outline: 0;
    }
  }
}

.table {
  overflow: auto;
  table {
    min-width: 600px;
    overflow-x: scroll;
    tbody {
      display: block;
      max-height: 300px;
      overflow-x: hidden;
      overflow-y: scroll;
    }
    thead,
    tbody tr,
    tfoot {
      display: table;
      width: 100%;
      table-layout: fixed;
    }

    th:first-child,
    td:first-child {
      width: 40%;
    }
    th:last-child,
    td:last-child {
      width: 40%;
    }
    tr:hover {
      .date__button-container {
        animation: slide-in-right 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      }
    }
  }
}

.url {
  line-height: 1.4;
  font-weight: $font-medium;
  &:hover {
    text-decoration: underline;
  }
}

.keywords {
  $gap: 10px;

  display: flex;
  flex-wrap: wrap;
  margin-bottom: -$gap;
  &__keyword {
    padding: 2px 10px;
    background: rgba($color-secondary, 0.2);
    color: #c38d30;
    border-radius: 3px;
    margin-bottom: $gap;
    font-size: 12px;
    &:not(:last-child) {
      margin-right: $gap;
    }
  }
}

@media #{$media-tablet-portrait} {
  .header {
    h2 {
      font-size: 25px;
    }
  }
}

@media #{$media-medium} {
  .header {
    flex-direction: column;
    align-items: flex-start;
    h2 {
      margin-bottom: 10px;
    }
  }
}
