@import "../../../style/partials";

.container {
  max-width: $max-width;
  margin: 20px auto 0 auto;
  padding: 20px;

  &--white {
    background: white;
    padding-bottom: 50px;
  }

  section:not(:last-child) {
    margin-bottom: 50px;
  }
}
