@import "src/style/partials";

.wrapper {
  height: max-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center; }

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: $max-width;
  padding: 50px 20px;
  box-sizing: border-box;
  text-align: center;

  &__title {
    margin-bottom: 20px; }

  &__text {
    line-height: 2;
    margin-bottom: 20px; } }

.buttons {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
  margin: 20px 20px 0 20px;
  min-height: max-content;

  &__quit {
    cursor: pointer;
    font-size: 12px;
    color: $color-grey;
    margin-top: 10px;
    &:hover {
      text-decoration: underline; } } }
