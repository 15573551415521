@import "../../../style/partials";

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__slider {
    position: relative;
    max-width: 400px;
    width: 100%;
    margin: 0 20px;
    display: flex;
    justify-content: center;

    p {
      position: absolute;
      color: $color-light-grey;
      top: -30px;
      font-size: 12px; }

    input {
      width: 100%; } }

  &__title {
    font-size: 15px;
    font-weight: $font-semi-bold;
    color: $color-blueish-grey; } }


.result {
  display: flex;
  flex-direction: column;
  background: white;
  border: 1px solid $color-border;
  padding: 8px 20px;
  box-sizing: border-box;
  min-width: 130px;
  border-radius: 3px;

  p:first-child {
    font-weight: $font-medium;
    color: $color-blueish-grey;
    font-size: 11px; }

  p:last-child {
    font-weight: $font-medium;
    color: $color-complementary; } }

@media #{$media-phone} {
  .container {
    flex-direction: column;

    &__slider {
      max-width: 100%;
      margin: 40px 0 20px 0; } }

  .result {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-width: 100%; } }
