@import "src/style/partials";

@value colors: "../../style/colors.css";
@value primary-color from colors;

@value sizes: "../../style/sizes.css";
@value max-width from sizes;

.root {
  background: white;
  border-top: 1px solid #f2f2f2;
  min-height: calc(100% - 61px);
}

.content {
  margin: 1rem auto;
  padding: 0 2rem;
}

.tabs {
  border-bottom: 1px solid lightgray;
  display: flex;
  margin-bottom: 10px;
  height: 42px;
  box-sizing: border-box;
}

.iframe-container {
  height: calc(100vh - #{$header-height} - 52px - 2rem) !important;
}

.tab {
  bottom: -2px;
  font-weight: 500;
  height: 2rem;
  padding-bottom: 0.4rem;
  margin-right: 1rem;
  position: relative;
}

.activeTab {
  color: primary-color;
  border-bottom: 2px solid primary-color;
}
