@import "src/style/partials";

.container {
  display: flex;
  flex-direction: column;

  &__breadcrumb {
    box-sizing: border-box;
    font-size: 12px;
    color: $color-light-grey;
    font-weight: $font-regular;
    height: 60px;
    display: flex;
    align-items: center;
    max-width: $max-width;
    margin: 0 auto;
    width: 100%;
    @media #{$media-max-width} {
      padding: 0 20px; }

    .breadcrumb__back {
      cursor: pointer;
      &:after {
        content: "/";
        margin: 0 20px; } }

    .breadcrumb__title {
      text-decoration: underline; } } }
