@import "src/style/partials";

@keyframes bounce-top {
  0% {
    transform: translateY(-45px);
    animation-timing-function: ease-in;
    opacity: 1; }
  24% {
    opacity: 1; }
  40% {
    transform: translateY(-24px);
    animation-timing-function: ease-in; }
  65% {
    transform: translateY(-12px);
    animation-timing-function: ease-in; }
  82% {
    transform: translateY(-6px);
    animation-timing-function: ease-in; }
  93% {
    transform: translateY(-4px);
    animation-timing-function: ease-in; }
  25%,
  55%,
  75%,
  87% {
    transform: translateY(0px);
    animation-timing-function: ease-out; }
  100% {
    transform: translateY(0px);
    animation-timing-function: ease-out;
    opacity: 1; } }

.header {
  height: 100%;
  width: 100%;
  border-bottom: 1px solid $color-border;
  display: flex;
  justify-content: space-between;
  background: white;
  font-weight: normal;
  >div {
    height: 100%; }

  &__left {
    display: flex;
    .left__subscription {
      display: flex;
      align-items: center;
      padding: 0 20px;
      >a {
        display: flex;
        align-items: center;
        color: white;
        font-weight: $font-medium;
        font-size: 12px;
        padding: 8px 12px;
        background: $color-secondary;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: all .1s ease-in-out;
        &:hover {
          transform: scale(1.05); }
        &:focus {
          outline: 0; }
        svg {
          fill: white;
          height: 12px;
          width: 12px;
          margin-right: 5px; } } } }

  &__right {
    display: flex;
    >div {
      height: 100%; }

    .right__help {
      align-self: center;
      display: flex;
      justify-content: center;
      margin-right: 20px;
      align-items: center;
      cursor: pointer;
      height: max-content;
      svg {
        width: 20px;
        stroke: $color-light-grey; } }

    .right__gopro {
      display: flex;
      font-size: 14px;
      align-items: center;
      padding-right: 20px;
      text-decoration: underline;
      color: $color-primary;
      >span {
        cursor: pointer; } }

    .right__profile {
      z-index: 1;
      min-width: 220px;
      overflow: hidden;
      border-left: 1px solid $color-border;
      border-right: 1px solid $color-border;
      background: white;
      height: max-content;
      cursor: pointer;
      &:hover {
        flex-direction: column;
        justify-content: flex-start;
        border-bottom: 1px solid $color-border;
        box-shadow: 0 10px 20px 0 rgba(0,0,0,0.1);
        border-radius: 0 0px 6px 6px;
        .right__profile-menu {
          display: block;
          cursor: default; }
        .right__profile-container {
          color: $color-primary;
          >svg {
            fill: $color-primary; } } }

      .right__profile-container {
        height: 80px;
        padding: 0 20px;
        font-size: 11px;
        letter-spacing: 1.5px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        >svg {
          width: 10px;
          fill: $color-grey;
          margin-left: 10px; }

        .right__profile-picture {
          background: white;
          border-radius: 50%;
          width: 35px;
          height: 35px;
          overflow: hidden;
          margin-right: 10px;
          >img {
            margin: 0 auto;
            height: 100%; } } }

      .right__profile-menu {
        display: none;
        width: 100%;
        overflow: hidden;
        .profile-menu__items {
          padding: 10px 30px 20px 30px;
          margin: 0;
          .profile-menu__item {
            font-size: 13px;
            cursor: pointer;
            list-style-type: none;
            padding: 12px 0;
            display: flex;
            align-items: center;
            >svg {
              fill: $color-primary;
              height: 20px;
              margin-right: 10px; }
            &:hover {
              color: $color-primary; }
            // &:not(:last-child)
 } }            //   margin-bottom: 25px
        .profile-menu__disconnect {
          cursor: pointer;
          text-transform: uppercase;
          font-size: 11px;
          font-weight: $font-medium;
          letter-spacing: 1.5px;
          height: 40px;
          background: $color-primary;
          color: white;
          display: flex;
          justify-content: center;
          align-items: center; } } }

    .right__shopping {
      width: 80px;
      background: $color-primary;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      position: relative;
      >svg {
        fill: white;
        width: 20px; }
      .shopping__number {
        animation: bounce-top 0.9s both;
        position: absolute;
        font-size: 10px;
        top: 23px;
        right: 20px;
        background: white;
        color: $color-primary;
        border-radius: 50%;
        width: 15px;
        height: 15px;
        display: flex;
        justify-content: center;
        align-items: center; } } } }
