@import "src/style/partials";

.wrapper {
  background: linear-gradient(to bottom, white 0%, white 20%, $color-background 20%, $color-background 100%);
  @media #{$media-max-width} {
    padding: 0 20px; } }

.kpis {
  position: relative;
  padding: 20px 0;
  max-width: $max-width;
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;
  flex-wrap: nowrap;
  &::-webkit-scrollbar {
    display: none; }
  @media #{$media-max-width} {
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    justify-content: flex-start; }
  >div {
    flex: 0 0 auto;
    &:not(:first-child) {
      margin-left: 30px; } }

  &__arrow {
    top: -75px;
    left: 20px;
    position: absolute;
    margin-left: 0 !important;
    @media #{$media-max-width} {
      display: none; } } }

