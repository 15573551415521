@import "src/style/partials";

.container {
  height: 100%;
  width: 100%;
  max-width: 250px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  &__forgot {
    margin-left: auto;
    cursor: pointer;
    font-size: 12px;
    margin-top: 20px;
    color: $color-light-grey;
    &:hover {
      text-decoration: underline; } }

  &__error {
    font-size: 12px;
    color: $color-primary;
    text-align: center;
    margin-top: 20px; }

  &__bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;

    .bottom__buttons {
      display: flex;
      justify-content: space-between;
      width: 100%; }

    .bottom__or {
      margin: 30px 0; }

    .bottom__signup {
      cursor: pointer;
      font-size: 12px;
      color: $color-grey;
      &:hover {
        text-decoration: underline; } } } }
