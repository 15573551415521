@import "src/style/partials";

.wrapper {
  z-index: 1;
  background: white;
  height: 75px;
  //position: fixed
  bottom: 0;
  color: $color-light-grey;
  font-size: 14px;
  padding: 0 20px;
  box-sizing: border-box;
  border: 1px solid $color-border;
  // because position fixed is only relative to the window
  width: 100%;
  @media #{$media-tablet-portrait} {
    display: none; } }

.container {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: $max-width;
  margin: 0 auto;
  @media #{$media-max-width} {
    justify-content: space-around; }

  &__elem {
    display: flex;
    align-items: center;
    max-width: 280px;
    margin: 0 10px;
    >img {
      margin-right: 10px;
      //margin-left: 5px
      @media #{$media-max-width} {
        margin: 0; } }
    >span {
      @media #{$media-max-width} {
        display: none; } } } }

.separator {
  width: 1px;
  background: $color-border;
  height: 30px;
  @media #{$media-max-width} {
    display: none; } }
