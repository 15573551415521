@import "src/style/partials";

.wrapper {
  position: absolute;
  width: 800px;
  z-index: 3;
  bottom: -20px;
  right: 620px;
  @media (max-width: 1150px) {
    right: 50px;
    bottom: -100px; }

  &__score-container {
    position: absolute;
    right: 133px;
    bottom: 140px;
    text-align: center;
    .wrapper__tooltip {
      position: absolute;
      top: -5px;
      right: -26px;
      display: flex;
      align-items: center;
      >svg {
        stroke: $color-light-grey;
        width: 14px; } }
    .wrapper__score {
      font-size: 80px;
      color: $color-dark-grey;
      font-weight: $font-black;
      line-height: 1; }
    .wrapper__range {
      font-size: 15px;
      font-weight: $font-regular;
      color: $color-light-grey; } } }
