@import "src/style/partials";

// So the background color can continue on wider screens
.wrapper {
  width: 100%;
  background: linear-gradient(90deg, #AFDFE6 50%, #FFFFFF 50%);
  @media (max-width: 1150px) {
    background: linear-gradient(-180deg, #AFDFE6 0%, #9ECEDA 100%); } }

.container {
  position: relative; // for the illustration OctoScore
  max-width: $max-width;
  margin: 0 auto;
  height: 350px;
  display: grid;
  grid-template-columns: 300px 1fr;
  @media (max-width: 1150px) {
    height: 200px;
    overflow: hidden; }

  &__score {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 1150px) {
      grid-column: 1/3; }
    &:before {
      content: "";
      position: absolute;
      border-radius: 50%;
      width: 100%;
      height: 1000px;
      background: white;
      right: -240px;
      @media (max-width: 1150px) {
        display: none; } } }

  &__text {
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
    padding-left: 75px;
    border-radius: 50%;
    z-index: 2;
    >p {
      margin: 20px 0 20px 0;
      font-size: 18px;
      text-align: justify;
      span {
        font-weight: $font-medium;
        color: $color-secondary; } }
    @media (max-width: 1150px) {
      display: none; }

    .text__audit {
      display: flex;
      align-items: center;
      font-size: 14px;
      position: absolute;
      bottom: 10px;
      color: $color-light-grey;
      >a {
        margin: 0 4px; } } }

  &__website {
    text-decoration: underline;
    cursor: pointer; } }

.downloadReport {
  text-decoration: underline;
  cursor: pointer;
  &--loading {
    pointer-events: none;
    opacity: 0.4;
    cursor: default; }
  &--disabled {
    cursor: default; } }
