@import "src/style/partials";

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;

  button {
    margin-top: 50px; } }
