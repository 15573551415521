@import "src/style/partials";

.select-websites-container {
  z-index: 30 !important;
  display: block !important;
  width: 100%;
  height: 100%;
  @media #{$media-tablet-portrait} {
    max-width: 160px; }

  .select-websites {
    &__control {
      height: 100%;
      cursor: pointer;
      background: none;
      border: none !important;
      &--is-focused, &--menu-is-open {
        box-shadow: none !important;
        border: none !important; } }

    &__value-container {
      padding: 0 !important;
      width: 90px; }

    &__placeholder {
      font-size: 14px;
      color: $color-light-grey; }

    &__single-value {
      color: white;
      text-transform: uppercase;
      margin: 0; }

    &__menu {
      top: 65px;
      right: 0;
      width: $sidebar-width - 40px;
      letter-spacing: normal; }

    &__option {
      color: $color-grey;
      text-overflow: ellipsis;
      overflow: hidden;
      &--is-selected {
        background: $color-complementary !important;
        color: white !important;
        text-decoration: none !important; }

      &--is-focused {
        background: rgba($color-complementary, 0.2); }

      &--is-disabled {
        color: $color-very-light-grey;
        text-decoration: line-through; }

      &:active {
        background: rgba($color-complementary, 0.4); } }

    &__indicator-separator {
      display: none; }

    &__indicator {
      padding: 0;
      >svg {
        fill: white;
        width: 15px; } } } }
