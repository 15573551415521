@import "src/style/partials";

.ReactTable {
  width: 100%;
  background-color: white;
  border: solid 2px $color-border;
  border-radius: 6px;
  color: #626B6B;
  font-size: 14px;
  box-sizing: border-box; }

//  header
.rt-thead {
  font-weight: $font-medium;
  height: 60px;
  box-shadow: none;
  border-bottom: solid 2px $color-border;
  .rt-th {
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: solid 2px $color-border !important;
    &:last-child {
      border-right: 0 !important; } } }

// each row
.rt-tr-group {
  border-bottom: solid 2px $color-border !important;
  &:last-child {
    border-bottom: solid 1px $color-border !important; }
  min-height: 40px !important;
  &:nth-child(odd) {
    background: #FBFBFB; } }

// each cell
.rt-td {
  border-right: solid 2px $color-border !important;
  display: flex;
  align-items: center;
  padding: 0 10px !important;
  &:last-child {
    border-right: 0 !important; } }

// button prev/next
.-btn {
  &:enabled {
    border: solid 1px $color-primary !important;
    background: white !important;
    color: $color-primary !important; }
  &:disabled {
    border: solid 1px $color-primary !important;
    background: white !important;
    color: $color-primary !important;
    opacity: 0.5; } }

.-pagination {
  box-shadow: none !important;
  border-top: solid 1px $color-border !important; }

// input page number
.-pageJump input {
  border: 1px solid $color-very-light-grey !important; }

// sorting bar
.rt-th.-sort-asc, .rt-td.-sort-asc {
  box-shadow: inset 0 3px 0 0 $color-primary !important; }
.rt-th.-sort-desc, .rt-td.-sort-desc {
  box-shadow: inset 0 -3px 0 0 $color-primary !important; }

// message no data
.rt-noData {
  z-index: 0 !important; }

// loading
.-loading, .-active {
  z-index: 0 !important; }
