@import "src/style/partials";

.button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  font-weight: $font-medium;
  color: $color-complementary;
  cursor: pointer;

  &--disabled {
    pointer-events: none;
    opacity: 0.6; }

  >div {
    display: flex;
    align-items: center;
    >svg {
      fill: $color-complementary;
      width: 12px;
      margin-right: 6px; } } }

.waitNextAudit {
  display: flex;
  flex-direction: column;
  padding: 10px;
  text-align: center;
  border-bottom: 1px solid $color-border;
  >span {
    font-weight: $font-regular;
    font-size: 12px; }
  >a {
    margin-top: 10px;
    font-weight: $font-medium;
    text-decoration: underline;
    color: $color-secondary; } }

.inProgress {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid $color-border; }
