@import "src/style/partials";

.container {
  height: 100%;
  width: 100%;
  max-width: 250px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  &__error {
    font-size: 12px;
    color: $color-primary;
    text-align: center;
    margin-top: 20px; }

  &__bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    .container__signup {
      cursor: pointer;
      font-size: 12px;
      color: $color-grey;
      &:hover {
        text-decoration: underline; } } } }

.success {
  text-align: center;
  color: $color-complementary;
  display: flex;
  flex-direction: column;
  align-items: center;
  >* {
    &:not(:last-child) {
      margin-bottom: 20px; } }
  >p {
    &:first-child {
      font-weight: $font-medium; } } }

