@import "src/style/partials";

.container {
  width: 100%;
  margin-top: 20px; }

.row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  & + .row {
    margin-top: 30px; }
  p {
    margin-top: 5px;
    color: $color-light-grey;
    font-size: 14px; } }

@media #{$media-tablet-landscape} {
  .row {
    flex-direction: column;
    align-items: flex-start;
    >div:nth-child(2) {
      margin-top: 10px; } } }
