@import "src/style/partials";

/* ----------------------------------------------
 * Generated by Animista on 2019-12-5 15:15:0
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-bottom
 * ---------------------------------------- */
@-webkit-keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0; }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; } }

@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0; }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; } }

/* ----------------------------------------------
 * Generated by Animista on 2019-12-5 15:19:56
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-out-bottom
 * ---------------------------------------- */
@-webkit-keyframes slide-out-bottom {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; }

  100% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0; } }

@keyframes slide-out-bottom {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; }

  100% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0; } }

.show {
  display: block !important; }

.hidden {
  animation: slide-out-bottom 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both !important; }

.popup {
  display: none;
  box-shadow: 7px 7px 20px 0px rgba(50, 50, 50, 0.2);
  animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  position: fixed;
  background: $color-primary;
  z-index: 999;
  bottom: 20px;
  left: 20px;
  border-radius: 6px;

  &__container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 50px;
    text-align: center;
    color: white; }

  &__title {
    font-size: 20px;
    font-weight: $font-medium;
    margin-bottom: 30px; }

  &__promo {
    font-weight: $font-semi-bold;
    font-size: 35px;
    margin-bottom: 10px; }

  &__quit {
    position: absolute;
    right: 15px;
    top: 15px;
    line-height: 1;
    cursor: pointer;
    align-self: flex-end; }

  a {
    border: none;
    margin-top: 20px; } }

@media #{$media-medium} {
  .popup {
    width: auto;
    right: 20px;

    &__container {
      padding: 20px; }

    &__title {
      margin-bottom: 10px; }

    &__promo {
      font-size: 30px; }

    a {
      margin-top: 10px; } } }
