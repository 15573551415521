@import "src/style/partials";

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.donut {
  display: inline-block;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: $color-primary;
  border-radius: 50%;
  animation: spin 750ms linear infinite; }
