@import "src/style/partials";

.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  //flex-direction: column // TODO : remettre s'il y a le prix
  align-items: center;

  // TODO : enlever s'il y a le prix
  @media #{$media-max-width} {
    justify-content: center; }

  &__title {
    font-size: 18px;
    font-weight: normal;
    color: $color-light-grey;
    @media #{$media-max-width} {
      display: none; } } }

.container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  button {
    margin-left: 20px; }

  &__price {
    color: $color-primary; }

  @media #{$media-phone} {
    flex-direction: column;
    button {
      margin-left: 0; } } }
