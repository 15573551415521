@import "src/style/partials";

$separator-border-color: $color-very-light-grey;
$separator-padding     : 1rem;
$separator-border-width: 1px;
$separator-border-style: solid;
$separator-max-width   : 100%;

.container {
  display: flex;
  align-items: center;
  padding: 0 $separator-padding;
  max-width: $separator-max-width;
  margin-left: auto;
  margin-right: auto;
  width: 100%;

  &:before, &:after {
    content: '';
    flex: 0 1 100%;
    border-bottom: $separator-border-width $separator-border-style $separator-border-color;
    margin: 0 $separator-padding;
    transform: translateY(#{$separator-border-width} / 2); }

  &:before {
    margin-left: 0; }

  &:after {
    margin-right: 0; }

  span {
    text-transform: uppercase;
    font-weight: $font-regular;
    font-size: small; } }
