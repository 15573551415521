@import "src/style/partials";

.form {
  width: 100%;
  &__input-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 -25px;
    >label {
      margin: 20px 25px;
      flex: 1 0 200px; } }
  &__button {
    margin-top: 20px;
    margin-left: auto; } }
