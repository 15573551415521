@import "../../../../style/partials";

.container {
  padding: 20px 50px;
  h1 {
    color: $color-complementary;
    margin-bottom: 30px;
  }

  > div {
    padding: 0;
  }

  section:not(:last-child) {
    margin-bottom: 50px;
  }
}

@media #{$media-tablet-portrait} {
  .container {
    padding: 0;
  }
}
