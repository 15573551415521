@import "src/style/partials";

.wrapper {
  background: white;
  padding: 50px 0;
  @media #{$media-max-width} {
    padding: 50px 20px; } }

.container {
  display: flex;
  flex-direction: column;
  max-width: $max-width;
  margin: 0 auto;
  &__title {
    margin-bottom: 10px; }
  &__list {
    display: flex;
    flex-direction: column; }
  &__button {
    margin: 50px auto 0 auto;
    @media #{$media-max-width} {
      display: none; } } }

%item {
  width: 100%;
  border-bottom: 1px solid $color-border;
  // TODO: remettre quand "Voir les urls impactés" dispo
  //cursor: pointer
  padding: 20px 0 15px 0;
  display: flex;
  align-items: center;
  @media #{$media-tablet-landscape} {
    flex-direction: column;
    align-items: flex-start; } }

.item {
  @extend %item;

  &__left {
    display: flex;
    flex-direction: column; }
  &__title {
    font-size: 20px; }
  &__subtitle {
    font-size: 15px;
    color: $color-light-grey; }
  >button {
    transition: opacity 0.3s ease;
    opacity: 0;
    margin-left: auto;
    @media #{$media-phone} {
      display: none; } }
  &__number {
    margin-left: auto;
    font-size: 14px;
    width: 150px;
    color: $color-grey;
    @media #{$media-tablet-landscape} {
      margin: 10px 0 0 0; } } }

.badge {
  display: flex;
  align-items: center;
  >* {
    &:not(:last-child) {
      margin-right: 10px; } }

  &__circle {
    min-width: 10px;
    min-height: 10px;
    border-radius: 50%; }

  &--green {
    color: $color-complementary;
    div {
      background: $color-complementary; } }
  &--orange {
    color: $color-secondary;
    div {
      background: $color-secondary; } }
  &--red {
    color: $color-primary;
    div {
      background: $color-primary; } } }
