@import "src/style/partials";

.container {
  padding: 0 20px;
  &:not(:last-child) {
    margin-bottom: 50px; }

  &__content {
    max-width: $max-width;
    margin: 0 auto;
    background: white;
    border-radius: 6px;
    border: 1px solid $color-border;
    padding: 40px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start; } }

