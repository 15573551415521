@import "src/style/partials";

.table {
  width: 100%;
  font-size: 14px;
  text-align: left;
  border-radius: 6px !important;
  border: 2px solid $color-border;
  background: white;
  border-spacing: 0;
  overflow: hidden;
  &:hover {
    .table__counter--more {
      svg {
        animation: shake-top 0.8s cubic-bezier(0.455, 0.030, 0.515, 0.955) both; } } }


  %counter {
    margin-left: auto;
    font-weight: normal; }

  &__counter {
    @extend %counter;
    &--max {
      @extend %counter;
      color: $color-complementary; }
    &--more {
      @extend %counter;
      display: flex;
      align-items: center;
      color: $color-primary;
      svg {
        width: 14px;
        height: 14px;
        margin-left: 5px;
        fill: $color-primary; } } }

  th {
    display: flex;
    align-items: center;
    padding: 0 20px;
    height: 60px;
    box-sizing: border-box;
    font-weight: $font-medium;
    border-bottom: 2px solid $color-border; }

  %td {
    height: auto;
    min-height: 40px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    box-sizing: border-box; }

  td {
    @extend %td; }

  .td--input {
    @extend %td;
    padding: 0;
    background: #FBFBFB;
    >button {
      margin-left: 20px; } }

  tbody {
    overflow-y: scroll; // has to be scroll, not auto
    -webkit-overflow-scrolling: touch; // smooth scroll for iOS
    display: flex;
    flex-direction: column;
    tr {
      &:nth-child(odd) {
        background: #FBFBFB; }
      &:not(:last-child) {
        td {
          border-bottom: 2px solid $color-border; } } } } }

.form {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  input {
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    font-size: 12px;
    color: inherit;
    border: none;
    padding: 0 10px;
    background: none;
    &:focus {
      outline: 0; }
    &::placeholder {
      opacity: 0.5; } }
  button {
    margin: 0 10px; } }

@keyframes slide {
  0% {
    transform: translateX(-100%); }
  100% {
    transform: translateX(100%); } }


.maxMessage {
  background: $color-secondary !important;
  color: white;
  cursor: pointer;
  height: auto !important;
  padding: 20px !important;
  display: flex;
  align-items: flex-start !important;
  flex-direction: column;
  font-weight: $font-medium;
  font-size: 16px;
  position: relative;

  button {
    margin-top: 20px;
    margin-left: 0 !important;
    align-self: flex-end;
    color: white;
    svg {
      fill: white; } } }
