@import "src/style/partials";

.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  >u {
    cursor: pointer; }

  &__discover {
    position: absolute;
    left: 50px;
    top: 90px;
    @media #{$media-tablet-landscape} {
      display: none; }
    &:before {
      position: absolute;
      content: url("../../components/Arrow/arrow.svg");
      transform: scaleX(-1) rotate(-55deg);
      bottom: -70px; } }

  &__button {
    text-decoration: underline;
    cursor: pointer;
    &--disabled {
      opacity: 0.4;
      pointer-events: none; } } }

.failed {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  text-align: center;

  &__arrow {
    position: absolute;
    bottom: 170px;
    right: 90px;
    text-align: left;
    @media #{$media-phone} {
      display: none; }
    &:before {
      position: absolute;
      content: url("../../components/Arrow/arrow.svg");
      transform: rotate(-20deg);
      bottom: -100px;
      right: 20px; } } }

