@import "../../../style/partials";

.container {
  max-width: $max-width;
  margin: 20px auto 0 auto;
  padding: 20px; }

.step {
  margin-bottom: 30px;
  >h3 {
    margin-bottom: 10px; }

  >p {
    font-size: 15px;
    color: $color-light-grey;
    margin-bottom: 20px;
    a {
      color: $color-grey;
      text-decoration: underline; } } }
