@import "src/style/partials";

.diff {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 3px;
  padding: 5px 8px;

  &--up {
    background: $color-complementary; }

  &--down {
    background: $color-primary; }

  &__num {
    line-height: 1;
    font-size: 10px;
    font-weight: $font-semi-bold; } }
