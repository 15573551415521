@import "src/style/partials";

.button {
  background: white;
  min-height: 40px;
  border: none;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.15);
  font-weight: $font-semi-bold;
  font-size: 14px;
  color: $color-grey;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 8px 15px;
  border-radius: 90px;
  cursor: pointer;
  max-width: max-content;
  img {
    width: 18px;
    margin-right: 24px; }
  &:focus {
    outline: 0; } }
