@import "src/style/partials";

.wrapper {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box; }

.container {
  display: flex;
  flex-direction: column;
  background: white;
  border: 1px solid $color-border;
  border-radius: 6px;
  width: 100%;
  max-width: 350px;
  padding: 30px 20px;
  box-sizing: border-box;

  &__logo {
    max-height: 100px;
    margin-bottom: 20px; }

  &__title {
    text-align: center;
    font-size: 20px;
    margin: 20px 0;
    font-weight: $font-semi-bold; }

  &__description {
    text-align: center;
    margin-bottom: 20px;
    font-size: 14px; } }
