@import "src/style/partials";

.label {
  position: relative; }

%input {
  border: none;
  border-bottom: 1px solid $color-very-light-grey;
  border-radius: 0;
  padding: 15px;
  font-family: "Poppins", sans-serif;
  font-size: inherit;
  color: inherit;
  box-sizing: border-box;
  width: 100%;
  background: none;
  &:disabled {
    color: $color-light-grey;
    -webkit-text-fill-color: $color-light-grey; }
  &:focus {
    outline: 0;
    border-bottom: 1px solid $color-light-grey; }
  &::placeholder {
    opacity: 0.5; }
  // remove background when browser autofills
  &:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus, &:-webkit-autofill:active {
    transition: background-color 9999999999s ease-in-out 0s;
    -webkit-text-fill-color: $color-grey !important; }
  // avoid zoom on input on ios
  @media #{$media-tablet-portrait} {
    font-size: 16px !important; } }

.input {
  @extend %input;

  &--error {
    @extend %input;
    border-bottom: 1px solid $color-primary;
    &:focus {
      border-bottom: 1px solid $color-primary; } } }

.error-message {
  position: absolute;
  right: 0;
  bottom: -20px;
  color: $color-primary;
  margin-left: auto;
  font-size: 12px;
  z-index: 1; }

.loading {
  position: absolute;
  right: 0;
  top: 15px; }

.valid {
  position: absolute;
  right: 0;
  top: 15px;
  svg {
    width: 20px;
    fill: $color-complementary; } }
