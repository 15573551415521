@import "src/style/partials";

.title {
  position: relative;
  display: flex;
  align-items: center;
  &:before {
    content: attr(data-step);
    color: $color-primary;
    font-weight: $font-light;
    margin-right: 45px; }
  &:after {
    content: "";
    width: 1px;
    height: 60%;
    background: $color-very-light-grey;
    position: absolute;
    left: 30px; } }
