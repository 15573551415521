@import "src/style/partials";

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px 20px 20px;
  box-sizing: border-box;
  max-width: $max-width;
  margin: 0 auto;
  text-align: center;

  &__description {
    margin-top: 20px;
    width: 75%;
    @media #{$media-tablet-portrait} {
      width: 100%; } } }

.form {
  margin-top: 50px;
  width: 50%;
  display: flex;
  flex-direction: column;
  @media #{$media-tablet-portrait} {
    width: 100%; }
  label {
    font-size: 14px; }

  &__buttons {
    margin-top: 50px;
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: center; }

  &__link {
    margin-top: 10px;
    cursor: pointer;
    font-size: 12px;
    color: $color-grey;
    &:hover {
      text-decoration: underline; } } }
