@import "src/style/partials";

@keyframes floating {
  from {
    transform: translate(0,  0px); }
  65% {
    transform: translate(0, 15px); }
  to {
    transform: translate(0, -0px); } }

.wrapper {
  padding: 20px;
  display: flex;
  justify-content: center; }

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  &__illustration {
    margin-bottom: 50px;
    width: 35vh;
    animation: floating 3s infinite ease-in-out; } }
