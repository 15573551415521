@import "src/style/partials";

.container {
  width: 100%;
  padding: 10px 20px;
  border: 1px solid $color-complementary;
  box-sizing: border-box;
  border-radius: 6px;
  background: #28a59c1f;
  color: $color-complementary;
  font-size: 14px; }
