@import "../../../style/partials";

.articles {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  overflow-x: scroll;

  &__radio {
    position: relative;
    display: flex;
    justify-content: center;
    &:not(:last-child) {
      margin-right: 20px; }

    &--highlighted {
      position: absolute;
      font-weight: $font-medium;
      color: $color-secondary;
      top: -25px;
      font-size: 14px; }

    input[type="radio"] {
      opacity: 0;
      position: fixed;
      width: 0;
      &:checked + label {
        border: 2px solid $color-complementary;
        color: $color-complementary;
        background: rgba($color-complementary, 0.02); } }
    label {
      display: flex;
      font-weight: $font-medium;
      box-sizing: border-box;
      justify-content: center;
      cursor: pointer;
      background: white;
      padding: 8px 20px;
      border: 2px solid $color-border;
      border-radius: 6px;
      width: 150px; } } }
