@import "src/style/partials";

.dropdown {
  position: relative;
  display: flex;
  align-items: center;
  &__select {
    cursor: pointer;
    border: 0px;
    outline: 0px;
    padding-right: 20px;
    z-index: 2;
    color: $color-primary;
    background: none;
    font-size: 25px;
    appearance: none;

    //remove arrow for I.E
    &::-ms-expand {
      display: none; } }

  &__option {
    color: $color-grey; }

  svg {
    position: absolute;
    right: 0px;
    z-index: 1;
    width: 13px; } }







