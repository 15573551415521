@import "src/style/partials";

.layout {
  width: 100%;
  height: 100vh;
  display: flex;
  &__menu {
    z-index: 300;
    width: $sidebar-width;
    @media #{$media-tablet-portrait} {
      position: fixed;
      width: 100%; } }
  &__header {
    width: calc(100% - #{$sidebar-width});
    z-index: 300;
    height: $header-height;
    position: fixed;
    @media #{$media-tablet-portrait} {
      display: none; } }
  &__content {
    height: 100%;
    width: calc(100% - #{$sidebar-width});
    @media #{$media-tablet-portrait} {
      width: 100%; } } }

%content {
  position: relative;
  height: calc(100% - #{$header-height});
  margin-top: $header-height;
  @media #{$media-tablet-portrait} {
    height: calc(100% - #{$header-height-mobile});
    margin-top: $header-height-mobile; } }

.content {
  @extend %content;
  overflow-y: scroll; // has to be scroll, not auto
  -webkit-overflow-scrolling: touch; } // smooth scroll for iOS

// disable scroll on content when a div is over
// https://stackoverflow.com/questions/9280258/prevent-body-scrolling-but-allow-overlay-scrolling
.content--no-scroll {
  @extend %content;
  overflow: hidden; }

.menu--disable {
  pointer-events: none;
  opacity: 0.4; }
