@import "src/style/partials";

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  text-align: center;
  padding: 20px;
  >h2 {
    margin-bottom: 50px; }
  >button {
    margin-top: 20px; } }

