@import "../../../../style/partials";

.container {
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    margin: 20px 0;
  }

  p {
    max-width: 600px;
    text-align: center;
    line-height: 2;
  }

  button {
    margin-top: 50px;
  }
}

.check {
  background: $color-complementary;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;
  svg {
    width: 100%;
    fill: white;
  }
}

@media #{$media-tablet-portrait} {
  .container {
    padding: 50px 20px;
  }
}
