@import "src/style/partials";

.container {
  width: 100%;
  box-sizing: border-box;
  letter-spacing: normal; }

.form {
  color: $color-dark-grey;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin-top: 50px;

  label {
    margin-bottom: 10px;
    color: $color-grey; }

  &__input-container {
    box-sizing: border-box;
    display: flex;
    input {
      height: 40px;
      width: 100%;
      font-size: medium;
      padding: 10px 15px;
      border: 1px solid $color-very-light-grey;
      box-sizing: border-box;
      border-radius: 6px 0 0 6px;
      color: $color-dark-grey;
      &:focus {
        outline: 0;
        border-bottom: 1px solid $color-very-light-grey; }
      &::placeholder {
        color: $color-light-grey; } }

    button {
      font-weight: $font-semi-bold;
      border: none;
      color: white;
      background: $color-primary;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 0 6px 6px 0;
      padding: 8px 30px;
      text-transform: uppercase;
      &:focus {
        outline: 0; }
      &:disabled {
        cursor: default;
        pointer-events: none;
        background: $color-light-grey; } } }

  &__error {
    margin-top: 5px;
    font-size: 12px;
    color: $color-primary; } }

@media #{$media-medium} {
  .form {
    &__input-container {
      flex-direction: column;
      input {
        border-radius: 6px 6px 0 0; }
      button {
        border-radius: 0 0 6px 6px;
        padding: 15px 30px; } } } }
