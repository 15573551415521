@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,400i,500,600,900);
.Arrow_Toastify__toast__2AX89 {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.Arrow_Toastify__toast--success__AGQ4J {
  background: #27A49B; }

.Arrow_Toastify__toast--error__1FCD9 {
  background: #F24D4B; }

.Arrow_arrow__16di4 {
  position: relative;
  font-style: italic;
  font-size: 14px;
  color: #9AA9AB; }
  .Arrow_arrow__text--red__2soEs {
    color: #F24D4B; }
  .Arrow_arrow__img__1dZrH {
    position: absolute;
    left: 25px;
    bottom: -90px; }

.Banner_Toastify__toast__21DTu {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.Banner_Toastify__toast--success__2mLo4 {
  background: #27A49B; }

.Banner_Toastify__toast--error__1mw52 {
  background: #F24D4B; }

.Banner_container__1HSgE {
  width: 100%;
  padding: 10px 20px;
  border: 1px solid #27A49B;
  box-sizing: border-box;
  border-radius: 6px;
  background: #28a59c1f;
  color: #27A49B;
  font-size: 14px; }

.Button_Toastify__toast__3Tz30 {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.Button_Toastify__toast--success__1tGMK {
  background: #27A49B; }

.Button_Toastify__toast--error__2FKpj {
  background: #F24D4B; }

.Button_button__vyKWR {
  font-weight: 600;
  font-size: 14px;
  border: none;
  color: #F24D4B;
  padding: 0;
  cursor: pointer;
  background: none;
  display: flex;
  align-items: center; }
  .Button_button__vyKWR:focus {
    outline: 0; }
  .Button_button__vyKWR > svg {
    margin-left: 10px;
    fill: #F24D4B;
    width: 15px; }
  .Button_button__vyKWR > span, .Button_button__vyKWR svg {
    pointer-events: none; }
  .Button_button--cta__GuMbO {
    background: #F24D4B;
    color: white;
    padding: 10px 20px;
    border-radius: 360px; }
    .Button_button--cta__GuMbO > svg {
      fill: white; }
    .Button_button--cta-disabled__3vZIb {
      cursor: default;
      background: #C3C3C3; }
  .Button_button--disabled__3AOi- {
    cursor: default;
    color: #C3C3C3; }
    .Button_button--disabled__3AOi- > svg {
      fill: #C3C3C3; }

.Breadcrumb_Toastify__toast__1YF_U {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.Breadcrumb_Toastify__toast--success__3YDFL {
  background: #27A49B; }

.Breadcrumb_Toastify__toast--error__2tzsy {
  background: #F24D4B; }

.Breadcrumb_container__3pZGj {
  box-sizing: border-box;
  font-size: 12px;
  color: #9AA9AB;
  fill: #9AA9AB;
  font-weight: 400;
  height: 60px;
  display: flex;
  align-items: center;
  max-width: 920px;
  margin: 0 auto;
  width: 100%; }
  @media (max-width: 1190px) {
    .Breadcrumb_container__3pZGj {
      padding: 0 20px; } }
  .Breadcrumb_container__3pZGj .Breadcrumb_container__back__1ZGlx, .Breadcrumb_container__3pZGj .Breadcrumb_container__back--with-title__33CVL {
    cursor: pointer;
    display: flex;
    align-items: center; }
    .Breadcrumb_container__3pZGj .Breadcrumb_container__back__1ZGlx > svg, .Breadcrumb_container__3pZGj .Breadcrumb_container__back--with-title__33CVL > svg {
      margin-right: 10px;
      width: 12px; }
  .Breadcrumb_container__back--with-title__33CVL:after {
    content: "/";
    margin: 0 20px; }
  .Breadcrumb_container__title__2sKWM {
    text-decoration: underline; }

.Chart_wrapper__Frzos {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1; }
  .Chart_wrapper__loading__2wbFA {
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.9);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 0.3s ease-in-out, z-index 0.3s; }
    .Chart_wrapper__loading--show__gi6J- {
      opacity: 1; }
    .Chart_wrapper__loading--hide__3BFKY {
      opacity: 0;
      z-index: -1; }

.Checkbox_Toastify__toast__1Ybjt {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.Checkbox_Toastify__toast--success__26yr1 {
  background: #27A49B; }

.Checkbox_Toastify__toast--error__2J_e3 {
  background: #F24D4B; }

.Checkbox_checkbox__2FviL, .Checkbox_checkbox__checked__x6sdY {
  height: 20px;
  width: 20px;
  min-height: 20px;
  min-width: 20px;
  border-radius: 50%;
  border: 2px solid #F2F2F2;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  cursor: pointer; }
  .Checkbox_checkbox__2FviL svg, .Checkbox_checkbox__checked__x6sdY svg {
    width: 60%;
    height: auto;
    fill: white; }

.Checkbox_checkbox__checked__x6sdY {
  background-color: #27A49B; }

.Checkbox_checkbox__text__2ehCq {
  cursor: pointer;
  padding-left: 10px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.Checkbox_disabled__39o8_ {
  cursor: default;
  opacity: 0.6; }

.CompetitorRatio_Toastify__toast__rLLMh {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.CompetitorRatio_Toastify__toast--success__2kNmd {
  background: #27A49B; }

.CompetitorRatio_Toastify__toast--error__2jjjr {
  background: #F24D4B; }

.CompetitorRatio_competitor--easy__1971X, .CompetitorRatio_competitor--medium__20aAw, .CompetitorRatio_competitor--hard__1eMj5 {
  font-weight: 500; }

.CompetitorRatio_competitor--easy__1971X {
  color: #27A49B; }

.CompetitorRatio_competitor--medium__20aAw {
  color: #EEB656; }

.CompetitorRatio_competitor--hard__1eMj5 {
  color: #F24D4B; }

.Confetti_Toastify__toast__XW74j {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.Confetti_Toastify__toast--success__3Uae5 {
  background: #27A49B; }

.Confetti_Toastify__toast--error__2V60Z {
  background: #F24D4B; }

.Confetti_container__2iDxn {
  opacity: 0.5;
  --color1: #55476a;
  --color2: #ae3d63;
  --color3: #db3853;
  --color4: #f45c44;
  --color5: #f8b663; }

.Confetti_confetti__BL-Nb {
  position: absolute;
  display: flex;
  width: 10px;
  height: 25px;
  top: -100px; }

.Confetti_confetti__BL-Nb:nth-child(1) {
  background-color: var(--color1);
  -webkit-animation: Confetti_fall__3KSkW 2.5s linear infinite;
          animation: Confetti_fall__3KSkW 2.5s linear infinite;
  left: 10%; }

.Confetti_confetti__BL-Nb:nth-child(2) {
  background-color: var(--color2);
  -webkit-animation: Confetti_fall__3KSkW 2.3s linear infinite 0.2s;
          animation: Confetti_fall__3KSkW 2.3s linear infinite 0.2s;
  left: 20%; }

.Confetti_confetti__BL-Nb:nth-child(3) {
  background-color: var(--color3);
  -webkit-animation: Confetti_fall__3KSkW 2.4s linear infinite 0.4s;
          animation: Confetti_fall__3KSkW 2.4s linear infinite 0.4s;
  left: 30%; }

.Confetti_confetti__BL-Nb:nth-child(4) {
  background-color: var(--color4);
  -webkit-animation: Confetti_fall__3KSkW 2.7s linear infinite 0.1s;
          animation: Confetti_fall__3KSkW 2.7s linear infinite 0.1s;
  left: 40%; }

.Confetti_confetti__BL-Nb:nth-child(5) {
  background-color: var(--color5);
  -webkit-animation: Confetti_fall__3KSkW 2.6s linear infinite 0.7s;
          animation: Confetti_fall__3KSkW 2.6s linear infinite 0.7s;
  left: 50%; }

.Confetti_confetti__BL-Nb:nth-child(6) {
  background-color: var(--color1);
  -webkit-animation: Confetti_fall__3KSkW 2.4s linear infinite 0.2s;
          animation: Confetti_fall__3KSkW 2.4s linear infinite 0.2s;
  left: 60%; }

.Confetti_confetti__BL-Nb:nth-child(7) {
  background-color: var(--color2);
  -webkit-animation: Confetti_fall__3KSkW 2.1s linear infinite 0.7s;
          animation: Confetti_fall__3KSkW 2.1s linear infinite 0.7s;
  left: 70%; }

.Confetti_confetti__BL-Nb:nth-child(8) {
  background-color: var(--color3);
  -webkit-animation: Confetti_fall__3KSkW 2.4s linear infinite 0.9s;
          animation: Confetti_fall__3KSkW 2.4s linear infinite 0.9s;
  left: 80%; }

.Confetti_confetti__BL-Nb:nth-child(9) {
  background-color: var(--color4);
  -webkit-animation: Confetti_fall__3KSkW 2.9s linear infinite 0.9s;
          animation: Confetti_fall__3KSkW 2.9s linear infinite 0.9s;
  left: 90%; }

.Confetti_confetti__BL-Nb:nth-child(10) {
  background-color: var(--color5);
  -webkit-animation: Confetti_fall__3KSkW 2.2s linear infinite 1.1s;
          animation: Confetti_fall__3KSkW 2.2s linear infinite 1.1s;
  left: 100%; }

.Confetti_confetti__BL-Nb:nth-child(11) {
  background-color: var(--color1);
  -webkit-animation: Confetti_fall2__3mpH2 2.5s linear infinite;
          animation: Confetti_fall2__3mpH2 2.5s linear infinite;
  left: 95%; }

.Confetti_confetti__BL-Nb:nth-child(12) {
  background-color: var(--color2);
  -webkit-animation: Confetti_fall2__3mpH2 2.3s linear infinite 1.1s;
          animation: Confetti_fall2__3mpH2 2.3s linear infinite 1.1s;
  left: 85%; }

.Confetti_confetti__BL-Nb:nth-child(13) {
  background-color: var(--color3);
  -webkit-animation: Confetti_fall2__3mpH2 2.4s linear infinite 1.2s;
          animation: Confetti_fall2__3mpH2 2.4s linear infinite 1.2s;
  left: 75%; }

.Confetti_confetti__BL-Nb:nth-child(14) {
  background-color: var(--color4);
  -webkit-animation: Confetti_fall2__3mpH2 2.7s linear infinite 1.3s;
          animation: Confetti_fall2__3mpH2 2.7s linear infinite 1.3s;
  left: 65%; }

.Confetti_confetti__BL-Nb:nth-child(15) {
  background-color: var(--color5);
  -webkit-animation: Confetti_fall2__3mpH2 2.6s linear infinite 1.4s;
          animation: Confetti_fall2__3mpH2 2.6s linear infinite 1.4s;
  left: 55%; }

.Confetti_confetti__BL-Nb:nth-child(16) {
  background-color: var(--color1);
  -webkit-animation: Confetti_fall2__3mpH2 2.4s linear infinite 1.5s;
          animation: Confetti_fall2__3mpH2 2.4s linear infinite 1.5s;
  left: 45%; }

.Confetti_confetti__BL-Nb:nth-child(17) {
  background-color: var(--color2);
  -webkit-animation: Confetti_fall2__3mpH2 2.1s linear infinite 1.6s;
          animation: Confetti_fall2__3mpH2 2.1s linear infinite 1.6s;
  left: 35%; }

.Confetti_confetti__BL-Nb:nth-child(18) {
  background-color: var(--color3);
  -webkit-animation: Confetti_fall2__3mpH2 2.4s linear infinite 1.7s;
          animation: Confetti_fall2__3mpH2 2.4s linear infinite 1.7s;
  left: 25%; }

.Confetti_confetti__BL-Nb:nth-child(19) {
  background-color: var(--color4);
  -webkit-animation: Confetti_fall2__3mpH2 2.9s linear infinite 1.8s;
          animation: Confetti_fall2__3mpH2 2.9s linear infinite 1.8s;
  left: 15%; }

.Confetti_confetti__BL-Nb:nth-child(20) {
  background-color: var(--color5);
  -webkit-animation: Confetti_fall2__3mpH2 2.2s linear infinite 1.9s;
          animation: Confetti_fall2__3mpH2 2.2s linear infinite 1.9s;
  left: 5%; }

.Confetti_confetti__BL-Nb:nth-child(21) {
  background-color: var(--color1);
  -webkit-animation: Confetti_fall__3KSkW 2.5s linear infinite;
          animation: Confetti_fall__3KSkW 2.5s linear infinite;
  left: 3%; }

.Confetti_confetti__BL-Nb:nth-child(22) {
  background-color: var(--color2);
  -webkit-animation: Confetti_fall__3KSkW 2.3s linear infinite 1.3s;
          animation: Confetti_fall__3KSkW 2.3s linear infinite 1.3s;
  left: 13%; }

.Confetti_confetti__BL-Nb:nth-child(23) {
  background-color: var(--color3);
  -webkit-animation: Confetti_fall__3KSkW 2.4s linear infinite 1.4s;
          animation: Confetti_fall__3KSkW 2.4s linear infinite 1.4s;
  left: 23%; }

.Confetti_confetti__BL-Nb:nth-child(24) {
  background-color: var(--color4);
  -webkit-animation: Confetti_fall__3KSkW 2.7s linear infinite 1.5s;
          animation: Confetti_fall__3KSkW 2.7s linear infinite 1.5s;
  left: 33%; }

.Confetti_confetti__BL-Nb:nth-child(25) {
  background-color: var(--color5);
  -webkit-animation: Confetti_fall__3KSkW 2.6s linear infinite 1.6s;
          animation: Confetti_fall__3KSkW 2.6s linear infinite 1.6s;
  left: 43%; }

.Confetti_confetti__BL-Nb:nth-child(26) {
  background-color: var(--color1);
  -webkit-animation: Confetti_fall__3KSkW 2.4s linear infinite 1.2s;
          animation: Confetti_fall__3KSkW 2.4s linear infinite 1.2s;
  left: 53%; }

.Confetti_confetti__BL-Nb:nth-child(27) {
  background-color: var(--color2);
  -webkit-animation: Confetti_fall__3KSkW 2.1s linear infinite 1.7s;
          animation: Confetti_fall__3KSkW 2.1s linear infinite 1.7s;
  left: 63%; }

.Confetti_confetti__BL-Nb:nth-child(28) {
  background-color: var(--color3);
  -webkit-animation: Confetti_fall__3KSkW 2.4s linear infinite 1.8s;
          animation: Confetti_fall__3KSkW 2.4s linear infinite 1.8s;
  left: 73%; }

.Confetti_confetti__BL-Nb:nth-child(29) {
  background-color: var(--color4);
  -webkit-animation: Confetti_fall__3KSkW 2.9s linear infinite 1.9s;
          animation: Confetti_fall__3KSkW 2.9s linear infinite 1.9s;
  left: 83%; }

.Confetti_confetti__BL-Nb:nth-child(30) {
  background-color: var(--color5);
  -webkit-animation: Confetti_fall__3KSkW 2.2s linear infinite 1.1s;
          animation: Confetti_fall__3KSkW 2.2s linear infinite 1.1s;
  left: 93%; }

.Confetti_confetti__BL-Nb:nth-child(31) {
  background-color: var(--color1);
  -webkit-animation: Confetti_fall2__3mpH2 2.2s linear infinite 1.1s;
          animation: Confetti_fall2__3mpH2 2.2s linear infinite 1.1s;
  left: 7%; }

.Confetti_confetti__BL-Nb:nth-child(32) {
  background-color: var(--color2);
  -webkit-animation: Confetti_fall2__3mpH2 2.9s linear infinite 0.9s;
          animation: Confetti_fall2__3mpH2 2.9s linear infinite 0.9s;
  left: 17%; }

.Confetti_confetti__BL-Nb:nth-child(33) {
  background-color: var(--color3);
  -webkit-animation: Confetti_fall2__3mpH2 2.4s linear infinite 0.1s;
          animation: Confetti_fall2__3mpH2 2.4s linear infinite 0.1s;
  left: 27%; }

.Confetti_confetti__BL-Nb:nth-child(34) {
  background-color: var(--color4);
  -webkit-animation: Confetti_fall2__3mpH2 2.1s linear infinite 0.7s;
          animation: Confetti_fall2__3mpH2 2.1s linear infinite 0.7s;
  left: 37%; }

.Confetti_confetti__BL-Nb:nth-child(35) {
  background-color: var(--color5);
  -webkit-animation: Confetti_fall2__3mpH2 2.4s linear infinite 0.2s;
          animation: Confetti_fall2__3mpH2 2.4s linear infinite 0.2s;
  left: 47%; }

.Confetti_confetti__BL-Nb:nth-child(36) {
  background-color: var(--color1);
  -webkit-animation: Confetti_fall2__3mpH2 2.6s linear infinite 0.7s;
          animation: Confetti_fall2__3mpH2 2.6s linear infinite 0.7s;
  left: 57%; }

.Confetti_confetti__BL-Nb:nth-child(37) {
  background-color: var(--color2);
  -webkit-animation: Confetti_fall2__3mpH2 2.7s linear infinite 0.9s;
          animation: Confetti_fall2__3mpH2 2.7s linear infinite 0.9s;
  left: 67%; }

.Confetti_confetti__BL-Nb:nth-child(38) {
  background-color: var(--color3);
  -webkit-animation: Confetti_fall2__3mpH2 2.4s linear infinite 0.4s;
          animation: Confetti_fall2__3mpH2 2.4s linear infinite 0.4s;
  left: 77%; }

.Confetti_confetti__BL-Nb:nth-child(39) {
  background-color: var(--color4);
  -webkit-animation: Confetti_fall2__3mpH2 2.3s linear infinite 0.2s;
          animation: Confetti_fall2__3mpH2 2.3s linear infinite 0.2s;
  left: 87%; }

.Confetti_confetti__BL-Nb:nth-child(40) {
  background-color: var(--color5);
  -webkit-animation: Confetti_fall2__3mpH2 2.5s linear infinite 0.3s;
          animation: Confetti_fall2__3mpH2 2.5s linear infinite 0.3s;
  left: 97%; }

@-webkit-keyframes Confetti_fall__3KSkW {
  0% {
    top: -110px;
    transform: rotate(0deg) rotateY(-90deg);
    opacity: 1; }
  100% {
    top: 900px;
    transform: rotate(360deg) rotateY(180deg);
    opacity: 0.7; } }

@keyframes Confetti_fall__3KSkW {
  0% {
    top: -110px;
    transform: rotate(0deg) rotateY(-90deg);
    opacity: 1; }
  100% {
    top: 900px;
    transform: rotate(360deg) rotateY(180deg);
    opacity: 0.7; } }

@-webkit-keyframes Confetti_fall2__3mpH2 {
  0% {
    top: -110px;
    transform: rotate(0deg) rotateY(90deg);
    opacity: 1; }
  100% {
    top: 900px;
    transform: rotate(-360deg) rotateY(-180deg);
    opacity: 0.5; } }

@keyframes Confetti_fall2__3mpH2 {
  0% {
    top: -110px;
    transform: rotate(0deg) rotateY(90deg);
    opacity: 1; }
  100% {
    top: 900px;
    transform: rotate(-360deg) rotateY(-180deg);
    opacity: 0.5; } }

.CrawlingPage_Toastify__toast__1-fpq {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.CrawlingPage_Toastify__toast--success__358Ku {
  background: #27A49B; }

.CrawlingPage_Toastify__toast--error__2aI2U {
  background: #F24D4B; }

@-webkit-keyframes CrawlingPage_floating__313l7 {
  from {
    transform: translate(0, 0px); }
  65% {
    transform: translate(0, 15px); }
  to {
    transform: translate(0, 0px); } }

@keyframes CrawlingPage_floating__313l7 {
  from {
    transform: translate(0, 0px); }
  65% {
    transform: translate(0, 15px); }
  to {
    transform: translate(0, 0px); } }

.CrawlingPage_wrapper__1nkA5 {
  padding: 20px;
  display: flex;
  justify-content: center; }

.CrawlingPage_container__3yg6L {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center; }
  .CrawlingPage_container__illustration__8Oi-l {
    margin-bottom: 50px;
    width: 35vh;
    -webkit-animation: CrawlingPage_floating__313l7 3s infinite ease-in-out;
            animation: CrawlingPage_floating__313l7 3s infinite ease-in-out; }

.CtaBanner_Toastify__toast__3ayNq {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.CtaBanner_Toastify__toast--success__16At8 {
  background: #27A49B; }

.CtaBanner_Toastify__toast--error__z-jWp {
  background: #F24D4B; }

.CtaBanner_container__V8Mai {
  box-sizing: border-box;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.14);
  padding: 30px 100px;
  background: #F24D4B;
  color: white;
  width: 100%;
  min-height: 150px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  border-radius: 6px; }
  .CtaBanner_container__V8Mai p {
    font-size: 21px;
    font-weight: 600;
    line-height: 1.8; }
  .CtaBanner_container__V8Mai button {
    min-width: -webkit-max-content;
    min-width: -moz-max-content;
    min-width: max-content;
    margin-left: 20px;
    color: #F24D4B;
    background: white; }
    .CtaBanner_container__V8Mai button svg {
      fill: #F24D4B; }

@media (max-width: 1190px) {
  .CtaBanner_container__V8Mai p {
    font-size: 18px; } }

@media (max-width: 979px) {
  .CtaBanner_container__V8Mai {
    flex-direction: column;
    padding: 30px; }
    .CtaBanner_container__V8Mai p {
      text-align: center;
      margin-bottom: 20px; }
    .CtaBanner_container__V8Mai button {
      margin-left: 0; } }

.Toastify__toast {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.Toastify__toast--success {
  background: #27A49B; }

.Toastify__toast--error {
  background: #F24D4B; }

.DateRangePicker .DateRangePickerInput {
  border: none;
  background: none; }

.DateRangePicker .DateInput, .DateRangePicker input {
  box-sizing: border-box;
  background: none;
  color: #666B73;
  cursor: pointer;
  padding: 0;
  width: 85px;
  font-size: 16px; }

.DateRangePicker .DateInput_input__focused {
  border-bottom: 2px solid #27A49B; }

.DateRangePicker .DateRangePickerInput_arrow_svg {
  width: 15px;
  margin: 0 10px; }

.DateRangePicker .DateRangePicker_picker {
  z-index: 3; }

.Dropdown_Toastify__toast__okwKh {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.Dropdown_Toastify__toast--success__1xXw6 {
  background: #27A49B; }

.Dropdown_Toastify__toast--error__2qAec {
  background: #F24D4B; }

.Dropdown_dropdown__12XoG {
  position: relative;
  display: flex;
  align-items: center; }
  .Dropdown_dropdown__select__1TbdQ {
    cursor: pointer;
    border: 0px;
    outline: 0px;
    padding-right: 20px;
    z-index: 2;
    color: #F24D4B;
    background: none;
    font-size: 25px;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none; }
    .Dropdown_dropdown__select__1TbdQ::-ms-expand {
      display: none; }
  .Dropdown_dropdown__option__2Pv0r {
    color: #666B73; }
  .Dropdown_dropdown__12XoG svg {
    position: absolute;
    right: 0px;
    z-index: 1;
    width: 13px; }

.ErrorBoundary_Toastify__toast__1Eeoc {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.ErrorBoundary_Toastify__toast--success__1XM_3 {
  background: #27A49B; }

.ErrorBoundary_Toastify__toast--error__1imlJ {
  background: #F24D4B; }

.ErrorBoundary_container__1pzsB {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  text-align: center;
  padding: 20px; }
  .ErrorBoundary_container__1pzsB > h2 {
    margin-bottom: 50px; }
  .ErrorBoundary_container__1pzsB > button {
    margin-top: 20px; }

.ErrorPage_Toastify__toast__nQtpR {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.ErrorPage_Toastify__toast--success__2Ooak {
  background: #27A49B; }

.ErrorPage_Toastify__toast--error__13fCG {
  background: #F24D4B; }

.ErrorPage_container__27GGf {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
  padding: 20px;
  box-sizing: border-box; }
  .ErrorPage_container__27GGf button {
    margin-top: 50px; }

.GuidedTour_Toastify__toast__3L8fT {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.GuidedTour_Toastify__toast--success__1Pojw {
  background: #27A49B; }

.GuidedTour_Toastify__toast--error__W7bk_ {
  background: #F24D4B; }

@-webkit-keyframes GuidedTour_arrow-jump__1Mdk3 {
  0% {
    opacity: 0;
    transform: translateX(0) rotate(180deg); }
  100% {
    opacity: 1;
    transform: translateX(-15px) rotate(180deg); } }

@keyframes GuidedTour_arrow-jump__1Mdk3 {
  0% {
    opacity: 0;
    transform: translateX(0) rotate(180deg); }
  100% {
    opacity: 1;
    transform: translateX(-15px) rotate(180deg); } }

.GuidedTour_container__1WomX {
  z-index: 200;
  position: fixed;
  left: 250px;
  top: 80px;
  right: 0;
  bottom: 0;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #FAFDFD; }
  @media (max-width: 767px) {
    .GuidedTour_container__1WomX {
      left: 0;
      top: 70px; } }
  .GuidedTour_container__step-wrapper__3rJhq {
    width: 100%;
    height: 100%; }

.GuidedTour_buttons__2LRkc {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: center;
  margin: 20px;
  min-height: -webkit-max-content;
  min-height: -moz-max-content;
  min-height: max-content; }
  .GuidedTour_buttons__2LRkc button {
    margin-bottom: 10px; }
  .GuidedTour_buttons__back__cNMB8 {
    cursor: pointer;
    font-size: 12px;
    color: #666B73; }
    .GuidedTour_buttons__back__cNMB8:hover {
      text-decoration: underline; }

.GuidedTour_arrow__14r0o {
  position: fixed;
  left: 270px;
  -webkit-animation: GuidedTour_arrow-jump__1Mdk3 1s ease infinite;
          animation: GuidedTour_arrow-jump__1Mdk3 1s ease infinite;
  width: 20px; }
  @media (max-width: 767px) {
    .GuidedTour_arrow__14r0o {
      display: none; } }

.GuidedTour_close__2eA4X {
  position: absolute;
  top: 30px;
  right: 30px;
  z-index: 1;
  cursor: pointer; }
  .GuidedTour_close__2eA4X:hover {
    top: 29px;
    right: 29px; }
    .GuidedTour_close__2eA4X:hover svg {
      width: 22px;
      height: 22px; }

.Step_Toastify__toast__3mFhO {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.Step_Toastify__toast--success__1D54a {
  background: #27A49B; }

.Step_Toastify__toast--error__1vFiz {
  background: #F24D4B; }

.Step_container__jxgko {
  max-width: 920px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px; }
  .Step_container__title__2D17i {
    margin-bottom: 20px; }
  .Step_container__text__37Zs3 {
    margin-top: 20px; }
  .Step_container__img__1eFUU {
    width: 75%;
    box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.1); }
    @media (max-width: 767px) {
      .Step_container__img__1eFUU {
        width: 100%; } }

.Welcome_Toastify__toast__2NCCa {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.Welcome_Toastify__toast--success__2rjkL {
  background: #27A49B; }

.Welcome_Toastify__toast--error__2n7H6 {
  background: #F24D4B; }

.Welcome_wrapper__1I7Tc {
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center; }

.Welcome_container__2X-Pd {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 920px;
  padding: 50px 20px;
  box-sizing: border-box;
  text-align: center; }
  .Welcome_container__title__2xS46 {
    margin-bottom: 20px; }
  .Welcome_container__text__3ZddJ {
    line-height: 2;
    margin-bottom: 20px; }

.Welcome_buttons__36r3o {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
  margin: 20px 20px 0 20px;
  min-height: -webkit-max-content;
  min-height: -moz-max-content;
  min-height: max-content; }
  .Welcome_buttons__quit__2VCbb {
    cursor: pointer;
    font-size: 12px;
    color: #666B73;
    margin-top: 10px; }
    .Welcome_buttons__quit__2VCbb:hover {
      text-decoration: underline; }

.HeaderPage_Toastify__toast__3bZxn {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.HeaderPage_Toastify__toast--success__j10py {
  background: #27A49B; }

.HeaderPage_Toastify__toast--error__15O1A {
  background: #F24D4B; }

.HeaderPage_wrapper__2rG3H {
  background: white;
  height: 150px;
  min-height: 150px;
  width: 100%;
  position: relative;
  overflow: hidden;
  border-bottom: 1px solid #F2F2F2; }
  @media (max-width: 1190px) {
    .HeaderPage_wrapper__2rG3H {
      min-height: auto;
      height: auto;
      background-color: #062f36;
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='52' height='26' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 10c0-2.21-1.79-4-4-4a6 6 0 01-6-6h2c0 2.21 1.79 4 4 4a6 6 0 016 6c0 2.21 1.79 4 4 4a6 6 0 016 6c0 2.21 1.79 4 4 4v2a6 6 0 01-6-6c0-2.21-1.79-4-4-4a6 6 0 01-6-6zm25.464-1.95l8.486 8.486-1.414 1.414-8.486-8.486 1.414-1.414z' fill='%23fff' fill-opacity='.05' fill-rule='evenodd'/%3E%3C/svg%3E");
      color: white; } }
  .HeaderPage_wrapper__content__Avk87 {
    height: 100%;
    max-width: 920px;
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    align-items: center; }
    .HeaderPage_wrapper__content__Avk87 .HeaderPage_content__text-container__3unpP, .HeaderPage_wrapper__content__Avk87 .HeaderPage_content__text-container--white__1XILJ {
      margin-left: 250px;
      display: flex;
      flex-direction: column;
      justify-content: center; }
      @media (max-width: 1190px) {
        .HeaderPage_wrapper__content__Avk87 .HeaderPage_content__text-container__3unpP, .HeaderPage_wrapper__content__Avk87 .HeaderPage_content__text-container--white__1XILJ {
          width: 100%;
          margin-left: 0;
          align-items: center;
          text-align: center; } }
    .HeaderPage_wrapper__content__Avk87 .HeaderPage_content__text-container--white__1XILJ {
      color: white; }
    @media (max-width: 480px) {
      .HeaderPage_wrapper__content__Avk87 .HeaderPage_content__text-container__3unpP .HeaderPage_content__title__2hG88 {
        font-size: 25px; } }
    .HeaderPage_wrapper__content__Avk87 .HeaderPage_content__text-container__3unpP .HeaderPage_content__description__30tce {
      margin-top: 10px;
      line-height: 1.8; }
      @media (max-width: 1190px) {
        .HeaderPage_wrapper__content__Avk87 .HeaderPage_content__text-container__3unpP .HeaderPage_content__description__30tce {
          font-size: 14px; } }
      @media (max-width: 580px) {
        .HeaderPage_wrapper__content__Avk87 .HeaderPage_content__text-container__3unpP .HeaderPage_content__description__30tce {
          display: none; } }

.HeaderPage_image__HGHdm {
  position: absolute;
  bottom: -100px;
  width: 250px; }
  @media (max-width: 1190px) {
    .HeaderPage_image__HGHdm {
      display: none; } }
  .HeaderPage_image--netlinking__3wFcn {
    bottom: 0px !important;
    height: 90%; }
  .HeaderPage_image--sea__1yyxd {
    height: 120px;
    bottom: auto !important;
    bottom: initial !important; }
  .HeaderPage_image--seolocal__3mhX9 {
    bottom: 20px;
    height: 120px; }
  .HeaderPage_image--content__3AWoi {
    bottom: 0;
    width: 200px;
    height: 100%; }

.GoogleButton_Toastify__toast__2Y8G9 {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.GoogleButton_Toastify__toast--success__1hZip {
  background: #27A49B; }

.GoogleButton_Toastify__toast--error__2jPkQ {
  background: #F24D4B; }

.GoogleButton_button__3xzT- {
  background: white;
  min-height: 40px;
  border: none;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  font-weight: 600;
  font-size: 14px;
  color: #666B73;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 8px 15px;
  border-radius: 90px;
  cursor: pointer;
  max-width: -webkit-max-content;
  max-width: -moz-max-content;
  max-width: max-content; }
  .GoogleButton_button__3xzT- img {
    width: 18px;
    margin-right: 24px; }
  .GoogleButton_button__3xzT-:focus {
    outline: 0; }

.HelpButton_Toastify__toast__2cFKx {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.HelpButton_Toastify__toast--success__W10jP {
  background: #27A49B; }

.HelpButton_Toastify__toast--error__2giJe {
  background: #F24D4B; }

.HelpButton_button__LSx4V {
  font-size: small;
  cursor: pointer; }
  .HelpButton_button__LSx4V:hover {
    text-decoration: underline; }

.Input_Toastify__toast__3ON4Y {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.Input_Toastify__toast--success__3Gq5d {
  background: #27A49B; }

.Input_Toastify__toast--error__IlB1P {
  background: #F24D4B; }

.Input_label__oNdhv {
  position: relative; }

.Input_input__x-Kyq, .Input_input--error__6oYpp {
  border: none;
  border-bottom: 1px solid #C3C3C3;
  border-radius: 0;
  padding: 15px;
  font-family: "Poppins", sans-serif;
  font-size: inherit;
  color: inherit;
  box-sizing: border-box;
  width: 100%;
  background: none; }
  .Input_input__x-Kyq:disabled, .Input_input--error__6oYpp:disabled {
    color: #9AA9AB;
    -webkit-text-fill-color: #9AA9AB; }
  .Input_input__x-Kyq:focus, .Input_input--error__6oYpp:focus {
    outline: 0;
    border-bottom: 1px solid #9AA9AB; }
  .Input_input__x-Kyq::-webkit-input-placeholder, .Input_input--error__6oYpp::-webkit-input-placeholder {
    opacity: 0.5; }
  .Input_input__x-Kyq::-ms-input-placeholder, .Input_input--error__6oYpp::-ms-input-placeholder {
    opacity: 0.5; }
  .Input_input__x-Kyq::placeholder, .Input_input--error__6oYpp::placeholder {
    opacity: 0.5; }
  .Input_input__x-Kyq:-webkit-autofill, .Input_input--error__6oYpp:-webkit-autofill, .Input_input__x-Kyq:-webkit-autofill:hover, .Input_input--error__6oYpp:-webkit-autofill:hover, .Input_input__x-Kyq:-webkit-autofill:focus, .Input_input--error__6oYpp:-webkit-autofill:focus, .Input_input__x-Kyq:-webkit-autofill:active, .Input_input--error__6oYpp:-webkit-autofill:active {
    -webkit-transition: background-color 9999999999s ease-in-out 0s;
    transition: background-color 9999999999s ease-in-out 0s;
    -webkit-text-fill-color: #666B73 !important; }
  @media (max-width: 767px) {
    .Input_input__x-Kyq, .Input_input--error__6oYpp {
      font-size: 16px !important; } }

.Input_input--error__6oYpp {
  border-bottom: 1px solid #F24D4B; }
  .Input_input--error__6oYpp:focus {
    border-bottom: 1px solid #F24D4B; }

.Input_error-message__2GzkV {
  position: absolute;
  right: 0;
  bottom: -20px;
  color: #F24D4B;
  margin-left: auto;
  font-size: 12px;
  z-index: 1; }

.Input_loading__1SI2Y {
  position: absolute;
  right: 0;
  top: 15px; }

.Input_valid__ZD6nI {
  position: absolute;
  right: 0;
  top: 15px; }
  .Input_valid__ZD6nI svg {
    width: 20px;
    fill: #27A49B; }

.Loading_Toastify__toast__1Vq-s {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.Loading_Toastify__toast--success__2zP0B {
  background: #27A49B; }

.Loading_Toastify__toast--error__35l6i {
  background: #F24D4B; }

@-webkit-keyframes Loading_spin__3wEon {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes Loading_spin__3wEon {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.Loading_donut__2Kb-N {
  display: inline-block;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #F24D4B;
  border-radius: 50%;
  -webkit-animation: Loading_spin__3wEon 750ms linear infinite;
          animation: Loading_spin__3wEon 750ms linear infinite; }

.KpiTile_Toastify__toast__22qR1 {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.KpiTile_Toastify__toast--success__P98OZ {
  background: #27A49B; }

.KpiTile_Toastify__toast--error__3U-Iz {
  background: #F24D4B; }

.KpiTile_kpiCard--disabled__3Xnrx, .KpiTile_kpiCard__2U09Q, .KpiTile_skeleton__zxpqr {
  height: 162px;
  width: 257px;
  background: white;
  border: 2px solid #F2F2F2;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
  transition: box-shadow 0.3s ease;
  padding: 20px;
  box-sizing: border-box;
  display: grid;
  grid-template-rows: 60px 1fr;
  grid-template-columns: 60px 2fr;
  grid-template-areas: "icon kpi" "desc desc";
  grid-gap: 5px; }

.KpiTile_kpiCard--disabled__3Xnrx {
  opacity: 0.6; }
  .KpiTile_kpiCard--disabled__3Xnrx .KpiTile_kpiCard__desc__1XCHl {
    text-align: center; }

.KpiTile_kpiCard--clickable__Xyx0_ {
  cursor: pointer;
  border-color: #27A49B;
  border-style: dashed; }
  .KpiTile_kpiCard--clickable__Xyx0_:hover {
    border-style: solid; }
    .KpiTile_kpiCard--clickable__Xyx0_:hover svg {
      transform: translateX(5px); }
  .KpiTile_kpiCard--clickable__Xyx0_ svg {
    transition: transform 0.1s ease-in-out;
    position: absolute;
    bottom: 15px;
    right: 20px;
    width: 15px;
    fill: #27A49B; }

.KpiTile_kpiCard__icon__jzkCy {
  grid-area: icon;
  display: flex;
  justify-content: center;
  align-items: center; }

.KpiTile_kpiCard__kpi__LzxsF {
  grid-area: kpi;
  line-height: 1; }
  .KpiTile_kpiCard__kpi__LzxsF .KpiTile_kpiCard__number__7aEaE {
    display: flex;
    align-items: center;
    overflow-wrap: break-word;
    font-size: 30px;
    font-weight: 600;
    color: #27A49B; }
    .KpiTile_kpiCard__kpi__LzxsF .KpiTile_kpiCard__number__7aEaE > *:nth-child(1) {
      margin-left: 5px; }
  .KpiTile_kpiCard__kpi__LzxsF .KpiTile_kpiCard__text__19k4d {
    font-size: 14px;
    line-height: 1.2;
    font-weight: 400; }

.KpiTile_kpiCard__desc__1XCHl {
  grid-area: desc;
  display: grid;
  align-items: flex-end;
  font-size: 15px;
  color: #78909C; }

@-webkit-keyframes KpiTile_shine-icon__1PUWM {
  0% {
    background-position: -100px; }
  40%, 100% {
    background-position: 203px; } }

@keyframes KpiTile_shine-icon__1PUWM {
  0% {
    background-position: -100px; }
  40%, 100% {
    background-position: 203px; } }

@-webkit-keyframes KpiTile_shine-lines__Ckxu5 {
  0% {
    background-position: -155px; }
  40%, 100% {
    background-position: 148px; } }

@keyframes KpiTile_shine-lines__Ckxu5 {
  0% {
    background-position: -155px; }
  40%, 100% {
    background-position: 148px; } }

@-webkit-keyframes KpiTile_shine-desc__3xsKS {
  0% {
    background-position: -100px; }
  40%, 100% {
    background-position: 217px; } }

@keyframes KpiTile_shine-desc__3xsKS {
  0% {
    background-position: -100px; }
  40%, 100% {
    background-position: 217px; } }

.KpiTile_skeleton__icon__AV3j_ {
  grid-area: icon;
  width: 50px;
  height: 50px;
  margin: auto;
  border-radius: 50%;
  background-image: linear-gradient(90deg, #F4F4F4 0px, rgba(229, 229, 229, 0.8) 40px, #F4F4F4 80px);
  background-size: 600px;
  -webkit-animation: KpiTile_shine-icon__1PUWM 2s infinite ease-out;
          animation: KpiTile_shine-icon__1PUWM 2s infinite ease-out; }

.KpiTile_skeleton__kpi__1bqni {
  grid-area: kpi;
  display: flex;
  flex-direction: column; }
  .KpiTile_skeleton__kpi__1bqni div {
    border-radius: 6px;
    background-image: linear-gradient(90deg, #F4F4F4 0px, rgba(229, 229, 229, 0.8) 40px, #F4F4F4 80px);
    background-size: 600px;
    -webkit-animation: KpiTile_shine-lines__Ckxu5 2s infinite ease-out;
            animation: KpiTile_shine-lines__Ckxu5 2s infinite ease-out; }
  .KpiTile_skeleton__kpi__1bqni div:first-child {
    height: 30px; }
  .KpiTile_skeleton__kpi__1bqni div:last-child {
    margin-top: 5px;
    height: 17px; }

.KpiTile_skeleton__desc__2FN5E {
  grid-area: desc;
  border-radius: 6px;
  background-image: linear-gradient(90deg, #F4F4F4 0px, rgba(229, 229, 229, 0.8) 40px, #F4F4F4 80px);
  background-size: 600px;
  -webkit-animation: KpiTile_shine-desc__3xsKS 2s infinite ease-out;
          animation: KpiTile_shine-desc__3xsKS 2s infinite ease-out; }

.ProgressionIcon_Toastify__toast__1QQoA {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.ProgressionIcon_Toastify__toast--success__2veXs {
  background: #27A49B; }

.ProgressionIcon_Toastify__toast--error__1jHmS {
  background: #F24D4B; }

.ProgressionIcon_diff__3Td6q {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 3px;
  padding: 5px 8px; }
  .ProgressionIcon_diff--up__2y56G {
    background: #27A49B; }
  .ProgressionIcon_diff--down__21PM5 {
    background: #F24D4B; }
  .ProgressionIcon_diff__num__1YaE8 {
    line-height: 1;
    font-size: 10px;
    font-weight: 600; }

.Layout_Toastify__toast__1FF2X {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.Layout_Toastify__toast--success__31rK8 {
  background: #27A49B; }

.Layout_Toastify__toast--error__3erIN {
  background: #F24D4B; }

.Layout_layout__3lkTr {
  width: 100%;
  height: 100vh;
  display: flex; }
  .Layout_layout__menu__QsNnC {
    z-index: 300;
    width: 250px; }
    @media (max-width: 767px) {
      .Layout_layout__menu__QsNnC {
        position: fixed;
        width: 100%; } }
  .Layout_layout__header__1qu1x {
    width: calc(100% - 250px);
    z-index: 300;
    height: 80px;
    position: fixed; }
    @media (max-width: 767px) {
      .Layout_layout__header__1qu1x {
        display: none; } }
  .Layout_layout__content__pZp1U {
    height: 100%;
    width: calc(100% - 250px); }
    @media (max-width: 767px) {
      .Layout_layout__content__pZp1U {
        width: 100%; } }

.Layout_content__1laFi, .Layout_content--no-scroll__OMSgy {
  position: relative;
  height: calc(100% - 80px);
  margin-top: 80px; }
  @media (max-width: 767px) {
    .Layout_content__1laFi, .Layout_content--no-scroll__OMSgy {
      height: calc(100% - 70px);
      margin-top: 70px; } }

.Layout_content__1laFi {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch; }

.Layout_content--no-scroll__OMSgy {
  overflow: hidden; }

.Layout_menu--disable__2oDxV {
  pointer-events: none;
  opacity: 0.4; }

.SideBar_Toastify__toast__1ZNxm {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.SideBar_Toastify__toast--success__1fUYg {
  background: #27A49B; }

.SideBar_Toastify__toast--error__4_grU {
  background: #F24D4B; }

.SideBar_menu__1Ji07 {
  height: 100%;
  width: 100%;
  border-right: 1px solid #F2F2F2;
  display: grid;
  grid-template-rows: 80px 1fr;
  grid-template-areas: "top" "items";
  font-weight: normal;
  overflow: hidden; }
  @media (max-width: 767px) {
    .SideBar_menu__1Ji07 {
      grid-template-rows: 70px;
      grid-template-areas: "top"; } }
  .SideBar_menu__top__4Oxhd {
    grid-area: top;
    padding: 0 20px;
    background: #F24D4B;
    color: white;
    display: flex;
    align-items: center;
    font-size: 11px;
    letter-spacing: 1.5px; }
    .SideBar_menu__top__4Oxhd .SideBar_top__logo__3xGEM {
      color: #F24D4B;
      display: flex;
      cursor: pointer;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-weight: 600;
      text-transform: uppercase;
      background: white;
      border-radius: 50%;
      min-width: 40px;
      min-height: 40px;
      overflow: hidden;
      margin-right: 10px; }
      .SideBar_menu__top__4Oxhd .SideBar_top__logo__3xGEM > img {
        margin: 0 auto;
        height: 100%;
        width: auto; }
    .SideBar_menu__top__4Oxhd .SideBar_top__icons__3QCpY {
      display: none;
      margin-left: auto;
      cursor: pointer;
      height: 100%; }
      .SideBar_menu__top__4Oxhd .SideBar_top__icons__3QCpY > a {
        display: flex;
        align-items: center; }
      .SideBar_menu__top__4Oxhd .SideBar_top__icons__3QCpY > *:not(:last-child) {
        margin-right: 25px; }
      .SideBar_menu__top__4Oxhd .SideBar_top__icons__3QCpY svg {
        fill: white;
        width: 25px; }
      @media (max-width: 767px) {
        .SideBar_menu__top__4Oxhd .SideBar_top__icons__3QCpY {
          display: flex;
          align-items: center; } }
  .SideBar_menu__1Ji07 .SideBar_menu__items__1Mozz, .SideBar_menu__1Ji07 .SideBar_menu__items--toggle-show__1rGi1 {
    background: white;
    padding: 50px 0 0 20px;
    box-sizing: border-box; }
    @media (max-width: 767px) {
      .SideBar_menu__1Ji07 .SideBar_menu__items__1Mozz, .SideBar_menu__1Ji07 .SideBar_menu__items--toggle-show__1rGi1 {
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        height: calc(100vh - 70px);
        width: 100%;
        padding: 20px 20px 70px 20px;
        flex-direction: column; } }
  .SideBar_menu__items__1Mozz {
    grid-area: items; }
    @media (max-width: 767px) {
      .SideBar_menu__items__1Mozz {
        display: none; } }
  .SideBar_menu__1Ji07 .SideBar_menu__item__Uv_gd, .SideBar_menu__1Ji07 .SideBar_menu__item--disabled__3jIrU, .SideBar_menu__1Ji07 .SideBar_mobile-profile-buttons__item__3TD_c {
    height: 50px;
    display: flex;
    align-items: center;
    color: #666B73;
    fill: #666B73;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500; }
    .SideBar_menu__1Ji07 .SideBar_menu__item__Uv_gd > svg, .SideBar_menu__1Ji07 .SideBar_menu__item--disabled__3jIrU > svg, .SideBar_menu__1Ji07 .SideBar_mobile-profile-buttons__item__3TD_c > svg {
      width: 20px;
      margin-right: 10px; }
    .SideBar_menu__1Ji07 .SideBar_menu__item__Uv_gd:hover, .SideBar_menu__1Ji07 .SideBar_menu__item--disabled__3jIrU:hover, .SideBar_menu__1Ji07 .SideBar_mobile-profile-buttons__item__3TD_c:hover {
      color: #F24D4B; }
      .SideBar_menu__1Ji07 .SideBar_menu__item__Uv_gd:hover > svg, .SideBar_menu__1Ji07 .SideBar_menu__item--disabled__3jIrU:hover > svg, .SideBar_menu__1Ji07 .SideBar_mobile-profile-buttons__item__3TD_c:hover > svg {
        fill: #F24D4B; }
    @media (max-width: 767px) {
      .SideBar_menu__1Ji07 .SideBar_menu__item__Uv_gd, .SideBar_menu__1Ji07 .SideBar_menu__item--disabled__3jIrU, .SideBar_menu__1Ji07 .SideBar_mobile-profile-buttons__item__3TD_c {
        font-size: 18px;
        height: 75px;
        width: 100%;
        display: flex;
        justify-content: center; } }
  .SideBar_menu__item--selected__tzi-C {
    color: #F24D4B !important; }
    .SideBar_menu__item--selected__tzi-C > svg {
      fill: #F24D4B !important; }
  .SideBar_menu__1Ji07 .SideBar_item__badge__1KxDx {
    border: 1px solid #EEB656;
    color: white;
    background: #EEB656;
    border-radius: 20px;
    padding: 2px 10px;
    font-size: 10px;
    text-transform: uppercase;
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600; }
  .SideBar_menu__item--disabled__3jIrU {
    cursor: default !important;
    color: #C3C8D0 !important;
    fill: #C3C8D0 !important; }
    .SideBar_menu__item--disabled__3jIrU:hover {
      color: #C3C8D0 !important; }
      .SideBar_menu__item--disabled__3jIrU:hover > svg {
        fill: #C3C8D0 !important; }
    .SideBar_menu__item--disabled__3jIrU .SideBar_item__badge__1KxDx {
      opacity: 0.6; }
  .SideBar_menu__logo__P7qe5 {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white; }
    @media (max-width: 767px) {
      .SideBar_menu__logo__P7qe5 {
        display: none; } }
    .SideBar_menu__logo__P7qe5 > svg {
      width: 50%;
      fill: #F24D4B; }

.SideBar_mobile-profile-buttons__-1Fyc {
  display: none; }
  @media (max-width: 767px) {
    .SideBar_mobile-profile-buttons__-1Fyc {
      display: flex;
      flex-direction: column;
      align-items: center; } }
  .SideBar_mobile-profile-buttons__disconnect__2oKer {
    z-index: 20;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 0;
    background: #F24D4B;
    color: white;
    height: 50px;
    width: 100%;
    text-transform: uppercase;
    font-weight: 500; }

.Toastify__toast {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.Toastify__toast--success {
  background: #27A49B; }

.Toastify__toast--error {
  background: #F24D4B; }

.select-websites-container {
  z-index: 30 !important;
  display: block !important;
  width: 100%;
  height: 100%; }
  @media (max-width: 767px) {
    .select-websites-container {
      max-width: 160px; } }
  .select-websites-container .select-websites__control {
    height: 100%;
    cursor: pointer;
    background: none;
    border: none !important; }
    .select-websites-container .select-websites__control--is-focused, .select-websites-container .select-websites__control--menu-is-open {
      box-shadow: none !important;
      border: none !important; }
  .select-websites-container .select-websites__value-container {
    padding: 0 !important;
    width: 90px; }
  .select-websites-container .select-websites__placeholder {
    font-size: 14px;
    color: #9AA9AB; }
  .select-websites-container .select-websites__single-value {
    color: white;
    text-transform: uppercase;
    margin: 0; }
  .select-websites-container .select-websites__menu {
    top: 65px;
    right: 0;
    width: 210px;
    letter-spacing: normal; }
  .select-websites-container .select-websites__option {
    color: #666B73;
    text-overflow: ellipsis;
    overflow: hidden; }
    .select-websites-container .select-websites__option--is-selected {
      background: #27A49B !important;
      color: white !important;
      text-decoration: none !important; }
    .select-websites-container .select-websites__option--is-focused {
      background: rgba(39, 164, 155, 0.2); }
    .select-websites-container .select-websites__option--is-disabled {
      color: #C3C3C3;
      text-decoration: line-through; }
    .select-websites-container .select-websites__option:active {
      background: rgba(39, 164, 155, 0.4); }
  .select-websites-container .select-websites__indicator-separator {
    display: none; }
  .select-websites-container .select-websites__indicator {
    padding: 0; }
    .select-websites-container .select-websites__indicator > svg {
      fill: white;
      width: 15px; }

.SelectWebsites_Toastify__toast__1CbA2 {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.SelectWebsites_Toastify__toast--success__5AUZ0 {
  background: #27A49B; }

.SelectWebsites_Toastify__toast--error__1M2rx {
  background: #F24D4B; }

.SelectWebsites_menu__top__3oCzP {
  padding: 6px 10px 10px 10px;
  display: flex;
  justify-content: center; }
  .SelectWebsites_menu__top__3oCzP button {
    background: white;
    border: 1px solid #F24D4B;
    color: #F24D4B; }
    .SelectWebsites_menu__top__3oCzP button > svg {
      fill: #F24D4B; }

.Modal_Toastify__toast__14DLB {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.Modal_Toastify__toast--success__3dwM1 {
  background: #27A49B; }

.Modal_Toastify__toast--error__ldmXx {
  background: #F24D4B; }

.Modal_modal__content__3s7pK {
  width: auto;
  height: auto;
  max-width: 100vw;
  max-height: calc(100vh - (20px * 2));
  position: relative;
  box-sizing: border-box;
  margin: auto;
  border: none;
  background: white;
  overflow: auto;
  border-radius: 20px;
  outline: none;
  padding: 20px; }
  @media (max-width: 767px) {
    .Modal_modal__content__3s7pK {
      width: 100%;
      height: 100%;
      max-height: 100vh;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border: 0 solid #cccccc;
      background: white;
      overflow-y: scroll;
      border-radius: 0;
      outline: none; } }

.Modal_modal__overlay__1tpLu {
  z-index: 999;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 20px;
  background-color: rgba(59, 169, 198, 0.5); }
  @media (max-width: 767px) {
    .Modal_modal__overlay__1tpLu {
      padding: 0; } }

.Modal_modal__closeicon__1TtP2 {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 30px;
  right: 30px;
  border-radius: 50%;
  font-size: 1px;
  border: 1px solid #9AA9AB;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: white;
  z-index: 1; }
  .Modal_modal__closeicon__1TtP2 svg {
    width: 10px;
    height: 10px;
    fill: #9AA9AB; }
  .Modal_modal__closeicon__1TtP2:hover {
    background: #9AA9AB; }
    .Modal_modal__closeicon__1TtP2:hover svg {
      fill: white; }

.NewAuditModal_Toastify__toast__1Qsbl {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.NewAuditModal_Toastify__toast--success___SUsY {
  background: #27A49B; }

.NewAuditModal_Toastify__toast--error__2wVfz {
  background: #F24D4B; }

.NewAuditModal_container__2Wvft {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  max-width: 700px;
  padding: 50px; }
  @media (max-width: 1190px) {
    .NewAuditModal_container__2Wvft {
      padding: 20px; } }
  .NewAuditModal_container__2Wvft h3 {
    margin-bottom: 20px; }
  .NewAuditModal_container__desc__2cbFe {
    text-align: justify;
    font-size: 14px;
    color: #9AA9AB; }

.NewAuditModal_wrapper--disabled__V9HFs {
  opacity: 0.1;
  pointer-events: none; }

.NewAuditModal_subscriptionContainer__2Byph {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column; }
  .NewAuditModal_subscriptionContainer__2Byph p {
    max-width: 500px;
    font-size: 20px;
    font-weight: 500; }
    .NewAuditModal_subscriptionContainer__2Byph p span {
      background: #EEB656;
      padding: 2px 12px;
      color: white;
      border-radius: 6px;
      text-transform: uppercase; }
  .NewAuditModal_subscriptionContainer__2Byph button {
    margin-top: 50px; }

.InputUrl_Toastify__toast__1ZkE0 {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.InputUrl_Toastify__toast--success__2_Aap {
  background: #27A49B; }

.InputUrl_Toastify__toast--error__3QK1E {
  background: #F24D4B; }

.InputUrl_container__tM-SY {
  width: 100%;
  box-sizing: border-box;
  letter-spacing: normal; }

.InputUrl_form__2FUXg {
  color: #43464C;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin-top: 50px; }
  .InputUrl_form__2FUXg label {
    margin-bottom: 10px;
    color: #666B73; }
  .InputUrl_form__input-container__fqVuq {
    box-sizing: border-box;
    display: flex; }
    .InputUrl_form__input-container__fqVuq input {
      height: 40px;
      width: 100%;
      font-size: medium;
      padding: 10px 15px;
      border: 1px solid #C3C3C3;
      box-sizing: border-box;
      border-radius: 6px 0 0 6px;
      color: #43464C; }
      .InputUrl_form__input-container__fqVuq input:focus {
        outline: 0;
        border-bottom: 1px solid #C3C3C3; }
      .InputUrl_form__input-container__fqVuq input::-webkit-input-placeholder {
        color: #9AA9AB; }
      .InputUrl_form__input-container__fqVuq input::-ms-input-placeholder {
        color: #9AA9AB; }
      .InputUrl_form__input-container__fqVuq input::placeholder {
        color: #9AA9AB; }
    .InputUrl_form__input-container__fqVuq button {
      font-weight: 600;
      border: none;
      color: white;
      background: #F24D4B;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 0 6px 6px 0;
      padding: 8px 30px;
      text-transform: uppercase; }
      .InputUrl_form__input-container__fqVuq button:focus {
        outline: 0; }
      .InputUrl_form__input-container__fqVuq button:disabled {
        cursor: default;
        pointer-events: none;
        background: #9AA9AB; }
  .InputUrl_form__error__3DBCz {
    margin-top: 5px;
    font-size: 12px;
    color: #F24D4B; }

@media (max-width: 580px) {
  .InputUrl_form__input-container__fqVuq {
    flex-direction: column; }
    .InputUrl_form__input-container__fqVuq input {
      border-radius: 6px 6px 0 0; }
    .InputUrl_form__input-container__fqVuq button {
      border-radius: 0 0 6px 6px;
      padding: 15px 30px; } }

.Header_Toastify__toast__KZa65 {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.Header_Toastify__toast--success__25u4l {
  background: #27A49B; }

.Header_Toastify__toast--error__2dCTz {
  background: #F24D4B; }

@-webkit-keyframes Header_bounce-top__1zR-z {
  0% {
    transform: translateY(-45px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 1; }
  24% {
    opacity: 1; }
  40% {
    transform: translateY(-24px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in; }
  65% {
    transform: translateY(-12px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in; }
  82% {
    transform: translateY(-6px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in; }
  93% {
    transform: translateY(-4px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in; }
  25%,
  55%,
  75%,
  87% {
    transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out; }
  100% {
    transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 1; } }

@keyframes Header_bounce-top__1zR-z {
  0% {
    transform: translateY(-45px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 1; }
  24% {
    opacity: 1; }
  40% {
    transform: translateY(-24px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in; }
  65% {
    transform: translateY(-12px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in; }
  82% {
    transform: translateY(-6px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in; }
  93% {
    transform: translateY(-4px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in; }
  25%,
  55%,
  75%,
  87% {
    transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out; }
  100% {
    transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 1; } }

.Header_header__rkPje {
  height: 100%;
  width: 100%;
  border-bottom: 1px solid #F2F2F2;
  display: flex;
  justify-content: space-between;
  background: white;
  font-weight: normal; }
  .Header_header__rkPje > div {
    height: 100%; }
  .Header_header__left__3nQBj {
    display: flex; }
    .Header_header__left__3nQBj .Header_left__subscription__33pPJ {
      display: flex;
      align-items: center;
      padding: 0 20px; }
      .Header_header__left__3nQBj .Header_left__subscription__33pPJ > a {
        display: flex;
        align-items: center;
        color: white;
        font-weight: 500;
        font-size: 12px;
        padding: 8px 12px;
        background: #EEB656;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: all .1s ease-in-out; }
        .Header_header__left__3nQBj .Header_left__subscription__33pPJ > a:hover {
          transform: scale(1.05); }
        .Header_header__left__3nQBj .Header_left__subscription__33pPJ > a:focus {
          outline: 0; }
        .Header_header__left__3nQBj .Header_left__subscription__33pPJ > a svg {
          fill: white;
          height: 12px;
          width: 12px;
          margin-right: 5px; }
  .Header_header__right__1FjwX {
    display: flex; }
    .Header_header__right__1FjwX > div {
      height: 100%; }
    .Header_header__right__1FjwX .Header_right__help__1iiDd {
      align-self: center;
      display: flex;
      justify-content: center;
      margin-right: 20px;
      align-items: center;
      cursor: pointer;
      height: -webkit-max-content;
      height: -moz-max-content;
      height: max-content; }
      .Header_header__right__1FjwX .Header_right__help__1iiDd svg {
        width: 20px;
        stroke: #9AA9AB; }
    .Header_header__right__1FjwX .Header_right__gopro__1Fj1x {
      display: flex;
      font-size: 14px;
      align-items: center;
      padding-right: 20px;
      text-decoration: underline;
      color: #F24D4B; }
      .Header_header__right__1FjwX .Header_right__gopro__1Fj1x > span {
        cursor: pointer; }
    .Header_header__right__1FjwX .Header_right__profile__1kk6d {
      z-index: 1;
      min-width: 220px;
      overflow: hidden;
      border-left: 1px solid #F2F2F2;
      border-right: 1px solid #F2F2F2;
      background: white;
      height: -webkit-max-content;
      height: -moz-max-content;
      height: max-content;
      cursor: pointer; }
      .Header_header__right__1FjwX .Header_right__profile__1kk6d:hover {
        flex-direction: column;
        justify-content: flex-start;
        border-bottom: 1px solid #F2F2F2;
        box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
        border-radius: 0 0px 6px 6px; }
        .Header_header__right__1FjwX .Header_right__profile__1kk6d:hover .Header_right__profile-menu__2MPEH {
          display: block;
          cursor: default; }
        .Header_header__right__1FjwX .Header_right__profile__1kk6d:hover .Header_right__profile-container__3C-E_ {
          color: #F24D4B; }
          .Header_header__right__1FjwX .Header_right__profile__1kk6d:hover .Header_right__profile-container__3C-E_ > svg {
            fill: #F24D4B; }
      .Header_header__right__1FjwX .Header_right__profile__1kk6d .Header_right__profile-container__3C-E_ {
        height: 80px;
        padding: 0 20px;
        font-size: 11px;
        letter-spacing: 1.5px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase; }
        .Header_header__right__1FjwX .Header_right__profile__1kk6d .Header_right__profile-container__3C-E_ > svg {
          width: 10px;
          fill: #666B73;
          margin-left: 10px; }
        .Header_header__right__1FjwX .Header_right__profile__1kk6d .Header_right__profile-container__3C-E_ .Header_right__profile-picture__al_IN {
          background: white;
          border-radius: 50%;
          width: 35px;
          height: 35px;
          overflow: hidden;
          margin-right: 10px; }
          .Header_header__right__1FjwX .Header_right__profile__1kk6d .Header_right__profile-container__3C-E_ .Header_right__profile-picture__al_IN > img {
            margin: 0 auto;
            height: 100%; }
      .Header_header__right__1FjwX .Header_right__profile__1kk6d .Header_right__profile-menu__2MPEH {
        display: none;
        width: 100%;
        overflow: hidden; }
        .Header_header__right__1FjwX .Header_right__profile__1kk6d .Header_right__profile-menu__2MPEH .Header_profile-menu__items__2nIVq {
          padding: 10px 30px 20px 30px;
          margin: 0; }
          .Header_header__right__1FjwX .Header_right__profile__1kk6d .Header_right__profile-menu__2MPEH .Header_profile-menu__items__2nIVq .Header_profile-menu__item__3Bh0F {
            font-size: 13px;
            cursor: pointer;
            list-style-type: none;
            padding: 12px 0;
            display: flex;
            align-items: center; }
            .Header_header__right__1FjwX .Header_right__profile__1kk6d .Header_right__profile-menu__2MPEH .Header_profile-menu__items__2nIVq .Header_profile-menu__item__3Bh0F > svg {
              fill: #F24D4B;
              height: 20px;
              margin-right: 10px; }
            .Header_header__right__1FjwX .Header_right__profile__1kk6d .Header_right__profile-menu__2MPEH .Header_profile-menu__items__2nIVq .Header_profile-menu__item__3Bh0F:hover {
              color: #F24D4B; }
        .Header_header__right__1FjwX .Header_right__profile__1kk6d .Header_right__profile-menu__2MPEH .Header_profile-menu__disconnect__1sUfE {
          cursor: pointer;
          text-transform: uppercase;
          font-size: 11px;
          font-weight: 500;
          letter-spacing: 1.5px;
          height: 40px;
          background: #F24D4B;
          color: white;
          display: flex;
          justify-content: center;
          align-items: center; }
    .Header_header__right__1FjwX .Header_right__shopping__okaAY {
      width: 80px;
      background: #F24D4B;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      position: relative; }
      .Header_header__right__1FjwX .Header_right__shopping__okaAY > svg {
        fill: white;
        width: 20px; }
      .Header_header__right__1FjwX .Header_right__shopping__okaAY .Header_shopping__number__2RsAI {
        -webkit-animation: Header_bounce-top__1zR-z 0.9s both;
                animation: Header_bounce-top__1zR-z 0.9s both;
        position: absolute;
        font-size: 10px;
        top: 23px;
        right: 20px;
        background: white;
        color: #F24D4B;
        border-radius: 50%;
        width: 15px;
        height: 15px;
        display: flex;
        justify-content: center;
        align-items: center; }

.ArrowShowCart_Toastify__toast__2PQqn {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.ArrowShowCart_Toastify__toast--success__2fLFF {
  background: #27A49B; }

.ArrowShowCart_Toastify__toast--error__2smOO {
  background: #F24D4B; }

/**
 * ----------------------------------------
 * animation slide-in-blurred-right
 * ---------------------------------------- */
@-webkit-keyframes ArrowShowCart_slide-in-blurred-right__2uU9L {
  0% {
    transform: translateX(1000px) scaleX(2.5) scaleY(0.2);
    transform-origin: 0% 50%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0; }
  100% {
    transform: translateX(0) scaleY(1) scaleX(1);
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1; } }

@keyframes ArrowShowCart_slide-in-blurred-right__2uU9L {
  0% {
    transform: translateX(1000px) scaleX(2.5) scaleY(0.2);
    transform-origin: 0% 50%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0; }
  100% {
    transform: translateX(0) scaleY(1) scaleX(1);
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1; } }

.ArrowShowCart_container__2E8qC {
  position: absolute;
  right: 100px;
  top: 100px;
  -webkit-animation: ArrowShowCart_slide-in-blurred-right__2uU9L 0.5s cubic-bezier(0.23, 1, 0.32, 1) both;
          animation: ArrowShowCart_slide-in-blurred-right__2uU9L 0.5s cubic-bezier(0.23, 1, 0.32, 1) both; }
  .ArrowShowCart_container__close__aU4cs {
    position: absolute;
    right: 12px;
    top: 6px; }
    .ArrowShowCart_container__close__aU4cs svg {
      width: 10px;
      fill: #C3C3C3; }
  .ArrowShowCart_container__2E8qC p {
    background: white;
    border: 3px solid #27A49B;
    color: #27A49B;
    padding: 20px 30px;
    box-sizing: border-box;
    border-radius: 6px;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1); }
  .ArrowShowCart_container__2E8qC img {
    position: absolute;
    right: -50px;
    top: -15px;
    transform: rotate(-120deg);
    cursor: default;
    pointer-events: none; }

.Footer_Toastify__toast__37SMT {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.Footer_Toastify__toast--success__3e-3G {
  background: #27A49B; }

.Footer_Toastify__toast--error__1Q_Eb {
  background: #F24D4B; }

.Footer_wrapper__3ZYhZ {
  z-index: 1;
  background: white;
  height: 75px;
  bottom: 0;
  color: #9AA9AB;
  font-size: 14px;
  padding: 0 20px;
  box-sizing: border-box;
  border: 1px solid #F2F2F2;
  width: 100%; }
  @media (max-width: 767px) {
    .Footer_wrapper__3ZYhZ {
      display: none; } }

.Footer_container__9qAOs {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 920px;
  margin: 0 auto; }
  @media (max-width: 1190px) {
    .Footer_container__9qAOs {
      justify-content: space-around; } }
  .Footer_container__elem__3NSIL {
    display: flex;
    align-items: center;
    max-width: 280px;
    margin: 0 10px; }
    .Footer_container__elem__3NSIL > img {
      margin-right: 10px; }
      @media (max-width: 1190px) {
        .Footer_container__elem__3NSIL > img {
          margin: 0; } }
    @media (max-width: 1190px) {
      .Footer_container__elem__3NSIL > span {
        display: none; } }

.Footer_separator__1cTVN {
  width: 1px;
  background: #F2F2F2;
  height: 30px; }
  @media (max-width: 1190px) {
    .Footer_separator__1cTVN {
      display: none; } }

.Form_Toastify__toast__19xpF {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.Form_Toastify__toast--success__1sFof {
  background: #27A49B; }

.Form_Toastify__toast--error__3KZqF {
  background: #F24D4B; }

.Form_container__1Vhuj {
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  align-items: center; }
  .Form_container__title__13qEg {
    font-size: 14px; }
  .Form_containerrdv__dYLjM {
    display: flex; }
  .Form_container__1Vhuj .Form_form__2s2LG {
    display: flex;
    flex-direction: column;
    align-items: center; }
    .Form_container__1Vhuj .Form_form__input-container__1qTp4 {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin: 0 -25px; }
      .Form_container__1Vhuj .Form_form__input-container__1qTp4 > label {
        margin: 20px 25px;
        flex: 1 0 300px; }
    .Form_container__1Vhuj .Form_form__button__102a2 {
      margin: 20px auto 0 auto; }

.Form_question__21xi9 {
  margin-top: 10px; }

.Contact_Toastify__toast__1BITA {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.Contact_Toastify__toast--success__2zTNF {
  background: #27A49B; }

.Contact_Toastify__toast--error__1FIKz {
  background: #F24D4B; }

.Contact_container__29Jzm {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  max-width: 920px;
  padding: 50px 20px; }
  @media (max-width: 1190px) {
    .Contact_container__29Jzm {
      padding: 20px; } }
  .Contact_container__29Jzm > span {
    margin-bottom: 10px; }

.LoadingPage_Toastify__toast__3B9P5 {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.LoadingPage_Toastify__toast--success__26YGZ {
  background: #27A49B; }

.LoadingPage_Toastify__toast--error__gAaMn {
  background: #F24D4B; }

.LoadingPage_container__2ehJD {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }

.Popup_Toastify__toast__15Tt3 {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.Popup_Toastify__toast--success__8fXbf {
  background: #27A49B; }

.Popup_Toastify__toast--error__Cnd5t {
  background: #F24D4B; }

/* ----------------------------------------------
 * Generated by Animista on 2019-12-5 15:15:0
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
/**
 * ----------------------------------------
 * animation slide-in-bottom
 * ---------------------------------------- */
@-webkit-keyframes Popup_slide-in-bottom__2uTZC {
  0% {
    transform: translateY(1000px);
    opacity: 0; }
  100% {
    transform: translateY(0);
    opacity: 1; } }

@keyframes Popup_slide-in-bottom__2uTZC {
  0% {
    transform: translateY(1000px);
    opacity: 0; }
  100% {
    transform: translateY(0);
    opacity: 1; } }

/* ----------------------------------------------
 * Generated by Animista on 2019-12-5 15:19:56
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
/**
 * ----------------------------------------
 * animation slide-out-bottom
 * ---------------------------------------- */
@-webkit-keyframes Popup_slide-out-bottom__JiRnb {
  0% {
    transform: translateY(0);
    opacity: 1; }
  100% {
    transform: translateY(1000px);
    opacity: 0; } }

@keyframes Popup_slide-out-bottom__JiRnb {
  0% {
    transform: translateY(0);
    opacity: 1; }
  100% {
    transform: translateY(1000px);
    opacity: 0; } }

.Popup_show__28JpH {
  display: block !important; }

.Popup_hidden__hPiTz {
  -webkit-animation: Popup_slide-out-bottom__JiRnb 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both !important;
          animation: Popup_slide-out-bottom__JiRnb 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both !important; }

.Popup_popup__1XYHP {
  display: none;
  box-shadow: 7px 7px 20px 0px rgba(50, 50, 50, 0.2);
  -webkit-animation: Popup_slide-in-bottom__2uTZC 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
          animation: Popup_slide-in-bottom__2uTZC 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  position: fixed;
  background: #F24D4B;
  z-index: 999;
  bottom: 20px;
  left: 20px;
  border-radius: 6px; }
  .Popup_popup__container__O_RC2 {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 50px;
    text-align: center;
    color: white; }
  .Popup_popup__title__1WRy0 {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 30px; }
  .Popup_popup__promo__3pODE {
    font-weight: 600;
    font-size: 35px;
    margin-bottom: 10px; }
  .Popup_popup__quit__2q7fB {
    position: absolute;
    right: 15px;
    top: 15px;
    line-height: 1;
    cursor: pointer;
    align-self: flex-end; }
  .Popup_popup__1XYHP a {
    border: none;
    margin-top: 20px; }

@media (max-width: 580px) {
  .Popup_popup__1XYHP {
    width: auto;
    right: 20px; }
    .Popup_popup__container__O_RC2 {
      padding: 20px; }
    .Popup_popup__title__1WRy0 {
      margin-bottom: 10px; }
    .Popup_popup__promo__3pODE {
      font-size: 30px; }
    .Popup_popup__1XYHP a {
      margin-top: 10px; } }

.Toastify__toast {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.Toastify__toast--success {
  background: #27A49B; }

.Toastify__toast--error {
  background: #F24D4B; }

.ReactTable {
  width: 100%;
  background-color: white;
  border: solid 2px #F2F2F2;
  border-radius: 6px;
  color: #626B6B;
  font-size: 14px;
  box-sizing: border-box; }

.rt-thead {
  font-weight: 500;
  height: 60px;
  box-shadow: none;
  border-bottom: solid 2px #F2F2F2; }
  .rt-thead .rt-th {
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: solid 2px #F2F2F2 !important; }
    .rt-thead .rt-th:last-child {
      border-right: 0 !important; }

.rt-tr-group {
  border-bottom: solid 2px #F2F2F2 !important;
  min-height: 40px !important; }
  .rt-tr-group:last-child {
    border-bottom: solid 1px #F2F2F2 !important; }
  .rt-tr-group:nth-child(odd) {
    background: #FBFBFB; }

.rt-td {
  border-right: solid 2px #F2F2F2 !important;
  display: flex;
  align-items: center;
  padding: 0 10px !important; }
  .rt-td:last-child {
    border-right: 0 !important; }

.-btn:enabled {
  border: solid 1px #F24D4B !important;
  background: white !important;
  color: #F24D4B !important; }

.-btn:disabled {
  border: solid 1px #F24D4B !important;
  background: white !important;
  color: #F24D4B !important;
  opacity: 0.5; }

.-pagination {
  box-shadow: none !important;
  border-top: solid 1px #F2F2F2 !important; }

.-pageJump input {
  border: 1px solid #C3C3C3 !important; }

.rt-th.-sort-asc, .rt-td.-sort-asc {
  box-shadow: inset 0 3px 0 0 #F24D4B !important; }

.rt-th.-sort-desc, .rt-td.-sort-desc {
  box-shadow: inset 0 -3px 0 0 #F24D4B !important; }

.rt-noData {
  z-index: 0 !important; }

.-loading, .-active {
  z-index: 0 !important; }

.Screenshot_Toastify__toast__3OpqQ {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.Screenshot_Toastify__toast--success__2uTnX {
  background: #27A49B; }

.Screenshot_Toastify__toast--error__1hfMT {
  background: #F24D4B; }

.Screenshot_container__2zfAC {
  border: 1px solid #F2F2F2;
  display: flex;
  flex-direction: column;
  width: 250px;
  height: 100%;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
  background: #9aa9ab45; }
  .Screenshot_container__score__1rsFU {
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 75px;
    background: white;
    border-radius: 0 6px 0 0;
    border-top: 1px solid #F2F2F2;
    border-right: 1px solid #F2F2F2; }
    .Screenshot_container__score__1rsFU span {
      font-size: 4em;
      color: #43464C;
      font-weight: 900; }

.Screenshot_header__dot--red__27XZF, .Screenshot_header__dot--yellow__1YeyF, .Screenshot_header__dot--green__1Waqe {
  min-height: 12px;
  min-width: 12px;
  margin-right: 7px;
  border-radius: 50%; }

.Screenshot_header__3Qqm8 {
  display: flex;
  align-items: center;
  background: white;
  padding: 10px 15px;
  border-bottom: 1px solid #F2F2F2;
  font-size: small; }
  .Screenshot_header__dot--red__27XZF {
    background-color: #F24D4B; }
  .Screenshot_header__dot--yellow__1YeyF {
    background-color: #EEB656; }
  .Screenshot_header__dot--green__1Waqe {
    background-color: #34C749; }
  .Screenshot_header__domain__2wGkn {
    color: #9AA9AB;
    margin-left: 5px;
    display: flex;
    align-items: center; }
    .Screenshot_header__domain__2wGkn .Screenshot_domain__url__34KBi {
      color: #43464C;
      font-weight: 400;
      display: inline-block;
      width: 120px;
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis; }

.Screenshot_image__2Wm59 {
  display: flex;
  width: 100%;
  height: 160px; }
  .Screenshot_image__screenshot__1uuWz {
    height: auto;
    margin: auto;
    min-height: 100%;
    width: 100%; }
  .Screenshot_image--add__16IEo {
    justify-content: center;
    align-items: center;
    cursor: pointer; }
    .Screenshot_image--add__16IEo svg {
      width: 50px;
      height: 50px;
      fill: #9AA9AB; }

.SelectionList_Toastify__toast__2XdxC {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.SelectionList_Toastify__toast--success__3oGC4 {
  background: #27A49B; }

.SelectionList_Toastify__toast--error__2HAhT {
  background: #F24D4B; }

.SelectionList_table__3We2P {
  width: 100%;
  font-size: 14px;
  text-align: left;
  border-radius: 6px !important;
  border: 2px solid #F2F2F2;
  background: white;
  border-spacing: 0;
  overflow: hidden; }
  .SelectionList_table__3We2P:hover .SelectionList_table__counter--more__3GTkB svg {
    -webkit-animation: SelectionList_shake-top__23_sx 0.8s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
            animation: SelectionList_shake-top__23_sx 0.8s cubic-bezier(0.455, 0.03, 0.515, 0.955) both; }
  .SelectionList_table__3We2P .SelectionList_table__counter__3us2x, .SelectionList_table__3We2P .SelectionList_table__counter--max__1o55S, .SelectionList_table__3We2P .SelectionList_table__counter--more__3GTkB {
    margin-left: auto;
    font-weight: normal; }
  .SelectionList_table__counter--max__1o55S {
    color: #27A49B; }
  .SelectionList_table__counter--more__3GTkB {
    display: flex;
    align-items: center;
    color: #F24D4B; }
    .SelectionList_table__counter--more__3GTkB svg {
      width: 14px;
      height: 14px;
      margin-left: 5px;
      fill: #F24D4B; }
  .SelectionList_table__3We2P th {
    display: flex;
    align-items: center;
    padding: 0 20px;
    height: 60px;
    box-sizing: border-box;
    font-weight: 500;
    border-bottom: 2px solid #F2F2F2; }
  .SelectionList_table__3We2P td, .SelectionList_table__3We2P .SelectionList_td--input__6JZYo {
    height: auto;
    min-height: 40px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    box-sizing: border-box; }
  .SelectionList_table__3We2P .SelectionList_td--input__6JZYo {
    padding: 0;
    background: #FBFBFB; }
    .SelectionList_table__3We2P .SelectionList_td--input__6JZYo > button {
      margin-left: 20px; }
  .SelectionList_table__3We2P tbody {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    display: flex;
    flex-direction: column; }
    .SelectionList_table__3We2P tbody tr:nth-child(odd) {
      background: #FBFBFB; }
    .SelectionList_table__3We2P tbody tr:not(:last-child) td {
      border-bottom: 2px solid #F2F2F2; }

.SelectionList_form__2mOZd {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center; }
  .SelectionList_form__2mOZd input {
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    font-size: 12px;
    color: inherit;
    border: none;
    padding: 0 10px;
    background: none; }
    .SelectionList_form__2mOZd input:focus {
      outline: 0; }
    .SelectionList_form__2mOZd input::-webkit-input-placeholder {
      opacity: 0.5; }
    .SelectionList_form__2mOZd input::-ms-input-placeholder {
      opacity: 0.5; }
    .SelectionList_form__2mOZd input::placeholder {
      opacity: 0.5; }
  .SelectionList_form__2mOZd button {
    margin: 0 10px; }

@-webkit-keyframes SelectionList_slide__32Qvh {
  0% {
    transform: translateX(-100%); }
  100% {
    transform: translateX(100%); } }

@keyframes SelectionList_slide__32Qvh {
  0% {
    transform: translateX(-100%); }
  100% {
    transform: translateX(100%); } }

.SelectionList_maxMessage__2J8sx {
  background: #EEB656 !important;
  color: white;
  cursor: pointer;
  height: auto !important;
  padding: 20px !important;
  display: flex;
  align-items: flex-start !important;
  flex-direction: column;
  font-weight: 500;
  font-size: 16px;
  position: relative; }
  .SelectionList_maxMessage__2J8sx button {
    margin-top: 20px;
    margin-left: 0 !important;
    align-self: flex-end;
    color: white; }
    .SelectionList_maxMessage__2J8sx button svg {
      fill: white; }

.StepTitle_Toastify__toast__19lVR {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.StepTitle_Toastify__toast--success__3i5xD {
  background: #27A49B; }

.StepTitle_Toastify__toast--error__21h9h {
  background: #F24D4B; }

.StepTitle_title__1eCK4 {
  position: relative;
  display: flex;
  align-items: center; }
  .StepTitle_title__1eCK4:before {
    content: attr(data-step);
    color: #F24D4B;
    font-weight: 300;
    margin-right: 45px; }
  .StepTitle_title__1eCK4:after {
    content: "";
    width: 1px;
    height: 60%;
    background: #C3C3C3;
    position: absolute;
    left: 30px; }

.Table_Toastify__toast__uJR5u {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.Table_Toastify__toast--success__15HQt {
  background: #27A49B; }

.Table_Toastify__toast--error__DV1oa {
  background: #F24D4B; }

.Table_table__2irt1 {
  border-spacing: 0;
  font-size: 14px;
  line-height: 1;
  width: 100%; }
  .Table_table__2irt1 thead {
    border-bottom: 1px solid #F2F2F2; }
  .Table_table__2irt1 tbody tr:not(:last-child) {
    border-bottom: 1px solid #F2F2F2; }
  .Table_table__2irt1 th:not(:first-child), .Table_table__2irt1 td:not(:first-child) {
    padding-left: 20px; }
  .Table_table__2irt1 th {
    color: #27A49B;
    font-weight: 500;
    text-align: left;
    padding-bottom: 10px; }
  .Table_table__2irt1 td {
    padding: 10px 0;
    line-height: 1.5; }

.Table_table__wrapper__23haj {
  position: relative; }

.Table_loading__2CvWs {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.9);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease-in-out, z-index 0.3s; }
  .Table_loading--show__Tq2Ve {
    z-index: 1;
    opacity: 1; }
  .Table_loading--hide__17-Z2 {
    opacity: 0;
    z-index: -1; }

.Table_no-data__V8wp3 {
  display: flex;
  justify-content: center;
  width: 100% !important;
  padding: 30px 0 !important;
  font-style: italic;
  color: #9AA9AB; }

.Tabs_Toastify__toast__3usj8 {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.Tabs_Toastify__toast--success__22XNy {
  background: #27A49B; }

.Tabs_Toastify__toast--error__1EHWD {
  background: #F24D4B; }

.Tabs_tabs__list__1X2bh {
  border-bottom: 2px solid #F2F2F2;
  padding-left: 0;
  margin: 0 0 20px; }
  .Tabs_tabs__list--active__Lkn0t {
    background-color: white;
    border: solid #F2F2F2;
    border-width: 2px 2px 0 2px;
    color: #666B73 !important; }

.Tabs_tabs__list-item__1N1gR {
  display: inline-block;
  list-style: none;
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 6px 6px 0 0;
  margin-bottom: -2px;
  color: #9AA9AB;
  font-weight: 500; }

.TextArea_Toastify__toast__11r49 {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.TextArea_Toastify__toast--success__LgFy8 {
  background: #27A49B; }

.TextArea_Toastify__toast--error__3OZ6N {
  background: #F24D4B; }

.TextArea_container__2kAVt {
  display: flex;
  flex-direction: column;
  width: 100%; }

.TextArea_label__24LJK {
  font-weight: 500;
  margin-bottom: 5px; }

.TextArea_textarea__1G5jo {
  box-sizing: border-box;
  width: 100%;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: inherit;
  border: 1px solid #9AA9AB;
  color: inherit;
  border-radius: 6px;
  resize: none; }
  .TextArea_textarea__1G5jo::-webkit-input-placeholder {
    opacity: 0.5; }
  .TextArea_textarea__1G5jo::-ms-input-placeholder {
    opacity: 0.5; }
  .TextArea_textarea__1G5jo::placeholder {
    opacity: 0.5; }
  .TextArea_textarea__1G5jo:focus {
    outline: 0;
    border: 1px solid #666B73; }
  .TextArea_textarea--error__zUZGQ {
    border: 1px solid #F24D4B; }
    .TextArea_textarea--error__zUZGQ:focus {
      border: 1px solid #F24D4B; }

.TextArea_error__ZU8Vg {
  align-self: flex-end;
  color: #F24D4B; }

.Tile_Toastify__toast__1q5Jf {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.Tile_Toastify__toast--success__e_yua {
  background: #27A49B; }

.Tile_Toastify__toast--error__2-2XT {
  background: #F24D4B; }

@-webkit-keyframes Tile_progressBarAnimation__1Kg2W {
  from {
    width: 0; } }

@keyframes Tile_progressBarAnimation__1Kg2W {
  from {
    width: 0; } }

@-webkit-keyframes Tile_shake-top__2iFiD {
  0%,
  100% {
    transform: rotate(0deg);
    transform-origin: 50% 0; }
  10% {
    transform: rotate(2deg); }
  20%,
  40%,
  60% {
    transform: rotate(-4deg); }
  30%,
  50%,
  70% {
    transform: rotate(4deg); }
  80% {
    transform: rotate(-2deg); }
  90% {
    transform: rotate(2deg); } }

@keyframes Tile_shake-top__2iFiD {
  0%,
  100% {
    transform: rotate(0deg);
    transform-origin: 50% 0; }
  10% {
    transform: rotate(2deg); }
  20%,
  40%,
  60% {
    transform: rotate(-4deg); }
  30%,
  50%,
  70% {
    transform: rotate(4deg); }
  80% {
    transform: rotate(-2deg); }
  90% {
    transform: rotate(2deg); } }

.Tile_card--complete__27l1L, .Tile_card__3RTTc {
  height: 242px;
  cursor: pointer;
  width: 206px;
  background: white;
  border: 2px solid #F2F2F2;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
  transition: box-shadow 0.3s ease;
  padding: 20px;
  box-sizing: border-box;
  display: grid;
  grid-template-rows: 2fr 1fr 20px;
  grid-template-columns: 2fr 1fr;
  grid-template-areas: "icon icon" "title title" "progressBar progressNumber";
  grid-row-gap: 20px; }
  .Tile_card--complete__27l1L:hover, .Tile_card__3RTTc:hover {
    box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.1); }
    .Tile_card--complete__27l1L:hover .Tile_card__button__4mpeY, .Tile_card__3RTTc:hover .Tile_card__button__4mpeY {
      opacity: 1;
      height: 40px; }
    .Tile_card--complete__27l1L:hover .Tile_card__price__3sE5x, .Tile_card__3RTTc:hover .Tile_card__price__3sE5x {
      opacity: 1; }
    .Tile_card--complete__27l1L:hover .Tile_card__lock__3EKSV, .Tile_card__3RTTc:hover .Tile_card__lock__3EKSV {
      -webkit-animation: Tile_shake-top__2iFiD 0.8s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
              animation: Tile_shake-top__2iFiD 0.8s cubic-bezier(0.455, 0.03, 0.515, 0.955) both; }

.Tile_card--complete__27l1L > div {
  opacity: 0.6; }

.Tile_card__cart__3jH38 {
  position: absolute;
  left: 15px;
  top: 10px; }
  .Tile_card__cart__3jH38 svg {
    width: 15px;
    fill: #F24D4B; }

.Tile_card__lock__3EKSV {
  position: absolute;
  left: 15px;
  top: 15px; }
  .Tile_card__lock__3EKSV svg {
    -webkit-animation: Tile_shake-top__2iFiD 0.8s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
            animation: Tile_shake-top__2iFiD 0.8s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
    width: 15px;
    fill: #F24D4B; }

.Tile_card__ribbon__1Qlvx {
  width: 200px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #EEB656;
  position: absolute;
  top: 20px;
  right: -65px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  letter-spacing: 1px;
  color: white;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 10px;
  transition: opacity 0.3s ease; }

.Tile_card__icon__Qvvtd {
  grid-area: icon;
  display: flex;
  justify-content: center;
  align-items: center; }
  .Tile_card__icon__Qvvtd > svg {
    stroke: #27A49B;
    width: 60px; }

.Tile_card__title__3hQsc {
  grid-area: title;
  margin-top: auto;
  font-size: 17px;
  font-weight: 400; }

.Tile_card__price__3sE5x {
  position: absolute;
  opacity: 0;
  transition: opacity .3s ease;
  color: #F24D4B;
  right: 15px;
  top: 10px;
  font-size: 15px;
  font-weight: 500; }

.Tile_card__price--disabled__wyv38 {
  display: none; }

.Tile_card__progressWrapper__2QZVr {
  grid-area: progressBar;
  display: flex;
  align-items: center; }
  .Tile_card__progressWrapper__2QZVr .Tile_card__progressBar__29EDi {
    width: 100%;
    height: 2px;
    background: #F2F2F2; }
    .Tile_card__progressWrapper__2QZVr .Tile_card__progressBar__29EDi .Tile_card__progress__3Sryj, .Tile_card__progressWrapper__2QZVr .Tile_card__progressBar__29EDi .Tile_card__progress--complete__RBxRb {
      -webkit-animation: Tile_progressBarAnimation__1Kg2W .3s ease 1;
              animation: Tile_progressBarAnimation__1Kg2W .3s ease 1;
      height: 100%; }
    .Tile_card__progressWrapper__2QZVr .Tile_card__progressBar__29EDi .Tile_card__progress__3Sryj {
      background: #F24D4B; }
    .Tile_card__progressWrapper__2QZVr .Tile_card__progressBar__29EDi .Tile_card__progress--complete__RBxRb {
      background: #27A49B; }

.Tile_card__progressNumber__TTZOk {
  grid-area: progressNumber;
  display: flex;
  align-items: center;
  padding-left: 20px;
  font-size: 11px; }
  .Tile_card__progressNumber__TTZOk > svg {
    fill: #27A49B;
    width: 12px;
    margin-left: auto; }

.Tile_card__button__4mpeY, .Tile_card__button--disabled__38H4t {
  position: absolute;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 1.5px;
  opacity: 0;
  bottom: 0;
  left: 0;
  background: #F24D4B;
  width: 100%;
  height: 0;
  color: white;
  border: none;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55); }
  .Tile_card__button__4mpeY:focus, .Tile_card__button--disabled__38H4t:focus {
    outline: 0; }

.Tile_disabled__3gyWG {
  cursor: default; }
  .Tile_disabled__3gyWG:hover {
    box-shadow: none; }

.TextLineSeparator_Toastify__toast__xZXpa {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.TextLineSeparator_Toastify__toast--success__3yhLi {
  background: #27A49B; }

.TextLineSeparator_Toastify__toast--error__3KMEc {
  background: #F24D4B; }

.TextLineSeparator_container__2QQd_ {
  display: flex;
  align-items: center;
  padding: 0 1rem;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  width: 100%; }
  .TextLineSeparator_container__2QQd_:before, .TextLineSeparator_container__2QQd_:after {
    content: '';
    flex: 0 1 100%;
    border-bottom: 1px solid #C3C3C3;
    margin: 0 1rem;
    transform: translateY(1px / 2); }
  .TextLineSeparator_container__2QQd_:before {
    margin-left: 0; }
  .TextLineSeparator_container__2QQd_:after {
    margin-right: 0; }
  .TextLineSeparator_container__2QQd_ span {
    text-transform: uppercase;
    font-weight: 400;
    font-size: small; }

.WarningPage_Toastify__toast__2dzri {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.WarningPage_Toastify__toast--success__2T85U {
  background: #27A49B; }

.WarningPage_Toastify__toast--error__1bVSd {
  background: #F24D4B; }

.WarningPage_page__1mtI7 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box; }
  .WarningPage_page__1mtI7 button {
    margin-top: 50px; }

.WarningPage_message__3-f2C {
  position: absolute;
  left: 50px;
  top: 190px; }
  @media (max-width: 767px) {
    .WarningPage_message__3-f2C {
      display: none; } }
  .WarningPage_message__3-f2C:before {
    position: absolute;
    content: url(/static/media/arrow.50c7a100.svg);
    transform: scaleX(-1) rotate(-55deg);
    bottom: -70px; }

.Login_Toastify__toast__11lnr {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.Login_Toastify__toast--success__1Qrmo {
  background: #27A49B; }

.Login_Toastify__toast--error__Wj55i {
  background: #F24D4B; }

.Login_container__1EydC {
  height: 100%;
  width: 100%;
  max-width: 250px;
  margin: 0 auto;
  display: flex;
  flex-direction: column; }
  .Login_container__forgot__1-b6U {
    margin-left: auto;
    cursor: pointer;
    font-size: 12px;
    margin-top: 20px;
    color: #9AA9AB; }
    .Login_container__forgot__1-b6U:hover {
      text-decoration: underline; }
  .Login_container__error__BQA_8 {
    font-size: 12px;
    color: #F24D4B;
    text-align: center;
    margin-top: 20px; }
  .Login_container__bottom__3oFwd {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px; }
    .Login_container__bottom__3oFwd .Login_bottom__buttons__o5lcQ {
      display: flex;
      justify-content: space-between;
      width: 100%; }
    .Login_container__bottom__3oFwd .Login_bottom__or__19YuN {
      margin: 30px 0; }
    .Login_container__bottom__3oFwd .Login_bottom__signup__2KBFw {
      cursor: pointer;
      font-size: 12px;
      color: #666B73; }
      .Login_container__bottom__3oFwd .Login_bottom__signup__2KBFw:hover {
        text-decoration: underline; }

.Template_Toastify__toast__35ym8 {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.Template_Toastify__toast--success__2v9sP {
  background: #27A49B; }

.Template_Toastify__toast--error__1wTRI {
  background: #F24D4B; }

.Template_wrapper__pcwW1 {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box; }

.Template_container__1WWV7 {
  display: flex;
  flex-direction: column;
  background: white;
  border: 1px solid #F2F2F2;
  border-radius: 6px;
  width: 100%;
  max-width: 350px;
  padding: 30px 20px;
  box-sizing: border-box; }
  .Template_container__logo__1Qk25 {
    max-height: 100px;
    margin-bottom: 20px; }
  .Template_container__title__1RosO {
    text-align: center;
    font-size: 20px;
    margin: 20px 0;
    font-weight: 600; }
  .Template_container__description__354UO {
    text-align: center;
    margin-bottom: 20px;
    font-size: 14px; }

.Header_Toastify__toast__1Pu0L {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.Header_Toastify__toast--success__15pyP {
  background: #27A49B; }

.Header_Toastify__toast--error__3df6m {
  background: #F24D4B; }

.Header_wrapper__2rZaw {
  background: white;
  padding: 50px 0 30px 0;
  border-top: 1px solid #F2F2F2; }
  @media (max-width: 1190px) {
    .Header_wrapper__2rZaw {
      padding: 50px 20px 30px 20px; } }
  .Header_wrapper__2rZaw .Header_container__gGYGA {
    display: grid;
    grid-template-columns: 250px 1fr;
    grid-template-rows: auto 1fr;
    max-width: 920px;
    margin: 0 auto;
    grid-column-gap: 80px;
    grid-row-gap: 20px;
    grid-template-areas: "image text" ". price"; }
    @media (max-width: 1190px) {
      .Header_wrapper__2rZaw .Header_container__gGYGA {
        grid-gap: 20px;
        grid-template-columns: 1fr;
        grid-template-rows: 100px auto auto;
        grid-template-areas: "image" "text" "price"; } }
    .Header_wrapper__2rZaw .Header_container__image__200H1 {
      grid-area: image;
      display: flex;
      align-items: center;
      justify-content: center; }
      .Header_wrapper__2rZaw .Header_container__image__200H1 > svg {
        stroke: #27A49B;
        width: 100%; }
        @media (max-width: 1190px) {
          .Header_wrapper__2rZaw .Header_container__image__200H1 > svg {
            height: 100%; } }
    .Header_wrapper__2rZaw .Header_container__text-container__3oHQY {
      grid-area: text; }
      @media (max-width: 1190px) {
        .Header_wrapper__2rZaw .Header_container__text-container__3oHQY .Header_container__title__2XlQ2 {
          text-align: center; } }
      @media (max-width: 580px) {
        .Header_wrapper__2rZaw .Header_container__text-container__3oHQY .Header_container__title__2XlQ2 {
          font-size: 20px; } }
      .Header_wrapper__2rZaw .Header_container__text-container__3oHQY .Header_container__subtitle__3FYIn {
        font-weight: normal;
        font-size: 18px;
        color: #9AA9AB;
        margin-bottom: 20px; }
        @media (max-width: 1190px) {
          .Header_wrapper__2rZaw .Header_container__text-container__3oHQY .Header_container__subtitle__3FYIn {
            text-align: center; } }
      .Header_wrapper__2rZaw .Header_container__text-container__3oHQY .Header_container__desc__29GQq {
        text-align: justify;
        margin-bottom: 30px; }
        @media (max-width: 1190px) {
          .Header_wrapper__2rZaw .Header_container__text-container__3oHQY .Header_container__desc__29GQq {
            margin-bottom: 0; } }
        @media (max-width: 580px) {
          .Header_wrapper__2rZaw .Header_container__text-container__3oHQY .Header_container__desc__29GQq {
            font-size: 14px; } }
    .Header_wrapper__2rZaw .Header_container__price__3tWff {
      grid-area: price;
      display: flex;
      align-items: center;
      font-weight: 600;
      height: 40px;
      font-size: 40px; }
      @media (max-width: 1190px) {
        .Header_wrapper__2rZaw .Header_container__price__3tWff {
          display: none; } }
    .Header_wrapper__2rZaw .Header_container__price--complete__2F5jF {
      grid-area: price;
      height: 50px;
      display: flex;
      align-items: center;
      font-weight: 600;
      color: #EEB656;
      font-size: 16px; }
      @media (max-width: 1190px) {
        .Header_wrapper__2rZaw .Header_container__price--complete__2F5jF {
          text-align: center;
          justify-content: center; } }

.AddToCartButton_Toastify__toast__2cMPn {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.AddToCartButton_Toastify__toast--success__2BQJl {
  background: #27A49B; }

.AddToCartButton_Toastify__toast--error__bdULD {
  background: #F24D4B; }

.AddToCartButton_container__8z779 {
  font-size: 14px;
  font-weight: 300;
  cursor: pointer;
  font-style: italic; }
  .AddToCartButton_container__8z779:hover .AddToCartButton_added__3hLeM {
    display: none; }
  .AddToCartButton_container__8z779:hover .AddToCartButton_remove__3hgI5 {
    display: inline; }
  .AddToCartButton_container__8z779 .AddToCartButton_added__3hLeM {
    display: inline;
    color: #27A49B; }
    @media (max-width: 1190px) {
      .AddToCartButton_container__8z779 .AddToCartButton_added__3hLeM {
        display: none; } }
  .AddToCartButton_container__8z779 .AddToCartButton_remove__3hgI5 {
    font-style: normal;
    color: #9AA9AB;
    display: none; }
    @media (max-width: 1190px) {
      .AddToCartButton_container__8z779 .AddToCartButton_remove__3hgI5 {
        display: block; } }

.Price_Toastify__toast__3hcks {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.Price_Toastify__toast--success__3Jloq {
  background: #27A49B; }

.Price_Toastify__toast--error__2SmQF {
  background: #F24D4B; }

.Price_wrapper__3xtlm {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center; }
  @media (max-width: 1190px) {
    .Price_wrapper__3xtlm {
      justify-content: center; } }
  .Price_wrapper__title__1EUfR {
    font-size: 18px;
    font-weight: normal;
    color: #9AA9AB; }
    @media (max-width: 1190px) {
      .Price_wrapper__title__1EUfR {
        display: none; } }

.Price_container__1-Din {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .Price_container__1-Din button {
    margin-left: 20px; }
  .Price_container__price___bFsp {
    color: #F24D4B; }
  @media (max-width: 480px) {
    .Price_container__1-Din {
      flex-direction: column; }
      .Price_container__1-Din button {
        margin-left: 0; } }

.Kpis_Toastify__toast__2gfx7 {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.Kpis_Toastify__toast--success__Y-PN8 {
  background: #27A49B; }

.Kpis_Toastify__toast--error__3HHXW {
  background: #F24D4B; }

.Kpis_wrapper__2x0p8 {
  background: linear-gradient(to bottom, white 0%, white 20%, #FAFDFD 20%, #FAFDFD 100%); }
  @media (max-width: 1190px) {
    .Kpis_wrapper__2x0p8 {
      padding: 0 20px; } }

.Kpis_kpis__m7nmb {
  position: relative;
  padding: 20px 0;
  max-width: 920px;
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;
  flex-wrap: nowrap; }
  .Kpis_kpis__m7nmb::-webkit-scrollbar {
    display: none; }
  @media (max-width: 1190px) {
    .Kpis_kpis__m7nmb {
      overflow-x: scroll;
      -webkit-overflow-scrolling: touch;
      justify-content: flex-start; } }
  .Kpis_kpis__m7nmb > div {
    flex: 0 0 auto; }
    .Kpis_kpis__m7nmb > div:not(:first-child) {
      margin-left: 30px; }
  .Kpis_kpis__arrow__3Dfua {
    top: -75px;
    left: 20px;
    position: absolute;
    margin-left: 0 !important; }
    @media (max-width: 1190px) {
      .Kpis_kpis__arrow__3Dfua {
        display: none; } }

.List_Toastify__toast__3gHQ_ {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.List_Toastify__toast--success__2DTX_ {
  background: #27A49B; }

.List_Toastify__toast--error__24M2l {
  background: #F24D4B; }

.List_wrapper__2tqDp {
  background: white;
  padding: 50px 0; }
  @media (max-width: 1190px) {
    .List_wrapper__2tqDp {
      padding: 50px 20px; } }

.List_container__3P0jm {
  display: flex;
  flex-direction: column;
  max-width: 920px;
  margin: 0 auto; }
  .List_container__title__jbmUm {
    margin-bottom: 10px; }
  .List_container__list__8w4vM {
    display: flex;
    flex-direction: column; }
  .List_container__button__2bkLZ {
    margin: 50px auto 0 auto; }
    @media (max-width: 1190px) {
      .List_container__button__2bkLZ {
        display: none; } }

.List_item__25afH {
  width: 100%;
  border-bottom: 1px solid #F2F2F2;
  padding: 20px 0 15px 0;
  display: flex;
  align-items: center; }
  @media (max-width: 979px) {
    .List_item__25afH {
      flex-direction: column;
      align-items: flex-start; } }

.List_item__left__UoWUz {
  display: flex;
  flex-direction: column; }

.List_item__title__1vkQr {
  font-size: 20px; }

.List_item__subtitle__3DE6S {
  font-size: 15px;
  color: #9AA9AB; }

.List_item__25afH > button {
  transition: opacity 0.3s ease;
  opacity: 0;
  margin-left: auto; }
  @media (max-width: 480px) {
    .List_item__25afH > button {
      display: none; } }

.List_item__number__1_xhR {
  margin-left: auto;
  font-size: 14px;
  width: 150px;
  color: #666B73; }
  @media (max-width: 979px) {
    .List_item__number__1_xhR {
      margin: 10px 0 0 0; } }

.List_badge__1kgAO {
  display: flex;
  align-items: center; }
  .List_badge__1kgAO > *:not(:last-child) {
    margin-right: 10px; }
  .List_badge__circle__1sZeF {
    min-width: 10px;
    min-height: 10px;
    border-radius: 50%; }
  .List_badge--green__AiMuG {
    color: #27A49B; }
    .List_badge--green__AiMuG div {
      background: #27A49B; }
  .List_badge--orange__9Garw {
    color: #EEB656; }
    .List_badge--orange__9Garw div {
      background: #EEB656; }
  .List_badge--red__2_c79 {
    color: #F24D4B; }
    .List_badge--red__2_c79 div {
      background: #F24D4B; }

.MobileFooterPrice_Toastify__toast__1vsXH {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.MobileFooterPrice_Toastify__toast--success__1yKoK {
  background: #27A49B; }

.MobileFooterPrice_Toastify__toast--error__3aVT_ {
  background: #F24D4B; }

.MobileFooterPrice_container__3G2uY {
  background: white;
  height: auto;
  position: fixed;
  bottom: 0;
  justify-content: space-between;
  align-items: center;
  font-size: 30px;
  font-weight: 600;
  padding: 20px;
  box-sizing: border-box;
  box-shadow: 10px 0 10px 0 rgba(0, 0, 0, 0.09);
  display: none;
  width: calc(100% - 250px); }
  @media (max-width: 767px) {
    .MobileFooterPrice_container__3G2uY {
      width: 100%; } }
  @media (max-width: 1190px) {
    .MobileFooterPrice_container__3G2uY {
      display: flex; } }
  @media (max-width: 480px) {
    .MobileFooterPrice_container__3G2uY {
      height: auto;
      padding: 10px 20px; } }

@charset "UTF-8";
.Information_Toastify__toast__2GSKn {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.Information_Toastify__toast--success__up5zH {
  background: #27A49B; }

.Information_Toastify__toast--error__7_4u2 {
  background: #F24D4B; }

.Information_wrapper__36HBU {
  background: white;
  padding: 20px 0 50px 0; }
  @media (max-width: 1190px) {
    .Information_wrapper__36HBU {
      padding: 20px 20px 100px 20px; } }

.Information_container__1u9R0 {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 80px;
  max-width: 920px;
  margin: 0 auto; }
  @media (max-width: 979px) {
    .Information_container__1u9R0 {
      grid-gap: 50px;
      grid-template-columns: 1fr;
      grid-template-rows: 200px 1fr; } }
  @media (max-width: 979px) {
    .Information_container__image__27RJg {
      width: 100%;
      height: 100%; } }
  .Information_container__desc__SJyvj {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start; }
    .Information_container__desc__SJyvj .Information_desc__title__L5gm5 {
      margin-bottom: 20px; }
    .Information_container__desc__SJyvj .Information_desc__list__bdulR {
      padding-left: 1em;
      margin-left: 0;
      list-style: none; }
      .Information_container__desc__SJyvj .Information_desc__list__bdulR > li {
        text-indent: -1em; }
        .Information_container__desc__SJyvj .Information_desc__list__bdulR > li:not(:last-child) {
          margin-bottom: 5px; }
        .Information_container__desc__SJyvj .Information_desc__list__bdulR > li:before {
          content: "\2022";
          margin-right: 10px;
          color: #F24D4B; }
    .Information_container__desc__SJyvj .Information_desc__sentence__3o2N5 {
      margin-top: 10px;
      font-weight: 500;
      font-size: 18px;
      color: #27A49B;
      text-align: center;
      align-self: center; }

.Module_Toastify__toast__DqYli {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.Module_Toastify__toast--success__1jvjo {
  background: #27A49B; }

.Module_Toastify__toast--error__mQqsr {
  background: #F24D4B; }

.Module_container__2SG21 {
  display: flex;
  flex-direction: column; }
  .Module_container__breadcrumb__1PZHT {
    box-sizing: border-box;
    font-size: 12px;
    color: #9AA9AB;
    font-weight: 400;
    height: 60px;
    display: flex;
    align-items: center;
    max-width: 920px;
    margin: 0 auto;
    width: 100%; }
    @media (max-width: 1190px) {
      .Module_container__breadcrumb__1PZHT {
        padding: 0 20px; } }
    .Module_container__breadcrumb__1PZHT .Module_breadcrumb__back__3lhvi {
      cursor: pointer; }
      .Module_container__breadcrumb__1PZHT .Module_breadcrumb__back__3lhvi:after {
        content: "/";
        margin: 0 20px; }
    .Module_container__breadcrumb__1PZHT .Module_breadcrumb__title__1khT_ {
      text-decoration: underline; }

.WarningOldAudit_Toastify__toast__wJh8u {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.WarningOldAudit_Toastify__toast--success__lvtsk {
  background: #27A49B; }

.WarningOldAudit_Toastify__toast--error__E0wlB {
  background: #F24D4B; }

.WarningOldAudit_container__2TzVW {
  z-index: 2;
  text-align: center;
  background: white;
  color: #EEB656;
  font-size: 14px;
  padding: 5px; }

.Maintenance_Toastify__toast__1vMj6 {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.Maintenance_Toastify__toast--success__3nyyI {
  background: #27A49B; }

.Maintenance_Toastify__toast--error__3Ksib {
  background: #F24D4B; }

.Maintenance_container__2kjDv {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  box-sizing: border-box;
  padding: 20px;
  text-align: center; }
  .Maintenance_container__2kjDv > img {
    width: 300px;
    margin-bottom: 50px; }
  .Maintenance_container__arrow__pFTna {
    position: absolute;
    bottom: 170px;
    right: 90px;
    text-align: left; }
    @media (max-width: 979px) {
      .Maintenance_container__arrow__pFTna {
        display: none; } }
    .Maintenance_container__arrow__pFTna:before {
      position: absolute;
      content: url(/static/media/arrow.50c7a100.svg);
      transform: rotate(-20deg);
      bottom: -100px;
      right: 20px; }

.Optimizations_Toastify__toast__1cNBU {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.Optimizations_Toastify__toast--success__2TcuL {
  background: #27A49B; }

.Optimizations_Toastify__toast--error__33P7L {
  background: #F24D4B; }

.Optimizations_loading__3E67t {
  display: flex;
  flex-direction: column;
  align-items: center; }
  .Optimizations_loading__3E67t > u {
    cursor: pointer; }
  .Optimizations_loading__discover__XugdZ {
    position: absolute;
    left: 50px;
    top: 90px; }
    @media (max-width: 979px) {
      .Optimizations_loading__discover__XugdZ {
        display: none; } }
    .Optimizations_loading__discover__XugdZ:before {
      position: absolute;
      content: url(/static/media/arrow.50c7a100.svg);
      transform: scaleX(-1) rotate(-55deg);
      bottom: -70px; }
  .Optimizations_loading__button__1Noso {
    text-decoration: underline;
    cursor: pointer; }
    .Optimizations_loading__button--disabled__3heZ9 {
      opacity: 0.4;
      pointer-events: none; }

.Optimizations_failed__2ThoU {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  text-align: center; }
  .Optimizations_failed__arrow__1Dc2a {
    position: absolute;
    bottom: 170px;
    right: 90px;
    text-align: left; }
    @media (max-width: 480px) {
      .Optimizations_failed__arrow__1Dc2a {
        display: none; } }
    .Optimizations_failed__arrow__1Dc2a:before {
      position: absolute;
      content: url(/static/media/arrow.50c7a100.svg);
      transform: rotate(-20deg);
      bottom: -100px;
      right: 20px; }

.Results_Toastify__toast__1gf6C {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.Results_Toastify__toast--success__1APxH {
  background: #27A49B; }

.Results_Toastify__toast--error__Br9_S {
  background: #F24D4B; }

.Results_container__9f_fT {
  display: flex;
  flex-direction: column;
  width: 100%; }

.Results_downloadReport__G7WU- {
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #F2F2F2;
  padding: 20px; }
  .Results_downloadReport__button__1isT- {
    background: none;
    border: none;
    padding: 0;
    text-align: center;
    text-decoration: underline;
    font-size: 16px;
    display: flex;
    align-items: center;
    color: #666B73;
    cursor: pointer; }
    .Results_downloadReport__button__1isT-:focus {
      outline: 0; }
    .Results_downloadReport__button__1isT- > svg {
      width: 16px;
      fill: #666B73;
      margin-right: 10px; }
    .Results_downloadReport__button--loading__1V5xB {
      pointer-events: none;
      opacity: 0.4;
      cursor: default; }
    .Results_downloadReport__button--disabled__3bGdg {
      cursor: default; }

.OctoScore_Toastify__toast__1L_RA {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.OctoScore_Toastify__toast--success__1BJUQ {
  background: #27A49B; }

.OctoScore_Toastify__toast--error__dTrIl {
  background: #F24D4B; }

.OctoScore_wrapper__3xhnL {
  position: absolute;
  width: 800px;
  z-index: 3;
  bottom: -20px;
  right: 620px; }
  @media (max-width: 1150px) {
    .OctoScore_wrapper__3xhnL {
      right: 50px;
      bottom: -100px; } }
  .OctoScore_wrapper__score-container__3Cgy3 {
    position: absolute;
    right: 133px;
    bottom: 140px;
    text-align: center; }
    .OctoScore_wrapper__score-container__3Cgy3 .OctoScore_wrapper__tooltip__3tt-X {
      position: absolute;
      top: -5px;
      right: -26px;
      display: flex;
      align-items: center; }
      .OctoScore_wrapper__score-container__3Cgy3 .OctoScore_wrapper__tooltip__3tt-X > svg {
        stroke: #9AA9AB;
        width: 14px; }
    .OctoScore_wrapper__score-container__3Cgy3 .OctoScore_wrapper__score__2R3DU {
      font-size: 80px;
      color: #43464C;
      font-weight: 900;
      line-height: 1; }
    .OctoScore_wrapper__score-container__3Cgy3 .OctoScore_wrapper__range__268Y2 {
      font-size: 15px;
      font-weight: 400;
      color: #9AA9AB; }

.Header_Toastify__toast__2CTHJ {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.Header_Toastify__toast--success__1ZaeC {
  background: #27A49B; }

.Header_Toastify__toast--error__2VPYg {
  background: #F24D4B; }

.Header_wrapper__2NR0X {
  width: 100%;
  background: linear-gradient(90deg, #AFDFE6 50%, #FFFFFF 50%); }
  @media (max-width: 1150px) {
    .Header_wrapper__2NR0X {
      background: linear-gradient(-180deg, #AFDFE6 0%, #9ECEDA 100%); } }

.Header_container__1fHUt {
  position: relative;
  max-width: 920px;
  margin: 0 auto;
  height: 350px;
  display: grid;
  grid-template-columns: 300px 1fr; }
  @media (max-width: 1150px) {
    .Header_container__1fHUt {
      height: 200px;
      overflow: hidden; } }
  .Header_container__score__5CU2W {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center; }
    @media (max-width: 1150px) {
      .Header_container__score__5CU2W {
        grid-column: 1/3; } }
    .Header_container__score__5CU2W:before {
      content: "";
      position: absolute;
      border-radius: 50%;
      width: 100%;
      height: 1000px;
      background: white;
      right: -240px; }
      @media (max-width: 1150px) {
        .Header_container__score__5CU2W:before {
          display: none; } }
  .Header_container__text__1k1rQ {
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
    padding-left: 75px;
    border-radius: 50%;
    z-index: 2; }
    .Header_container__text__1k1rQ > p {
      margin: 20px 0 20px 0;
      font-size: 18px;
      text-align: justify; }
      .Header_container__text__1k1rQ > p span {
        font-weight: 500;
        color: #EEB656; }
    @media (max-width: 1150px) {
      .Header_container__text__1k1rQ {
        display: none; } }
    .Header_container__text__1k1rQ .Header_text__audit__16B0j {
      display: flex;
      align-items: center;
      font-size: 14px;
      position: absolute;
      bottom: 10px;
      color: #9AA9AB; }
      .Header_container__text__1k1rQ .Header_text__audit__16B0j > a {
        margin: 0 4px; }
  .Header_container__website__ljRC9 {
    text-decoration: underline;
    cursor: pointer; }

.Header_downloadReport__1T9b_ {
  text-decoration: underline;
  cursor: pointer; }
  .Header_downloadReport--loading__p4GMR {
    pointer-events: none;
    opacity: 0.4;
    cursor: default; }
  .Header_downloadReport--disabled__1eqwv {
    cursor: default; }

.Toastify__toast {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.Toastify__toast--success {
  background: #27A49B; }

.Toastify__toast--error {
  background: #F24D4B; }

.select-audit-container {
  z-index: 2;
  display: block !important;
  margin-left: 5px; }
  .select-audit-container .select-audit__control {
    padding: 0px 10px; }
    .select-audit-container .select-audit__control--is-focused, .select-audit-container .select-audit__control--menu-is-open {
      box-shadow: none !important;
      border-color: #cccccc !important; }
  .select-audit-container .select-audit__value-container {
    padding: 0 !important;
    width: 90px; }
  .select-audit-container .select-audit__placeholder {
    font-size: 14px;
    color: #9AA9AB; }
  .select-audit-container .select-audit__single-value {
    font-size: 14px;
    color: #666B73;
    font-weight: 400; }
  .select-audit-container .select-audit__menu {
    width: 200px; }
  .select-audit-container .select-audit__option {
    color: #666B73; }
    .select-audit-container .select-audit__option--is-selected {
      background: #27A49B !important;
      color: white !important;
      text-decoration: none !important; }
    .select-audit-container .select-audit__option--is-focused {
      background: rgba(39, 164, 155, 0.2); }
    .select-audit-container .select-audit__option--is-disabled {
      color: #C3C3C3;
      text-decoration: line-through; }
    .select-audit-container .select-audit__option:active {
      background: rgba(39, 164, 155, 0.4); }
  .select-audit-container .select-audit__indicator-separator {
    display: none; }
  .select-audit-container .select-audit__indicator {
    padding: 0; }
    .select-audit-container .select-audit__indicator > svg {
      fill: #666B73;
      width: 15px; }

.SelectAudit_Toastify__toast__5avpZ {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.SelectAudit_Toastify__toast--success__1Da4n {
  background: #27A49B; }

.SelectAudit_Toastify__toast--error__1u1pT {
  background: #F24D4B; }

.SelectAudit_button__3GYZa {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  font-weight: 500;
  color: #27A49B;
  cursor: pointer; }
  .SelectAudit_button--disabled__2qWEe {
    pointer-events: none;
    opacity: 0.6; }
  .SelectAudit_button__3GYZa > div {
    display: flex;
    align-items: center; }
    .SelectAudit_button__3GYZa > div > svg {
      fill: #27A49B;
      width: 12px;
      margin-right: 6px; }

.SelectAudit_waitNextAudit__3OP03 {
  display: flex;
  flex-direction: column;
  padding: 10px;
  text-align: center;
  border-bottom: 1px solid #F2F2F2; }
  .SelectAudit_waitNextAudit__3OP03 > span {
    font-weight: 400;
    font-size: 12px; }
  .SelectAudit_waitNextAudit__3OP03 > a {
    margin-top: 10px;
    font-weight: 500;
    text-decoration: underline;
    color: #EEB656; }

.SelectAudit_inProgress__tD2VV {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #F2F2F2; }

.Kpis_Toastify__toast__2PGWt {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.Kpis_Toastify__toast--success__nQPqJ {
  background: #27A49B; }

.Kpis_Toastify__toast--error__2pH-z {
  background: #F24D4B; }

.Kpis_wrapper__fkXcj {
  background: #FAFDFD;
  z-index: 1; }
  @media (max-width: 1190px) {
    .Kpis_wrapper__fkXcj {
      padding: 0 20px; } }

.Kpis_kpis__7HEmi {
  padding: 20px 0;
  max-width: 920px;
  margin: 0 auto;
  z-index: 2;
  display: flex;
  justify-content: flex-end;
  flex-wrap: nowrap;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  top: -20px;
  right: 0; }
  .Kpis_kpis__7HEmi::-webkit-scrollbar {
    display: none; }
  @media (max-width: 1190px) {
    .Kpis_kpis__7HEmi {
      justify-content: flex-start; } }
  .Kpis_kpis__7HEmi > div {
    flex: 0 0 auto; }
    .Kpis_kpis__7HEmi > div:not(:first-child) {
      margin-left: 30px; }

.Level_Toastify__toast__3hxAj {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.Level_Toastify__toast--success__1wfWg {
  background: #27A49B; }

.Level_Toastify__toast--error__3s7qb {
  background: #F24D4B; }

.Level_wrapper__2m--5 {
  background: white;
  border-top: 1px solid #F2F2F2;
  padding: 45px 0 20px 0;
  z-index: 1; }
  .Level_wrapper__2m--5:nth-child(even) {
    background: #FDFDFD; }

.Level_container__2awgo {
  max-width: 920px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  position: relative; }
  @media (max-width: 1190px) {
    .Level_container__2awgo {
      padding: 0 20px; } }
  .Level_container__arrow__2sXcl {
    position: absolute;
    top: -150px;
    left: -40px; }
    @media (max-width: 1300px) {
      .Level_container__arrow__2sXcl {
        display: none; } }
  .Level_container__header__31V8Y {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    @media (max-width: 979px) {
      .Level_container__header__31V8Y:nth-child(1) {
        flex-direction: column-reverse;
        align-items: flex-start; } }
    .Level_container__header__31V8Y .Level_header__buttonWrapper__3CP2d {
      position: relative;
      display: flex;
      justify-content: center; }
      @media (max-width: 979px) {
        .Level_container__header__31V8Y .Level_header__buttonWrapper__3CP2d {
          margin-bottom: 20px;
          width: 100%; } }
      .Level_container__header__31V8Y .Level_header__buttonWrapper__3CP2d:hover .Level_header__price__2vxDv {
        opacity: 1;
        transform: translate(0, -10px); }
      .Level_container__header__31V8Y .Level_header__buttonWrapper__3CP2d .Level_header__price__2vxDv {
        position: absolute;
        font-size: 14px;
        color: #27A49B;
        opacity: 0;
        top: -20px;
        transition: opacity 0.1s ease-in-out, transform .1s ease-in-out; }
  .Level_container__list__3cEKt {
    display: flex;
    justify-content: flex-start;
    flex-wrap: nowrap;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    padding: 25px 0; }
    .Level_container__list__3cEKt::-webkit-scrollbar {
      display: none; }
    .Level_container__list__3cEKt > div {
      z-index: 1;
      flex: 0 0 auto; }
      .Level_container__list__3cEKt > div:not(:first-child) {
        margin-left: 20px; }

.Level_title__2shvk {
  font-size: 25px;
  display: flex; }
  @media (max-width: 580px) {
    .Level_title__2shvk {
      font-size: 19px; } }
  .Level_title__text__1WrPf {
    font-weight: 500; }

.Impersonate_Toastify__toast__2g_UU {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.Impersonate_Toastify__toast--success__3Ji11 {
  background: #27A49B; }

.Impersonate_Toastify__toast--error__2RjKn {
  background: #F24D4B; }

.Impersonate_container__1FuP- {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
  text-align: center; }
  .Impersonate_container__1FuP- img {
    width: 150px;
    margin-bottom: 50px; }
  .Impersonate_container__1FuP- form {
    min-width: 300px;
    max-width: 400px;
    height: auto; }
    .Impersonate_container__1FuP- form label {
      width: 100%; }
  .Impersonate_container__1FuP- button {
    margin: 50px 0 10px; }
  .Impersonate_container__1FuP- a {
    font-size: small; }
    .Impersonate_container__1FuP- a:hover {
      text-decoration: underline; }

.SeaSelection_Toastify__toast__2r5nf {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.SeaSelection_Toastify__toast--success__5TYqe {
  background: #27A49B; }

.SeaSelection_Toastify__toast--error__1SF8_ {
  background: #F24D4B; }

.SeaSelection_container__39-bC {
  max-width: 700px;
  margin: 0 auto;
  padding: 20px; }

.SeaSelection_steps__1VupA {
  margin-top: 50px;
  width: 100%;
  display: flex;
  flex-direction: column; }
  .SeaSelection_steps__1VupA .SeaSelection_step__1QW2A {
    width: 100%;
    display: flex;
    flex-direction: column; }
    .SeaSelection_steps__1VupA .SeaSelection_step__1QW2A:not(:first-child) {
      margin-top: 30px; }
    .SeaSelection_steps__1VupA .SeaSelection_step__title__26xyI {
      position: relative;
      display: flex;
      align-items: center; }
      .SeaSelection_steps__1VupA .SeaSelection_step__title__26xyI:before {
        content: attr(data-step);
        color: #F24D4B;
        font-weight: 300;
        margin-right: 45px; }
      .SeaSelection_steps__1VupA .SeaSelection_step__title__26xyI:after {
        content: "";
        width: 1px;
        height: 60%;
        background: #C3C3C3;
        position: absolute;
        left: 30px; }
    .SeaSelection_steps__1VupA .SeaSelection_step__description__1niAB {
      margin: 10px 0 20px 0;
      text-align: justify; }
    .SeaSelection_steps__1VupA .SeaSelection_step__budget__Ew51k {
      display: flex;
      flex-direction: column;
      align-items: center;
      background: white;
      border-radius: 6px;
      border: 2px solid #F2F2F2;
      padding: 20px;
      box-sizing: border-box;
      margin-top: 10px; }
      .SeaSelection_steps__1VupA .SeaSelection_step__budget__Ew51k .SeaSelection_budget__slider__KkR5T {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        width: 250px; }
        @media (max-width: 580px) {
          .SeaSelection_steps__1VupA .SeaSelection_step__budget__Ew51k .SeaSelection_budget__slider__KkR5T {
            width: 100%; } }
        .SeaSelection_steps__1VupA .SeaSelection_step__budget__Ew51k .SeaSelection_budget__slider__KkR5T label {
          font-size: small;
          margin-bottom: 10px;
          text-align: center; }
      .SeaSelection_steps__1VupA .SeaSelection_step__budget__Ew51k .SeaSelection_budget__result__1S7DJ {
        text-align: center;
        font-size: 1.2em; }
      .SeaSelection_steps__1VupA .SeaSelection_step__budget__Ew51k .SeaSelection_budget__number__3n90F {
        color: #27A49B;
        font-size: 1.2em;
        cursor: help;
        background-image: linear-gradient(to right, #27A49B 33%, rgba(255, 255, 255, 0) 0%);
        background-position: bottom;
        background-size: 4px 1px;
        background-repeat: repeat-x; }
      .SeaSelection_steps__1VupA .SeaSelection_step__budget__Ew51k .SeaSelection_budget__star__3iV1n {
        font-size: small; }

.SeaSelection_cta__3uNyR {
  background: white;
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  padding: 10px 20px;
  box-sizing: border-box;
  box-shadow: 10px 0 10px 0 rgba(0, 0, 0, 0.09);
  width: 100%; }
  .SeaSelection_cta__wrapper__2n2FT {
    display: flex;
    max-width: 920px;
    margin: 0 auto;
    flex-direction: column;
    align-items: center; }

.SeaSelection_annotation__35Srl {
  font-size: small;
  font-style: italic;
  margin-left: auto;
  margin-top: 5px; }

.SeaSelection_formulas__3emwJ {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin-right: -50px; }
  .SeaSelection_formulas__3emwJ > div {
    flex: 1 0 300px;
    margin-right: 50px;
    margin-top: 20px; }
  .SeaSelection_formulas__item__3Q4Ad {
    display: flex;
    justify-content: space-between; }
    .SeaSelection_formulas__item__3Q4Ad > p:first-child {
      font-weight: 500; }
    .SeaSelection_formulas__item__3Q4Ad > p:not(:last-child) {
      margin-bottom: 10px; }

.SeaSelection_question__1DmwK {
  margin-top: 5px; }

.SeaSelection_contact___NDum {
  margin-top: 20px;
  position: relative;
  background: white;
  border: 2px solid #F2F2F2;
  border-radius: 6px;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: #78909C; }
  .SeaSelection_contact___NDum p:not(:last-child) {
    margin-bottom: 10px; }
  .SeaSelection_contact___NDum h3 {
    color: #27A49B;
    margin-bottom: 20px; }
  .SeaSelection_contact___NDum button {
    margin-top: 20px; }

@media (max-width: 580px) {
  .SeaSelection_formulas__3emwJ {
    flex-direction: column; }
    .SeaSelection_formulas__3emwJ > div {
      flex: 1 1; }
    .SeaSelection_formulas__item__3Q4Ad {
      flex-direction: column; }
      .SeaSelection_formulas__item__3Q4Ad:not(:first-child) {
        margin-top: 10px; }
      .SeaSelection_formulas__item__3Q4Ad p {
        margin-bottom: 0 !important; } }

.RangeSlider_Toastify__toast__1T_tJ {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.RangeSlider_Toastify__toast--success__3LCqV {
  background: #27A49B; }

.RangeSlider_Toastify__toast--error__34dcQ {
  background: #F24D4B; }

.RangeSlider_slider__1wMSo {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  height: 3px;
  border-radius: 5px;
  outline: none;
  background: #C3C3C3 !important; }
  .RangeSlider_slider__1wMSo::-webkit-slider-thumb {
    box-sizing: border-box;
    -webkit-appearance: none;
            appearance: none;
    width: 17px;
    height: 17px;
    border-radius: 50%;
    background: white !important;
    border: 2px solid #F24D4B;
    cursor: pointer; }
  .RangeSlider_slider__1wMSo::-moz-range-thumb {
    box-sizing: border-box;
    -moz-appearance: none;
         appearance: none;
    width: 17px;
    height: 17px;
    border-radius: 50%;
    background: white !important;
    border: 2px solid #F24D4B;
    cursor: pointer; }
  .RangeSlider_slider__1wMSo::-moz-focus-outer {
    border: 0; }

.Box_Toastify__toast__1QSXe {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.Box_Toastify__toast--success__1KVqh {
  background: #27A49B; }

.Box_Toastify__toast--error__lRMn6 {
  background: #F24D4B; }

.Box_container--selected__BVWJN, .Box_container__3IxN2 {
  position: relative;
  background: white;
  border: 2px solid #F2F2F2;
  border-radius: 6px;
  padding: 20px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  flex-direction: column; }
  .Box_container--selected__BVWJN:hover *, .Box_container__3IxN2:hover * {
    color: #27A49B; }

.Box_container--selected__BVWJN {
  border: 2px solid #27A49B; }
  .Box_container--selected__BVWJN * {
    color: #27A49B !important; }

.Box_container__subtitle__2gn-k {
  font-size: small;
  color: #9AA9AB; }

.Box_container__content__37F4i {
  margin-top: 30px; }
  .Box_container__content__37F4i * {
    color: #666B73 !important; }

.Box_selected__14OMk {
  position: absolute;
  right: 20px;
  top: 25px;
  display: flex;
  align-items: center; }
  .Box_selected__14OMk p {
    padding-left: 10px; }
  .Box_selected__check__1vKJb {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: #27A49B;
    width: 25px;
    height: 25px; }
    .Box_selected__check__1vKJb svg {
      stroke: white;
      fill: white;
      width: 50%; }

@media (max-width: 979px) {
  .Box_selected__14OMk p {
    display: none; } }

@media (max-width: 580px) {
  .Box_selected__check__1vKJb {
    display: none; } }

.SeaDashboard_Toastify__toast__1QpI5 {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.SeaDashboard_Toastify__toast--success__1qbCC {
  background: #27A49B; }

.SeaDashboard_Toastify__toast--error__1sQop {
  background: #F24D4B; }

.SeaDashboard_main-container__3hCL4, .SeaDashboard_container-chart__2tSjI, .SeaDashboard_container-table__wrapper__1UTnR {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column; }

.SeaDashboard_main-container__3hCL4 {
  align-items: center; }

.SeaDashboard_container-table__3GTtI {
  background: white;
  border-top: 1px solid #F2F2F2; }

.SeaDashboard_dataStudio__1Yb31 {
  max-width: 1200px;
  width: 100%;
  border: 2px solid #F2F2F2;
  border-radius: 6px;
  overflow: hidden; }
  .SeaDashboard_dataStudio__1Yb31 .SeaDashboard_dataStudio__inner__2z0Rr {
    width: 100%;
    position: relative;
    overflow: hidden;
    padding-top: 80%;
    height: 0; }
    .SeaDashboard_dataStudio__1Yb31 .SeaDashboard_dataStudio__inner__2z0Rr iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }

.Header_Toastify__toast__11Tiy {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.Header_Toastify__toast--success__2vEnA {
  background: #27A49B; }

.Header_Toastify__toast--error__ESsHa {
  background: #F24D4B; }

.Header_container__3YQfn {
  width: 100%;
  border: 2px solid #F2F2F2;
  background: white;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px; }

.Header_left__1gBux {
  display: flex;
  flex-direction: column;
  padding: 20px; }
  .Header_left__1gBux span {
    color: #78909C;
    font-size: 14px;
    margin-top: 5px; }

.Header_budget__MEocf {
  padding: 20px 30px;
  border-left: 1px solid #F2F2F2;
  display: flex;
  align-items: center;
  cursor: pointer; }
  .Header_budget__MEocf:hover {
    background: rgba(120, 144, 156, 0.05); }
    .Header_budget__MEocf:hover .Header_budget__edit__1RIw7 {
      border: 1px solid #9AA9AB; }
      .Header_budget__MEocf:hover .Header_budget__edit__1RIw7 svg {
        stroke: #666B73; }
  .Header_budget__num__sGvIC {
    display: flex;
    flex-direction: column;
    margin-right: 50px;
    font-weight: 500; }
    .Header_budget__num__sGvIC span:first-child {
      font-size: 12px;
      color: #78909C; }
    .Header_budget__num__sGvIC span:last-child {
      width: -webkit-max-content;
      width: -moz-max-content;
      width: max-content;
      color: #27A49B; }
  .Header_budget__edit__1RIw7 {
    min-height: 40px;
    min-width: 40px;
    border-radius: 50%;
    border: 1px solid #C3C3C3;
    display: flex;
    justify-content: center;
    align-items: center; }
    .Header_budget__edit__1RIw7:hover {
      background: rgba(195, 195, 195, 0.1); }
    .Header_budget__edit__1RIw7 svg {
      stroke: #9AA9AB;
      width: 14px;
      height: 14px; }

@media (max-width: 580px) {
  .Header_container__3YQfn {
    flex-direction: column; }
  .Header_budget__MEocf {
    border-left: none;
    border-top: 1px solid #F2F2F2;
    justify-content: space-between; } }

.Settings_Toastify__toast__1wgjK {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.Settings_Toastify__toast--success__1Garw {
  background: #27A49B; }

.Settings_Toastify__toast--error__G9d9w {
  background: #F24D4B; }

.Settings_container__328mP {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  max-width: 700px;
  padding: 50px 20px; }
  .Settings_container__328mP > p {
    margin-top: 10px;
    color: #78909C; }
  .Settings_container__328mP > button {
    margin-top: 20px;
    align-self: center; }

.Settings_budget__3IgRk {
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
  max-width: 350px;
  width: 100%;
  margin-top: 20px; }
  .Settings_budget__slider__18N_L {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 20px; }
    .Settings_budget__slider__18N_L p {
      font-size: 12px;
      margin-bottom: 10px;
      color: #9AA9AB; }
    .Settings_budget__slider__18N_L input {
      width: 100%; }
  .Settings_budget__result__1jrYl {
    display: flex;
    flex-direction: column;
    border: 1px solid #F2F2F2;
    border-radius: 3px;
    padding: 8px 30px; }
    .Settings_budget__result__1jrYl > p {
      font-size: 12px;
      color: #78909C;
      font-weight: 500; }
    .Settings_budget__result__1jrYl > div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: -webkit-max-content;
      width: -moz-max-content;
      width: max-content; }
      .Settings_budget__result__1jrYl > div p:first-child {
        color: #9AA9AB; }
      .Settings_budget__result__1jrYl > div svg {
        width: 15px;
        height: 15px;
        stroke: #666B73;
        margin: 0 10px; }
      .Settings_budget__result__1jrYl > div p:last-child {
        font-weight: 500;
        color: #27A49B; }

.Settings_question__dBMrY {
  margin-top: 10px;
  align-self: center; }

@media (max-width: 480px) {
  .Settings_budget__3IgRk {
    max-width: 100%; }
    .Settings_budget__result__1jrYl {
      padding: 8px 20px; } }

@media (max-width: 1190px) {
  .Settings_container__328mP {
    padding: 20px; } }

.Signup_Toastify__toast__9Sd-i {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.Signup_Toastify__toast--success__2elsA {
  background: #27A49B; }

.Signup_Toastify__toast--error__2UCsR {
  background: #F24D4B; }

.Signup_container__1P4_m {
  width: 100%;
  padding-bottom: 20px; }
  @media (max-width: 767px) {
    .Signup_container__1P4_m > div:first-child {
      display: none; } }
  .Signup_container__logo__2mAfK {
    position: absolute;
    top: 260px;
    left: 20px; }
    @media (max-width: 1190px) {
      .Signup_container__logo__2mAfK {
        width: 100%;
        display: flex;
        justify-content: center;
        top: 20px;
        left: 0; } }
    @media (max-width: 767px) {
      .Signup_container__logo__2mAfK {
        display: none; } }
    .Signup_container__logo__2mAfK img {
      width: 120px; }

.Signup_free__1rc5d {
  margin: 20px auto 50px auto;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  padding: 2px 10px;
  box-sizing: border-box;
  border-radius: 6px;
  background: #28a59c1f;
  color: #27A49B;
  font-size: 12px; }

.Signup_loading__ayDUM {
  height: 100vh; }

.Url_Toastify__toast__2DRIO {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.Url_Toastify__toast--success__12uV- {
  background: #27A49B; }

.Url_Toastify__toast--error__GkyB5 {
  background: #F24D4B; }

.Url_container__1zXy- {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px 20px 20px;
  box-sizing: border-box;
  max-width: 920px;
  margin: 0 auto;
  text-align: center; }
  .Url_container__description__3264B {
    margin-top: 20px;
    width: 75%; }
    @media (max-width: 767px) {
      .Url_container__description__3264B {
        width: 100%; } }

.Url_form__3ewD5 {
  margin-top: 50px;
  width: 50%;
  display: flex;
  flex-direction: column; }
  @media (max-width: 767px) {
    .Url_form__3ewD5 {
      width: 100%; } }
  .Url_form__3ewD5 label {
    font-size: 14px; }
  .Url_form__buttons__1NJOF {
    margin-top: 50px;
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: center; }
  .Url_form__link__2quGM {
    margin-top: 10px;
    cursor: pointer;
    font-size: 12px;
    color: #666B73; }
    .Url_form__link__2quGM:hover {
      text-decoration: underline; }

.Form_Toastify__toast__1k4jX {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.Form_Toastify__toast--success__25zTT {
  background: #27A49B; }

.Form_Toastify__toast--error__1J4K0 {
  background: #F24D4B; }

.Form_container__1vmus {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px 20px 20px;
  box-sizing: border-box;
  max-width: 920px;
  margin: 0 auto; }

.Form_form__1Pwly {
  display: flex;
  flex-direction: column;
  width: 50%; }
  .Form_form__1Pwly > label:not(:last-child), .Form_form__1Pwly > span:not(:last-child) {
    margin-bottom: 10px; }
  @media (max-width: 767px) {
    .Form_form__1Pwly {
      width: 100%; } }
  .Form_form__url__3Pp11 {
    position: relative; }
    .Form_form__url__3Pp11 .Form_url__change__ibOWe {
      position: absolute;
      right: 0;
      bottom: 10px;
      cursor: pointer;
      font-size: 12px;
      color: #666B73; }
      .Form_form__url__3Pp11 .Form_url__change__ibOWe:hover {
        text-decoration: underline; }
  .Form_form__buttons__Tag8e {
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center; }
    .Form_form__buttons__Tag8e .Form_buttons__login__3Jv6t {
      margin-top: 10px;
      cursor: pointer;
      font-size: 12px;
      color: #666B73; }
      .Form_form__buttons__Tag8e .Form_buttons__login__3Jv6t:hover {
        text-decoration: underline; }
    .Form_form__buttons__Tag8e .Form_buttons__or__33zE3 {
      margin: 30px 0; }
      .Form_form__buttons__Tag8e .Form_buttons__or__33zE3 span {
        background: #FAFDFD; }

.Form_success__13mcK {
  padding: 0 20px 20px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  max-width: 920px;
  margin: 0 auto; }
  .Form_success__13mcK p {
    margin-bottom: 10px; }
  .Form_success__13mcK h3 {
    color: #27A49B;
    font-weight: 500;
    margin-bottom: 50px;
    text-align: center; }
  .Form_success__13mcK button {
    margin-top: 20px; }
  .Form_success__container__1apsl {
    display: flex;
    width: 60%; }
    @media (max-width: 767px) {
      .Form_success__container__1apsl {
        width: 100%; } }
    .Form_success__container__1apsl .Form_container__module__2YH4j {
      padding-right: 20px; }
      @media (max-width: 767px) {
        .Form_success__container__1apsl .Form_container__module__2YH4j {
          display: none; } }
    .Form_success__container__1apsl .Form_container__description__25JiS {
      padding-left: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center; }
      @media (max-width: 767px) {
        .Form_success__container__1apsl .Form_container__description__25JiS {
          text-align: center;
          padding: 0;
          margin: 0 auto; }
          .Form_success__container__1apsl .Form_container__description__25JiS button {
            align-self: center; } }

.Form_error__359ZV {
  color: white;
  text-align: center;
  font-size: 14px;
  margin-top: 20px;
  background: #F24D4B;
  padding: 10px; }

.Verify_Toastify__toast__24tHF {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.Verify_Toastify__toast--success__R89jK {
  background: #27A49B; }

.Verify_Toastify__toast--error__kJXfe {
  background: #F24D4B; }

.Verify_container__3JebZ {
  height: 100%;
  width: 100%;
  max-width: 250px;
  margin: 20px auto 0 auto;
  display: flex;
  flex-direction: column;
  text-align: center; }
  .Verify_container__button__2CSpE {
    margin: 40px auto 0 auto; }

.ForgetPassword_Toastify__toast__2I7c5 {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.ForgetPassword_Toastify__toast--success__9Cb3m {
  background: #27A49B; }

.ForgetPassword_Toastify__toast--error__Ct68u {
  background: #F24D4B; }

.ForgetPassword_container__2XHw_ {
  height: 100%;
  width: 100%;
  max-width: 250px;
  margin: 0 auto;
  display: flex;
  flex-direction: column; }
  .ForgetPassword_container__bottom__3GwhA {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px; }
    .ForgetPassword_container__bottom__3GwhA .ForgetPassword_container__signup__1xUKa {
      cursor: pointer;
      font-size: 12px;
      color: #666B73; }
      .ForgetPassword_container__bottom__3GwhA .ForgetPassword_container__signup__1xUKa:hover {
        text-decoration: underline; }

.ForgetPassword_error__2odmv {
  font-size: 12px;
  color: #F24D4B;
  text-align: center;
  margin-top: 20px; }

.ForgetPassword_success__2bnnJ {
  text-align: center;
  color: #27A49B;
  display: flex;
  flex-direction: column;
  align-items: center; }
  .ForgetPassword_success__2bnnJ > *:not(:last-child) {
    margin-bottom: 20px; }
  .ForgetPassword_success__2bnnJ > p:first-child {
    font-weight: 500; }

.ForgetPassword_spam__3SV2l {
  align-items: center;
  text-align: center;
  margin-top: 20px;
  font-size: 14px;
  color: #666B73; }

.ResetPassword_Toastify__toast__3Chlx {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.ResetPassword_Toastify__toast--success__3VjZn {
  background: #27A49B; }

.ResetPassword_Toastify__toast--error__29it3 {
  background: #F24D4B; }

.ResetPassword_container__2LkaH {
  height: 100%;
  width: 100%;
  max-width: 250px;
  margin: 0 auto;
  display: flex;
  flex-direction: column; }
  .ResetPassword_container__error__1_Net {
    font-size: 12px;
    color: #F24D4B;
    text-align: center;
    margin-top: 20px; }
  .ResetPassword_container__bottom__NPxgb {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px; }
    .ResetPassword_container__bottom__NPxgb .ResetPassword_container__signup__1zU5g {
      cursor: pointer;
      font-size: 12px;
      color: #666B73; }
      .ResetPassword_container__bottom__NPxgb .ResetPassword_container__signup__1zU5g:hover {
        text-decoration: underline; }

.ResetPassword_success__SdhI4 {
  text-align: center;
  color: #27A49B;
  display: flex;
  flex-direction: column;
  align-items: center; }
  .ResetPassword_success__SdhI4 > *:not(:last-child) {
    margin-bottom: 20px; }
  .ResetPassword_success__SdhI4 > p:first-child {
    font-weight: 500; }

.SettingsPages_Toastify__toast__3K7eQ {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.SettingsPages_Toastify__toast--success__2rd2o {
  background: #27A49B; }

.SettingsPages_Toastify__toast--error__3d5iN {
  background: #F24D4B; }

.SettingsPages_wrapper__1IJ3g {
  display: flex;
  flex-direction: column;
  padding-bottom: 50px; }

.Tabs_Toastify__toast__2dFx5 {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.Tabs_Toastify__toast--success__hTWS2 {
  background: #27A49B; }

.Tabs_Toastify__toast--error__20HFP {
  background: #F24D4B; }

.Tabs_tabs__items__2sS0D {
  max-width: 920px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  padding: 20px; }
  .Tabs_tabs__items__2sS0D > a:not(:last-child) {
    margin-right: 50px; }

.Tabs_tabs__item__ZtHPs {
  height: 35px;
  font-weight: 500; }
  .Tabs_tabs__item--selected__2O0iw {
    color: #F24D4B;
    height: 33px;
    border-bottom: 2px solid #F24D4B; }

.Profile_Toastify__toast__1zgiy {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.Profile_Toastify__toast--success__HHjPT {
  background: #27A49B; }

.Profile_Toastify__toast--error__1hnyj {
  background: #F24D4B; }

.Profile_form__input-container__32sOd {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 -25px; }
  .Profile_form__input-container__32sOd > label {
    margin: 20px 25px;
    flex: 1 1 250px; }

.Profile_form__button__1cdPa {
  margin-top: 20px;
  margin-left: auto; }

.Security_Toastify__toast__3Y3qF {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.Security_Toastify__toast--success__1u7fg {
  background: #27A49B; }

.Security_Toastify__toast--error__2wXf- {
  background: #F24D4B; }

.Security_form__3TKCy {
  width: 100%; }
  .Security_form__input-container__eJIxc {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 -25px; }
    .Security_form__input-container__eJIxc > label {
      margin: 20px 25px;
      flex: 1 0 200px; }
  .Security_form__button__1HSic {
    margin-top: 20px;
    margin-left: auto; }

.ProfileTab_Toastify__toast__2qhp- {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.ProfileTab_Toastify__toast--success__1DuUO {
  background: #27A49B; }

.ProfileTab_Toastify__toast--error__24K4P {
  background: #F24D4B; }

.ProfileTab_wrapper__3X0Tg {
  display: flex;
  flex-direction: column; }

.ProfileTab_container__CmADP {
  padding: 0 20px; }

.Box_Toastify__toast__3IsU1 {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.Box_Toastify__toast--success__prm2o {
  background: #27A49B; }

.Box_Toastify__toast--error__2qjjO {
  background: #F24D4B; }

.Box_container__3kYwg {
  padding: 0 20px; }
  .Box_container__3kYwg:not(:last-child) {
    margin-bottom: 50px; }
  .Box_container__content__1EpH2 {
    max-width: 920px;
    margin: 0 auto;
    background: white;
    border-radius: 6px;
    border: 1px solid #F2F2F2;
    padding: 40px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start; }

.SubscriptionTab_Toastify__toast__2uX5C {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.SubscriptionTab_Toastify__toast--success__3ITz1 {
  background: #27A49B; }

.SubscriptionTab_Toastify__toast--error__1uZVD {
  background: #F24D4B; }

.SubscriptionTab_wrapper__1OVnJ {
  display: flex;
  flex-direction: column;
  padding: 0 20px; }

.SubscriptionTab_stop-subscription__nN0Y4 {
  margin: 20px auto 0 auto;
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer; }
  .SubscriptionTab_stop-subscription--disabled__3KiEI {
    opacity: 0.4;
    pointer-events: none; }

.SubscriptionTab_table__line--title__3Aodk h3, .SubscriptionTab_table__line--responsive__QSMCU h3 {
  display: flex;
  align-items: center; }
  .SubscriptionTab_table__line--title__3Aodk h3 svg, .SubscriptionTab_table__line--responsive__QSMCU h3 svg {
    width: 20px;
    margin-right: 15px;
    fill: #F24D4B; }

.SubscriptionTab_table__eoaQs {
  background: white;
  max-width: 920px;
  margin: 0 auto;
  width: 100%;
  border-collapse: collapse; }
  .SubscriptionTab_table__eoaQs colgroup col:not(:first-child) {
    width: 250px; }
  .SubscriptionTab_table__col-selected__1Efj4 {
    background: rgba(39, 164, 155, 0.05); }
  .SubscriptionTab_table__header__kg4Td {
    padding: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center; }
    .SubscriptionTab_table__header__kg4Td h2 {
      position: relative; }
    .SubscriptionTab_table__header__kg4Td .SubscriptionTab_table__badge__3JCK2 {
      margin-right: 20px; }
    .SubscriptionTab_table__header__kg4Td .SubscriptionTab_table__arrow__tRaTb {
      position: absolute;
      top: 20px;
      right: -80px;
      transform: scaleX(-1) rotate(70deg);
      width: 35px; }
  .SubscriptionTab_table__corner__1AqL9 {
    font-size: 14px;
    color: #9AA9AB; }
  .SubscriptionTab_table__centered__DcgP- {
    display: flex;
    justify-content: center;
    align-items: center; }
  .SubscriptionTab_table__subscription__1uKgB {
    display: flex;
    flex-direction: column; }
    .SubscriptionTab_table__subscription__1uKgB p {
      font-weight: 500; }
      .SubscriptionTab_table__subscription__1uKgB p:last-child {
        font-size: 14px;
        color: #F24D4B; }
    .SubscriptionTab_table__subscription--selected__2eU4V {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50px; }
      .SubscriptionTab_table__subscription--selected__2eU4V p {
        font-size: 14px;
        text-decoration: underline;
        font-weight: 600; }
    .SubscriptionTab_table__subscription--cta__hUJU5 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
      .SubscriptionTab_table__subscription--cta__hUJU5 button {
        margin-bottom: 5px; }
      .SubscriptionTab_table__subscription--cta__hUJU5:nth-child(1) {
        color: #27A49B; }
  .SubscriptionTab_table__line--responsive__QSMCU {
    display: none;
    text-align: center;
    border-top: 1px solid #F2F2F2; }
  .SubscriptionTab_table__line--no-border__2xpqd:last-child {
    border-bottom: 1px solid #F2F2F2; }
  .SubscriptionTab_table__line--no-border__2xpqd > td {
    border-bottom: none !important; }
  .SubscriptionTab_table__eoaQs th, .SubscriptionTab_table__eoaQs td {
    padding: 10px;
    border: 1px solid #F2F2F2; }
  .SubscriptionTab_table__eoaQs tr td:first-child {
    padding-left: 20px; }
  .SubscriptionTab_table__eoaQs tr td:not(:first-child) {
    font-weight: 500;
    font-size: 14px; }
  .SubscriptionTab_table__eoaQs td {
    border-top: none !important; }
    .SubscriptionTab_table__eoaQs td:not(:first-child) {
      text-align: center; }
  @media (max-width: 979px) {
    .SubscriptionTab_table__eoaQs .SubscriptionTab_table__arrow__tRaTb {
      display: none; } }
  @media (max-width: 580px) {
    .SubscriptionTab_table__eoaQs tbody tr:not(.SubscriptionTab_table__line--responsive__QSMCU) {
      font-size: 14px; }
      .SubscriptionTab_table__eoaQs tbody tr:not(.SubscriptionTab_table__line--responsive__QSMCU) td:first-child {
        display: none; }
    .SubscriptionTab_table__eoaQs tbody tr td {
      width: 50%; }
      .SubscriptionTab_table__eoaQs tbody tr td:not(:first-child) {
        font-weight: 400; }
    .SubscriptionTab_table__eoaQs colgroup col {
      width: auto !important; }
    .SubscriptionTab_table__eoaQs .SubscriptionTab_table__header__kg4Td .SubscriptionTab_table__badge__3JCK2 {
      width: 30px;
      margin-right: 10px; }
    .SubscriptionTab_table__eoaQs .SubscriptionTab_table__header__kg4Td h2 {
      font-size: 20px; }
    .SubscriptionTab_table__eoaQs .SubscriptionTab_table__line--responsive__QSMCU {
      background: rgba(242, 242, 242, 0.3);
      display: table-row;
      font-size: 14px;
      font-weight: 500; }
      .SubscriptionTab_table__eoaQs .SubscriptionTab_table__line--responsive__QSMCU h3 {
        font-size: 18px;
        display: flex;
        align-items: center;
        justify-content: center; }
    .SubscriptionTab_table__eoaQs .SubscriptionTab_table__line--title__3Aodk {
      display: none; }
    .SubscriptionTab_table__eoaQs .SubscriptionTab_table__col-selected__1Efj4 {
      background: none !important; } }

.SubscriptionTab_check-mark__1DQnH {
  border-radius: 50%;
  width: 10px;
  display: flex;
  justify-content: center;
  align-items: center; }
  .SubscriptionTab_check-mark--checked__4RXgP {
    height: 18px;
    width: 18px;
    min-width: 18px;
    min-height: 18px;
    background: #27A49B; }
    .SubscriptionTab_check-mark--checked__4RXgP > svg {
      width: 60%;
      fill: white; }
  .SubscriptionTab_check-mark--uncheked__2ijta {
    height: 8px;
    width: 8px;
    min-width: 8px;
    min-height: 8px;
    border: 1px solid #9AA9AB; }

.SubscriptionTab_subscription__28YT7 {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center; }
  .SubscriptionTab_subscription__28YT7 input {
    margin-bottom: 20px;
    color: #666B73; }
  .SubscriptionTab_subscription__button__3UB6q {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative; }
    .SubscriptionTab_subscription__button__3UB6q .SubscriptionTab_subscription__coupon__1Kmli {
      position: absolute;
      right: 0;
      cursor: pointer;
      width: 20px;
      height: 20px; }
      .SubscriptionTab_subscription__button__3UB6q .SubscriptionTab_subscription__coupon__1Kmli > svg {
        width: 20px;
        height: 20px;
        fill: #666B73; }

.NotificationsTab_Toastify__toast__142eH {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.NotificationsTab_Toastify__toast--success__1o-Sc {
  background: #27A49B; }

.NotificationsTab_Toastify__toast--error__29dGM {
  background: #F24D4B; }

.NotificationsTab_wrapper__2unxK {
  display: flex;
  flex-direction: column; }

.NotificationsTab_container__20KJo {
  padding: 0 20px; }

.Email_Toastify__toast__3Ufmm {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.Email_Toastify__toast--success__MjwAW {
  background: #27A49B; }

.Email_Toastify__toast--error__k3liJ {
  background: #F24D4B; }

.Email_container__MwZNH {
  width: 100%;
  margin-top: 20px; }

.Email_row__3MadL {
  display: flex;
  justify-content: space-between;
  align-items: flex-start; }
  .Email_row__3MadL + .Email_row__3MadL {
    margin-top: 30px; }
  .Email_row__3MadL p {
    margin-top: 5px;
    color: #9AA9AB;
    font-size: 14px; }

@media (max-width: 979px) {
  .Email_row__3MadL {
    flex-direction: column;
    align-items: flex-start; }
    .Email_row__3MadL > div:nth-child(2) {
      margin-top: 10px; } }

.Toastify__toast {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.Toastify__toast--success {
  background: #27A49B; }

.Toastify__toast--error {
  background: #F24D4B; }

.select-settings-container {
  z-index: 2;
  display: block !important; }
  .select-settings-container .select-settings__control {
    padding: 0px 10px; }
    .select-settings-container .select-settings__control--is-focused, .select-settings-container .select-settings__control--menu-is-open {
      box-shadow: none !important;
      border-color: #cccccc !important; }
  .select-settings-container .select-settings__value-container {
    padding: 0 !important;
    width: 150px; }
  .select-settings-container .select-settings__placeholder {
    font-size: 14px;
    color: #9AA9AB; }
  .select-settings-container .select-settings__single-value {
    font-size: 14px;
    color: #666B73;
    font-weight: 400; }
  .select-settings-container .select-settings__option {
    color: #666B73; }
    .select-settings-container .select-settings__option--is-selected {
      background: #27A49B !important;
      color: white !important;
      text-decoration: none !important; }
    .select-settings-container .select-settings__option--is-focused {
      background: rgba(39, 164, 155, 0.2); }
    .select-settings-container .select-settings__option--is-disabled {
      color: #C3C3C3;
      text-decoration: line-through; }
    .select-settings-container .select-settings__option:active {
      background: rgba(39, 164, 155, 0.4); }
  .select-settings-container .select-settings__indicator-separator {
    display: none; }
  .select-settings-container .select-settings__indicator {
    padding: 0; }
    .select-settings-container .select-settings__indicator > svg {
      fill: #666B73;
      width: 15px; }

.Header_Toastify__toast__1dn8Z {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.Header_Toastify__toast--success__1I07l {
  background: #27A49B; }

.Header_Toastify__toast--error__8wLDg {
  background: #F24D4B; }

.Header_wrapper__19N1F {
  background: white;
  border-top: 1px solid #F2F2F2;
  border-bottom: 1px solid #F2F2F2; }
  .Header_wrapper__content__16OnD {
    max-width: 920px;
    margin: 0 auto;
    padding: 50px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    @media (max-width: 979px) {
      .Header_wrapper__content__16OnD {
        flex-direction: column;
        align-items: flex-start; } }
  .Header_wrapper__profile__hCOps {
    display: flex;
    flex-direction: column;
    max-width: 500px; }
    .Header_wrapper__profile__hCOps > h2 {
      margin-bottom: 20px; }
    .Header_wrapper__profile__hCOps > p {
      line-height: 2;
      color: #9AA9AB; }

.Header_sign__1OG0O {
  display: flex;
  flex-direction: column;
  border: 1px solid #F2F2F2;
  border-radius: 6px;
  position: relative;
  margin-left: 20px;
  max-width: 350px; }
  @media (max-width: 979px) {
    .Header_sign__1OG0O {
      max-width: none;
      margin-left: 0;
      width: 100%;
      margin-top: 50px; } }
  .Header_sign__1OG0O > img {
    position: absolute;
    top: -20px;
    align-self: center; }
  .Header_sign__content__2IBky {
    padding: 40px 40px 20px 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
  .Header_sign__info__30ee9 {
    text-align: center;
    margin-top: 20px;
    font-size: 14px; }
  .Header_sign__list__1Mje5 {
    font-size: 14px;
    padding: 0;
    margin-top: 20px; }
    .Header_sign__list__1Mje5 li {
      list-style-type: none; }
      .Header_sign__list__1Mje5 li:not(:last-child) {
        margin-bottom: 5px; }
      .Header_sign__list__1Mje5 li:before {
        content: '';
        display: inline-block;
        background-image: url(/static/media/check-bullet.d73f046f.svg);
        background-size: contain;
        height: 10px;
        width: 10px;
        background-repeat: no-repeat;
        padding-left: 1em; }
  .Header_sign__title__2gJa4 {
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    color: #F24D4B; }
  .Header_sign__button__32dBa {
    font-size: 14px;
    text-transform: uppercase;
    background: #F24D4B;
    color: white;
    width: 100%;
    margin-top: auto;
    height: 50px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    border-radius: 0 0 5px 5px;
    letter-spacing: 1.5px; }





.LocalSeo_Toastify__toast__2UJKc {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.LocalSeo_Toastify__toast--success__3scWO {
  background: #27A49B; }

.LocalSeo_Toastify__toast--error__1QfD8 {
  background: #F24D4B; }
.LocalSeo_root__s62VL {
  background: white;
  border-top: 1px solid #f2f2f2;
  min-height: calc(100% - 61px); }

.LocalSeo_content__1pP1y {
  margin: 1rem auto;
  padding: 0 2rem; }

.LocalSeo_tabs__2pcp_ {
  border-bottom: 1px solid lightgray;
  display: flex;
  margin-bottom: 10px;
  height: 42px;
  box-sizing: border-box; }

.LocalSeo_iframe-container__6GA6N {
  height: calc(100vh - 80px - 52px - 2rem) !important; }

.LocalSeo_tab__1plz3 {
  bottom: -2px;
  font-weight: 500;
  height: 2rem;
  padding-bottom: 0.4rem;
  margin-right: 1rem;
  position: relative; }

.LocalSeo_activeTab__1v_TW {
  color: #f24d4b;
  border-bottom: 2px solid #f24d4b; }

@charset "UTF-8";
.Landing_Toastify__toast__1-Dlr {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.Landing_Toastify__toast--success__1gAqH {
  background: #27A49B; }

.Landing_Toastify__toast--error__Fk7tJ {
  background: #F24D4B; }

.Landing_container__JjsTj {
  max-width: 920px;
  margin: 20px auto 50px auto;
  padding: 20px; }

section:not(:last-child) {
  margin-bottom: 60px; }

section h3 {
  margin-bottom: 10px; }

section p {
  line-height: 2; }

section p + p {
  margin-top: 10px; }

.Landing_intro__3RbpF {
  display: flex;
  flex-wrap: wrap;
  margin-right: -30px;
  margin-top: -30px; }
  .Landing_intro__3RbpF > div {
    padding-top: 30px;
    padding-right: 30px; }
  .Landing_intro__img__28ver {
    position: relative;
    flex: 1 1 260px;
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
    margin-top: 10px; }
    .Landing_intro__img__28ver img {
      display: block;
      box-sizing: border-box;
      width: 100%;
      border: 2px solid #F2F2F2; }
    .Landing_intro__img__28ver span {
      padding-right: 30px;
      position: absolute;
      bottom: -20px;
      left: 0;
      right: 0;
      text-align: center;
      font-size: 12px;
      color: #9AA9AB; }
  .Landing_intro__text__RatoG {
    flex: 1.5 1 300px;
    display: flex;
    flex-direction: column; }
    .Landing_intro__text__RatoG button {
      align-self: flex-start;
      margin-top: 20px; }

.Landing_citation__2x-kw {
  display: flex;
  justify-content: center; }
  .Landing_citation__2x-kw blockquote {
    max-width: 650px;
    margin: 0;
    font-size: 35px;
    color: #27A49B;
    font-weight: 600;
    text-align: center;
    position: relative; }
    .Landing_citation__2x-kw blockquote:before {
      position: absolute;
      font-size: 150px;
      color: #e9e9e9;
      z-index: -1;
      content: "\201C";
      top: -50px;
      left: -20px; }
    .Landing_citation__2x-kw blockquote:after {
      position: absolute;
      font-size: 150px;
      color: #e9e9e9;
      z-index: -1;
      content: "\201D";
      bottom: -160px;
      right: -20px; }

.Landing_highlight__3JKNj {
  color: #27A49B;
  font-weight: 600; }

.Landing_cta__3Q15e {
  position: relative; }
  .Landing_cta__text__2IgAY {
    position: absolute;
    left: 200px;
    bottom: -70px; }
    .Landing_cta__text__2IgAY p {
      display: flex;
      align-items: center;
      position: relative;
      font-size: 14px;
      font-weight: 500;
      color: #43464C; }
      .Landing_cta__text__2IgAY p::before {
        position: absolute;
        left: -50px;
        top: 0px;
        content: "";
        display: block;
        width: 33px;
        height: 28px;
        background: url("data:image/svg+xml,%0A%3Csvg width='33px' height='28px' viewBox='0 0 33 28' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='17---SEO-LOCAL' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg transform='translate(-822.000000, -731.000000)' fill='%23F24D4B' fill-rule='nonzero' id='Para-1'%3E%3Cg transform='translate(365.000000, 418.000000)'%3E%3Cg id='arrow' transform='translate(452.000000, 312.000000)'%3E%3Cg id='Group-18' transform='translate(21.508519, 14.944761) scale(-1, 1) rotate(-110.000000) translate(-21.508519, -14.944761) translate(13.508519, -4.555239)'%3E%3Cpath id='Line' d='M0.53179585,-0.124221324 L1.4970124,0.137230423 L1.36628652,0.619838697 C0.58805657,3.49287527 0.885523691,8.54720918 2.27785017,15.7419482 C3.30838487,21.0671564 6.06502839,26.5648466 10.556038,32.2333965 L10.199,31.778 L13.1645815,29.145726 L15.7772342,38.8629296 L6.43587972,35.1227296 L9.4495588,32.4436424 C5.05478958,26.8008382 2.33439682,21.2974418 1.29606513,15.9319434 C-0.123384222,8.59704893 -0.428103367,3.41949418 0.401069976,0.35838695 L0.401069976,0.35838695 L0.53179585,-0.124221324 Z'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E") no-repeat center center; }

.Landing_promo__2UCnP {
  background-color: white;
  color: #27A49B;
  border: 2px dashed #27A49B;
  margin-bottom: 50px !important;
  text-align: center;
  border-radius: 6px;
  padding: 20px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.14); }
  .Landing_promo__2UCnP p {
    color: #43464C; }

@media (max-width: 1050px) {
  .Landing_cta__text__2IgAY {
    display: none; } }

@media (max-width: 650px) {
  section:not(:last-child) {
    margin-bottom: 50px; }
  .Landing_citation__2x-kw blockquote {
    font-size: 21px; } }

.AskEstimate_root__C_t6g {
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
  margin: 3rem 2rem 1rem 2rem;
  max-width: 800px;
}

.AskEstimate_root__C_t6g input {
  min-width: 200px;
}

.AskEstimate_header__2-Vbz {
  display: flex;
  justify-content: center;
  flex-grow: 1;
  font-weight: 500;
  margin: 0 2rem 2rem 2rem;
  text-align: center;
}

.AskEstimate_footer__32bQv {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  margin-top: 3rem;
}

.AskEstimate_footer__32bQv button {
  margin-bottom: 1rem;
}



.Button_button__2iFgg {
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  font-weight: bold;
  outline: none;
  padding: 0.6rem;
}

.Button_regular__IMBJt {
  color: #27a49b;
}

.Button_regular__IMBJt svg {
  fill: #27a49b;
}

.Button_emphasized__UXQNz {
  background: #f24d4b;
  border-radius: 2rem;
  box-shadow: 0 0 3px 0 rgba(242, 77, 75, 0.4);
  color: white;
  padding: 0.8rem 1.2rem;
  transition: all 0.3s ease;
}

.Button_emphasized__UXQNz:hover {
  box-shadow: 0 0 3px 0 rgba(242, 77, 75, 0.8);
}

.Button_emphasized__UXQNz svg {
  fill: white;
}

.Button_disabled__1nZXF {
  color: lightgrey;
}

.Button_disabled__1nZXF svg {
  fill: lightgrey;
}

.Button_icon__34zL_ {
  display: flex;
  margin-left: 0.6rem;
}

.Button_icon__34zL_ svg {
  height: 12px;
  width: 12px;
}

.Dashboard_Toastify__toast__3jS9N {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.Dashboard_Toastify__toast--success__Q5OCr {
  background: #27A49B; }

.Dashboard_Toastify__toast--error__qsg4Z {
  background: #F24D4B; }

@-webkit-keyframes Dashboard_skeleton__1hmAo {
  0%, 100% {
    opacity: 0.5; }
  50% {
    opacity: 1; } }

@keyframes Dashboard_skeleton__1hmAo {
  0%, 100% {
    opacity: 0.5; }
  50% {
    opacity: 1; } }

.Dashboard_wrapper__3KtAn {
  max-width: 920px;
  margin: 0 auto;
  padding: 20px; }
  .Dashboard_wrapper__3KtAn h2 {
    margin-bottom: 20px; }

.Dashboard_container__kYArH {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  grid-gap: 25px; }

.Dashboard_main__pO7ej {
  background: white;
  border-left: 2px solid #F2F2F2;
  border-right: 2px solid #F2F2F2;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  padding: 20px 20px 20px 140px; }
  .Dashboard_main__kpis__3FyWf {
    display: flex;
    flex-direction: column;
    width: 100%; }
    .Dashboard_main__kpis__3FyWf .Dashboard_kpis__line__3uKAs {
      display: flex;
      justify-content: space-between;
      line-height: 40px; }
    .Dashboard_main__kpis__3FyWf .Dashboard_kpis__title__2sfA3 {
      font-size: 17px;
      color: #9AA9AB;
      font-weight: 500;
      max-width: 200px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap; }
    .Dashboard_main__kpis__3FyWf .Dashboard_kpis__num__2LIir {
      font-size: 23px;
      font-weight: 500; }
      .Dashboard_main__kpis__3FyWf .Dashboard_kpis__num__2LIir span {
        font-size: 0.6em; }
  .Dashboard_main__progress__3SYde {
    border-left: 1px solid #D8D8D8;
    margin-left: 20px;
    padding-left: 20px; }
    .Dashboard_main__progress__3SYde .Dashboard_progress__num__1zmxS {
      font-size: 14px;
      font-weight: 500;
      line-height: 40px; }
      .Dashboard_main__progress__3SYde .Dashboard_progress__num--red__YGVm- {
        color: #F24D4B; }
      .Dashboard_main__progress__3SYde .Dashboard_progress__num--green__fBT96 {
        color: #27A49B; }
  .Dashboard_main__illu__1Cgen {
    position: absolute;
    bottom: -10px; }
  .Dashboard_main--dokey__17b98 {
    padding: 30px 50px;
    justify-content: center; }
    .Dashboard_main--dokey__17b98 img {
      height: 100%;
      z-index: 1; }
  .Dashboard_main--empty__1LKM7 {
    padding: 20px;
    justify-content: center; }
    .Dashboard_main--empty__1LKM7 img {
      -webkit-animation: Dashboard_skeleton__1hmAo 2s infinite ease-in-out;
              animation: Dashboard_skeleton__1hmAo 2s infinite ease-in-out; }
  .Dashboard_main--seo__3aQMY {
    padding: 20px 60px 20px 120px; }
    .Dashboard_main--seo__3aQMY img {
      left: -30px; }
  .Dashboard_main--ranking__2b0pe {
    padding: 20px 20px 20px 120px; }
    .Dashboard_main--ranking__2b0pe img {
      bottom: 10px;
      left: -150px; }
  .Dashboard_main--sea__1q9zI img {
    left: -30px; }
  .Dashboard_main--sea__1q9zI p {
    line-height: 1.8;
    font-size: 17px;
    font-weight: 400;
    color: #9AA9AB; }
    .Dashboard_main--sea__1q9zI p span {
      color: #27A49B;
      font-weight: 500; }
  .Dashboard_main--netlinking__15dsN img {
    left: -10px; }
  .Dashboard_main--netlinking__15dsN p {
    line-height: 1.8;
    font-size: 17px;
    font-weight: 400;
    color: #9AA9AB; }
    .Dashboard_main--netlinking__15dsN p span {
      color: #27A49B;
      font-weight: 500; }

.Dashboard_footer__2iEqo {
  min-height: 100px;
  background: #FDFDFD;
  margin-top: auto;
  border-radius: 0 0 6px 6px;
  display: flex;
  align-items: center;
  padding: 10px 25px;
  border: 2px solid #F2F2F2;
  font-size: 15px;
  color: #9AA9AB; }
  .Dashboard_footer__2iEqo a {
    display: contents;
    text-decoration: underline;
    color: #666B73;
    font-weight: 500; }
  .Dashboard_footer--text-only__2hb09 {
    justify-content: center;
    text-align: center; }
  .Dashboard_footer--text-with-button__59U3o {
    display: flex;
    justify-content: space-between; }
    .Dashboard_footer--text-with-button__59U3o button {
      margin-left: 20px;
      white-space: nowrap; }
  .Dashboard_footer--button__3sHVU {
    min-height: 80px;
    cursor: pointer;
    background: #F24D4B;
    color: white;
    font-size: 18px;
    font-weight: 600;
    position: relative;
    padding: 0 calc(25px + 40px) 0 25px; }
    .Dashboard_footer--button__3sHVU:hover::after {
      transform: translateX(10px); }
    .Dashboard_footer--button__3sHVU::after {
      position: absolute;
      content: "";
      background: url("data:image/svg+xml;base64,PHN2ZyBpZD0iQm9sZCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgMjQgMjQiPjx0aXRsZT5hcnJvdy10aGljay1yaWdodC0yPC90aXRsZT48cGF0aCBkPSJNMjMuNiwxMS4yODlsLTkuNzkzLTkuN2EyLjYwNywyLjYwNywwLDAsMC0zLjY3OS4wNzUsMi42MzgsMi42MzgsMCwwLDAtLjA2OCwzLjY4OWwzLjg3MSwzLjcxNGEuMjUuMjUsMCwwLDEtLjE3My40M0gyLjEzNUEyLjI4LDIuMjgsMCwwLDAsLjEsMTJjMCwuODE1LjQ0OCwyLjUxLDIsMi41MUgxMy43NzlhLjI1LjI1LDAsMCwxLC4xNzcuNDI3bC0zLjczMSwzLjczM2EyLjY2LDIuNjYsMCwwLDAsMy43NTgsMy43NTRsOS42MjUtOS43MkExLDEsMCwwLDAsMjMuNiwxMS4yODlaIi8+PC9zdmc+") no-repeat center center;
      -webkit-filter: brightness(0) invert(1);
              filter: brightness(0) invert(1);
      width: 20px;
      height: 20px;
      right: 40px;
      transition: transform 0.2s ease-in-out; }

@media (max-width: 1100px) {
  .Dashboard_container__kYArH {
    grid-template-rows: auto;
    grid-template-columns: 1fr; } }

@media (max-width: 480px) {
  .Dashboard_footer__2iEqo {
    min-height: auto; }
    .Dashboard_footer--text-only__2hb09 {
      padding: 20px; }
    .Dashboard_footer--text-with-button__59U3o {
      padding: 20px;
      text-align: center;
      flex-direction: column;
      justify-content: center; }
      .Dashboard_footer--text-with-button__59U3o button {
        margin-top: 10px;
        margin-left: 0; }
    .Dashboard_footer--button__3sHVU {
      padding-top: 20px;
      padding-bottom: 20px;
      padding-left: 20px; }
  .Dashboard_main__progress__3SYde {
    display: none; } }

@media (max-width: 420px) {
  .Dashboard_main__pO7ej {
    padding: 20px; }
    .Dashboard_main__illu__1Cgen {
      display: none; } }

.Box_Toastify__toast__EH8tZ {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.Box_Toastify__toast--success__2jPCS {
  background: #27A49B; }

.Box_Toastify__toast--error__1B5E- {
  background: #F24D4B; }

.Box_container__1kvGO {
  display: flex;
  flex-direction: column;
  height: 335px;
  overflow: hidden; }
  .Box_container__1kvGO * {
    box-sizing: border-box; }

.Box_header__37oc_ {
  min-height: 70px;
  background: #FDFDFD;
  border: 2px solid #F2F2F2;
  border-radius: 6px 6px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 1.2em;
  font-weight: 600;
  padding: 20px; }

@media (max-width: 480px) {
  .Box_container__1kvGO {
    height: auto; } }

.Netlinking_Toastify__toast__2jEhx {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.Netlinking_Toastify__toast--success__3IePw {
  background: #27A49B; }

.Netlinking_Toastify__toast--error__3dHPn {
  background: #F24D4B; }

.Netlinking_container__1SVyq {
  max-width: 920px;
  margin: 20px auto 0 auto;
  padding: 20px; }

.Netlinking_step__174Y_ {
  margin-bottom: 30px; }
  .Netlinking_step__174Y_ > h3 {
    margin-bottom: 10px; }
  .Netlinking_step__174Y_ > p {
    font-size: 15px;
    color: #9AA9AB;
    margin-bottom: 20px; }
    .Netlinking_step__174Y_ > p a {
      color: #666B73;
      text-decoration: underline; }

.ArticlesSelection_Toastify__toast__2gFVg {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.ArticlesSelection_Toastify__toast--success__SHh5h {
  background: #27A49B; }

.ArticlesSelection_Toastify__toast--error__2pOxz {
  background: #F24D4B; }

.ArticlesSelection_articles__oWM4p {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  overflow-x: scroll; }
  .ArticlesSelection_articles__radio__KfcS- {
    position: relative;
    display: flex;
    justify-content: center; }
    .ArticlesSelection_articles__radio__KfcS-:not(:last-child) {
      margin-right: 20px; }
    .ArticlesSelection_articles__radio--highlighted__2u8tT {
      position: absolute;
      font-weight: 500;
      color: #EEB656;
      top: -25px;
      font-size: 14px; }
    .ArticlesSelection_articles__radio__KfcS- input[type="radio"] {
      opacity: 0;
      position: fixed;
      width: 0; }
      .ArticlesSelection_articles__radio__KfcS- input[type="radio"]:checked + label {
        border: 2px solid #27A49B;
        color: #27A49B;
        background: rgba(39, 164, 155, 0.02); }
    .ArticlesSelection_articles__radio__KfcS- label {
      display: flex;
      font-weight: 500;
      box-sizing: border-box;
      justify-content: center;
      cursor: pointer;
      background: white;
      padding: 8px 20px;
      border: 2px solid #F2F2F2;
      border-radius: 6px;
      width: 150px; }

.Footer_Toastify__toast__2T510 {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.Footer_Toastify__toast--success__3sIsz {
  background: #27A49B; }

.Footer_Toastify__toast--error__afvxm {
  background: #F24D4B; }

.Footer_footer__1qDEZ {
  width: 100%;
  background: white;
  border-top: 1px solid #F2F2F2;
  position: relative; }

.Footer_container__nnkvV {
  margin: 0 auto;
  max-width: 920px;
  padding: 30px 20px 20px 20px;
  display: flex; }

.Footer_price__36-be {
  position: absolute;
  top: -20px;
  left: 0;
  right: 0;
  margin: 0 auto;
  background: white;
  width: 300px;
  display: flex;
  justify-content: space-between;
  padding: 5px 20px;
  border-radius: 50px;
  border: 1px solid #F2F2F2; }
  .Footer_price__36-be p {
    font-weight: 500; }
    .Footer_price__36-be p:first-child {
      text-transform: uppercase; }
    .Footer_price__36-be p:last-child {
      margin-left: 20px;
      color: #EEB656; }

.Footer_cart__QMXKO {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 50%;
  margin-right: 20px; }
  .Footer_cart__QMXKO h4 {
    font-weight: 300;
    color: #78909C;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 1.5px;
    margin-bottom: 10px; }
  .Footer_cart__row__3zlGS {
    display: flex;
    justify-content: space-between; }
    .Footer_cart__row__3zlGS p {
      margin-bottom: 5px; }
      .Footer_cart__row__3zlGS p:last-child {
        font-weight: 600; }
    .Footer_cart__row--total__3sGwj {
      border-top: 1px solid #F2F2F2;
      color: #27A49B;
      padding-top: 5px; }

.Footer_cta__2DK7g {
  width: 100%;
  max-width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center; }
  .Footer_cta__2DK7g p {
    color: #9AA9AB;
    font-size: 12px;
    max-width: 75%;
    margin-bottom: 10px; }
  .Footer_cta__2DK7g button {
    white-space: nowrap; }
  .Footer_cta__buttons__3KG90 {
    display: flex;
    flex-direction: column;
    align-items: center; }
  .Footer_cta__question__1BniK {
    margin-top: 5px; }

@media (max-width: 1190px) {
  .Footer_cart__QMXKO {
    max-width: 60%; }
  .Footer_cta__2DK7g {
    max-width: 40%; }
    .Footer_cta__2DK7g p {
      max-width: 100%; } }

@media (max-width: 767px) {
  .Footer_price__36-be {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content; }
  .Footer_container__nnkvV {
    padding: 40px 20px 20px 20px;
    flex-direction: column; }
    .Footer_container__nnkvV > div {
      max-width: 100%; }
  .Footer_cart__QMXKO {
    margin: 0 0 20px 0; }
  .Footer_cta__2DK7g {
    flex-direction: row;
    justify-content: space-between;
    text-align: left; }
    .Footer_cta__2DK7g p {
      max-width: 500px;
      margin: 0 20px 0 0; } }

@media (max-width: 480px) {
  .Footer_cta__2DK7g {
    flex-direction: column;
    text-align: center; }
    .Footer_cta__2DK7g p {
      max-width: 300px;
      margin: 0 0 10px 0; } }

.DurationSelection_Toastify__toast__1rhJW {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.DurationSelection_Toastify__toast--success__2f4_A {
  background: #27A49B; }

.DurationSelection_Toastify__toast--error__zchfK {
  background: #F24D4B; }

.DurationSelection_container__3McF6 {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .DurationSelection_container__slider__1ugSq {
    position: relative;
    max-width: 400px;
    width: 100%;
    margin: 0 20px;
    display: flex;
    justify-content: center; }
    .DurationSelection_container__slider__1ugSq p {
      position: absolute;
      color: #9AA9AB;
      top: -30px;
      font-size: 12px; }
    .DurationSelection_container__slider__1ugSq input {
      width: 100%; }
  .DurationSelection_container__title__JfPP2 {
    font-size: 15px;
    font-weight: 600;
    color: #78909C; }

.DurationSelection_result__29UAg {
  display: flex;
  flex-direction: column;
  background: white;
  border: 1px solid #F2F2F2;
  padding: 8px 20px;
  box-sizing: border-box;
  min-width: 130px;
  border-radius: 3px; }
  .DurationSelection_result__29UAg p:first-child {
    font-weight: 500;
    color: #78909C;
    font-size: 11px; }
  .DurationSelection_result__29UAg p:last-child {
    font-weight: 500;
    color: #27A49B; }

@media (max-width: 480px) {
  .DurationSelection_container__3McF6 {
    flex-direction: column; }
    .DurationSelection_container__slider__1ugSq {
      max-width: 100%;
      margin: 40px 0 20px 0; }
  .DurationSelection_result__29UAg {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-width: 100%; } }

.App_loading__38w4L {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box; }
  .App_loading__38w4L > img {
    width: 300px; }

.NetlinkingDashboard_Toastify__toast__2nDDL {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.NetlinkingDashboard_Toastify__toast--success__3bDrj {
  background: #27A49B; }

.NetlinkingDashboard_Toastify__toast--error__2vzfW {
  background: #F24D4B; }

.NetlinkingDashboard_container__2i7_i {
  max-width: 920px;
  margin: 20px auto 0 auto;
  padding: 20px; }
  .NetlinkingDashboard_container--white__1awSM {
    background: white;
    padding-bottom: 50px; }
  .NetlinkingDashboard_container__2i7_i section:not(:last-child) {
    margin-bottom: 50px; }

.Header_Toastify__toast__3AzJw {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.Header_Toastify__toast--success__6QZfI {
  background: #27A49B; }

.Header_Toastify__toast--error__3cYRd {
  background: #F24D4B; }

.Header_container__jsWeL {
  background: white;
  border: 2px solid #F2F2F2;
  border-radius: 6px;
  display: flex; }

.Header_campaign__MxRmZ {
  box-sizing: border-box;
  padding: 15px 30px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .Header_campaign__date__KE7ar {
    margin-top: 5px;
    font-size: 12px;
    color: #78909C; }
    .Header_campaign__date__KE7ar span {
      font-weight: 600; }
  .Header_campaign__current__MUdkm {
    width: 100%;
    display: flex;
    align-items: center; }
  .Header_campaign__status__2aAn- {
    line-height: 1;
    font-size: 12px;
    font-weight: 500;
    margin-left: 20px; }
    .Header_campaign__status--done__FUJjE {
      color: #F24D4B; }
    .Header_campaign__status--running__1b8BN {
      color: #27A49B; }
    .Header_campaign__status--pending__2dsYO {
      color: #EEB656; }
  .Header_campaign__left__26g-8 {
    display: flex;
    flex-direction: column; }
  .Header_campaign__right__2DP8w {
    display: flex;
    flex-direction: column;
    text-align: right;
    font-size: 14px; }
    .Header_campaign__right__2DP8w p:first-child {
      font-weight: 500; }
    .Header_campaign__right__2DP8w p:last-child {
      font-size: 14px;
      color: #78909C; }

.Header_cta__3C7i3 {
  box-sizing: border-box;
  background: #F24D4B;
  padding: 0 30px;
  color: white;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  cursor: pointer;
  min-width: 220px;
  border-radius: 0 4px 4px 0; }
  .Header_cta__3C7i3:hover img {
    transform: translateX(10px);
    opacity: 0.5; }
  .Header_cta__content__whX_G {
    width: 100%;
    display: flex;
    font-size: 14px;
    justify-content: space-between;
    align-items: center; }
    .Header_cta__content__whX_G img {
      transition: transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1), opacity 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
      -webkit-filter: brightness(0) invert(1);
              filter: brightness(0) invert(1);
      width: 20px;
      height: 20px; }

@media (max-width: 1000px) {
  .Header_container__jsWeL {
    flex-direction: column; }
  .Header_cta__3C7i3 {
    min-width: 100%;
    min-height: 80px;
    border-radius: 0 0 4px 4px; }
    .Header_cta__3C7i3 p > br {
      display: none; } }

@media (max-width: 550px) {
  .Header_campaign__left__26g-8 {
    width: 100%; }
  .Header_campaign__right__2DP8w {
    display: none; }
  .Header_campaign__current__MUdkm p {
    margin-left: auto; } }

@media (max-width: 375px) {
  .Header_campaign__current__MUdkm {
    flex-direction: column;
    align-items: flex-start; }
    .Header_campaign__current__MUdkm p {
      margin-top: 10px;
      margin-left: 0; } }

.Toastify__toast {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.Toastify__toast--success {
  background: #27A49B; }

.Toastify__toast--error {
  background: #F24D4B; }

.select-campaign-container {
  z-index: 30 !important;
  display: block !important;
  width: 250px;
  height: 100%; }
  .select-campaign-container .select-campaign__control {
    height: 100%;
    cursor: pointer;
    background: none;
    border: none !important; }
    .select-campaign-container .select-campaign__control--is-focused, .select-campaign-container .select-campaign__control--menu-is-open {
      box-shadow: none !important;
      border: none !important; }
  .select-campaign-container .select-campaign__value-container {
    padding: 0 !important;
    height: 30px; }
  .select-campaign-container .select-campaign__placeholder {
    font-size: 14px;
    color: #9AA9AB; }
  .select-campaign-container .select-campaign__single-value {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    color: #43464C;
    font-size: 21px;
    margin: 0; }
  .select-campaign-container .select-campaign__menu {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    font-size: 14px; }
  .select-campaign-container .select-campaign__option {
    color: #666B73;
    text-overflow: ellipsis;
    overflow: hidden; }
    .select-campaign-container .select-campaign__option--is-selected {
      background: #27A49B !important;
      color: white !important;
      text-decoration: none !important; }
    .select-campaign-container .select-campaign__option--is-focused {
      background: rgba(39, 164, 155, 0.2); }
    .select-campaign-container .select-campaign__option--is-disabled {
      color: #C3C3C3;
      text-decoration: line-through; }
    .select-campaign-container .select-campaign__option:active {
      background: rgba(39, 164, 155, 0.4); }
  .select-campaign-container .select-campaign__indicator-separator {
    display: none; }
  .select-campaign-container .select-campaign__indicator {
    padding: 0; }
    .select-campaign-container .select-campaign__indicator > svg {
      fill: #43464C;
      width: 15px; }

@media (max-width: 400px) {
  .select-campaign-container {
    width: 200px; } }

.SelectCampaign_Toastify__toast__vfwT6 {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.SelectCampaign_Toastify__toast--success__2z8uA {
  background: #27A49B; }

.SelectCampaign_Toastify__toast--error__2Gpsn {
  background: #F24D4B; }

.SelectCampaign_menu__top__2ebv2 {
  padding: 6px 10px 10px 10px;
  display: flex;
  justify-content: center; }
  .SelectCampaign_menu__top__2ebv2 button {
    background: white;
    border: 1px solid #F24D4B;
    color: #F24D4B; }
    .SelectCampaign_menu__top__2ebv2 button > svg {
      fill: #F24D4B; }

.NewCampaignModal_Toastify__toast__Wd6d- {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.NewCampaignModal_Toastify__toast--success__1u3s1 {
  background: #27A49B; }

.NewCampaignModal_Toastify__toast--error__1va9T {
  background: #F24D4B; }

.NewCampaignModal_container__3A0oM {
  padding: 20px 50px; }
  .NewCampaignModal_container__3A0oM h1 {
    color: #27A49B;
    margin-bottom: 30px; }
  .NewCampaignModal_container__3A0oM > div {
    padding: 0; }
  .NewCampaignModal_container__3A0oM section:not(:last-child) {
    margin-bottom: 50px; }

@media (max-width: 767px) {
  .NewCampaignModal_container__3A0oM {
    padding: 0; } }

.useValidationModal_Toastify__toast__3NrU1 {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.useValidationModal_Toastify__toast--success__Qb0uo {
  background: #27A49B; }

.useValidationModal_Toastify__toast--error__2r5DS {
  background: #F24D4B; }

.useValidationModal_container__1t1BC {
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: center; }
  .useValidationModal_container__1t1BC h1 {
    margin: 20px 0; }
  .useValidationModal_container__1t1BC p {
    max-width: 600px;
    text-align: center;
    line-height: 2; }
  .useValidationModal_container__1t1BC button {
    margin-top: 50px; }

.useValidationModal_check__2vJLi {
  background: #27A49B;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px; }
  .useValidationModal_check__2vJLi svg {
    width: 100%;
    fill: white; }

@media (max-width: 767px) {
  .useValidationModal_container__1t1BC {
    padding: 50px 20px; } }

.Modal_Toastify__toast__1AFKm {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.Modal_Toastify__toast--success___HdOT {
  background: #27A49B; }

.Modal_Toastify__toast--error__1FklS {
  background: #F24D4B; }

.Modal_container__2VNCj {
  padding: 20px 50px;
  display: flex;
  flex-direction: column;
  align-items: center; }
  .Modal_container__2VNCj h1 {
    color: #27A49B;
    align-self: flex-start; }
  .Modal_container__2VNCj form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center; }
    .Modal_container__2VNCj form label textarea {
      min-height: 100px; }
    .Modal_container__2VNCj form button {
      margin: 30px 0 10px 0; }

.Modal_month__23dV- {
  margin: 30px 0;
  width: 100%;
  box-sizing: border-box;
  padding: 20px 30px;
  background: rgba(39, 164, 155, 0.1);
  border-radius: 6px; }
  .Modal_month__23dV- h4 {
    color: #43464C;
    margin-bottom: 10px; }
  .Modal_month__23dV- span {
    color: #43464C;
    font-weight: 600;
    font-size: 21px; }
  .Modal_month__23dV- input[type="number"] {
    font-size: 21px;
    width: 50px;
    color: #43464C;
    font-weight: 600;
    border: 1px solid #9AA9AB;
    border-radius: 3px; }

.Modal_article__20-aC {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  flex-direction: column;
  margin: 30px 0;
  padding: 20px 30px;
  background: rgba(39, 164, 155, 0.1);
  border-radius: 6px; }
  .Modal_article__20-aC h4 {
    color: #43464C;
    margin-bottom: 5px; }
  .Modal_article__20-aC a {
    line-height: 1.4;
    text-decoration: underline; }

.Modal_configuration__16x2D {
  margin-top: 30px; }

.Modal_information__3hAWC {
  box-sizing: border-box;
  font-size: 14px;
  background: rgba(238, 182, 86, 0.2);
  color: #c38d30;
  border-radius: 6px;
  padding: 10px 20px;
  margin-top: 10px;
  width: 100%; }

@media (max-width: 767px) {
  .Modal_container__2VNCj {
    padding: 0; }
  .Modal_month__23dV- {
    max-width: 100%;
    align-self: flex-start;
    text-align: left; } }

.Articles_Toastify__toast__1DiJ2 {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.Articles_Toastify__toast--success__3wrYf {
  background: #27A49B; }

.Articles_Toastify__toast--error__CUJm_ {
  background: #F24D4B; }

@-webkit-keyframes Articles_slide-in-right__3Uurh {
  0% {
    transform: translateX(100px);
    opacity: 0; }
  100% {
    transform: translateX(10px);
    opacity: 1; } }

@-webkit-keyframes Articles_slide-out-right___kvjN {
  0% {
    transform: translateX(-20px);
    opacity: 1; }
  100% {
    transform: translateX(100px);
    opacity: 0; } }

.Articles_container__15OR6 {
  display: flex;
  flex-direction: column; }
  .Articles_container__15OR6 h2 {
    color: #43464C;
    margin-bottom: 30px; }
    .Articles_container__15OR6 h2 span {
      margin-left: 20px;
      font-weight: 500;
      color: #9AA9AB; }

.Articles_table__3L1K6 {
  overflow: auto; }
  .Articles_table__3L1K6 table {
    min-width: 600px;
    overflow-x: scroll; }
    .Articles_table__3L1K6 table tbody {
      display: block;
      max-height: 300px;
      overflow-x: hidden;
      overflow-y: scroll; }
    .Articles_table__3L1K6 table thead,
    .Articles_table__3L1K6 table tbody tr,
    .Articles_table__3L1K6 table tfoot {
      display: table;
      width: 100%;
      table-layout: fixed; }
    .Articles_table__3L1K6 table th:first-child,
    .Articles_table__3L1K6 table td:first-child {
      width: 50%; }
    .Articles_table__3L1K6 table th:nth-child(2),
    .Articles_table__3L1K6 table td:nth-child(2) {
      width: 10%; }
    .Articles_table__3L1K6 table th:nth-child(3),
    .Articles_table__3L1K6 table td:nth-child(3) {
      width: 10%; }
    .Articles_table__3L1K6 table th:nth-child(4),
    .Articles_table__3L1K6 table td:nth-child(4) {
      width: 15%; }
    .Articles_table__3L1K6 table th:nth-child(5),
    .Articles_table__3L1K6 table td:nth-child(5) {
      width: 15%; }
    .Articles_table__3L1K6 table tr:hover .Articles_date__button-container__3BZDQ {
      -webkit-animation: Articles_slide-in-right__3Uurh 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
              animation: Articles_slide-in-right__3Uurh 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

.Articles_url__2S6T_ {
  display: flex;
  flex-direction: column;
  word-break: break-all; }
  .Articles_url__2S6T_ a {
    line-height: 1.4; }
    .Articles_url__2S6T_ a:hover {
      text-decoration: underline; }
    .Articles_url__2S6T_ a:first-child {
      font-weight: 500; }
    .Articles_url__2S6T_ a:last-child {
      font-size: 12px;
      color: #78909C;
      position: relative;
      padding-left: 30px;
      margin-top: 5px; }
      .Articles_url__2S6T_ a:last-child:before {
        position: absolute;
        content: "";
        background: no-repeat center center url("data:image/svg+xml,%0A%3Csvg width='14px' height='12px' viewBox='0 0 14 12' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='18---NETLINKING-(dashboard)' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cg transform='translate(-346.000000, -666.000000)' id='Articles' stroke='%2378909C'%3E%3Cg transform='translate(336.000000, 560.000000)'%3E%3Cg id='list' transform='translate(0.000000, 55.000000)'%3E%3Cg id='url' transform='translate(0.420613, 30.000000)'%3E%3Cg id='diagram-increase' transform='translate(16.579387, 27.000000) rotate(-285.000000) translate(-16.579387, -27.000000) translate(9.579387, 22.000000)'%3E%3Cpath d='M11.8172222,0.416666667 L10.3066667,6.25277778 L10.3066668,6.25277733 C9.79898623,8.21378288 8.02955012,9.58333288 6.00388901,9.58333288 L0.277777894,9.58333288' id='Path'%3E%3C/path%3E%3Cpolyline id='Path' points='9.81166667 1.655 11.8172222 0.416666667 13.0555556 2.42222222'%3E%3C/polyline%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
        background-size: 15px 15px;
        width: 15px;
        height: 15px;
        left: 5px;
        top: -3px; }

.Articles_date__1hDK4 {
  position: relative; }
  .Articles_date__button-container__3BZDQ {
    display: flex;
    position: absolute;
    -webkit-animation: Articles_slide-out-right___kvjN 0s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
            animation: Articles_slide-out-right___kvjN 0s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), white);
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto 0;
    width: calc(100% + 50px);
    height: 75px;
    justify-content: flex-end;
    align-items: center;
    padding-right: 20px; }
    .Articles_date__button-container--animate__1O9jb {
      -webkit-animation: Articles_slide-out-right___kvjN 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
              animation: Articles_slide-out-right___kvjN 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
    .Articles_date__button-container__3BZDQ button {
      border: none;
      background: white;
      color: #43464C;
      text-decoration: underline;
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;
      padding: 0;
      height: 50px; }
      .Articles_date__button-container__3BZDQ button:focus {
        outline: 0; }

@media (max-width: 767px) {
  .Articles_container__15OR6 h2 {
    font-size: 25px; } }

.Configuration_Toastify__toast__VST9t {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.Configuration_Toastify__toast--success__Dqe1u {
  background: #27A49B; }

.Configuration_Toastify__toast--error__18Qeg {
  background: #F24D4B; }

.Configuration_container__2QZ1P {
  display: flex;
  flex-direction: column; }

.Configuration_header__3Adve {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px; }
  .Configuration_header__3Adve h2 {
    color: #43464C; }
  .Configuration_header__3Adve button {
    min-width: -webkit-max-content;
    min-width: -moz-max-content;
    min-width: max-content;
    border: none;
    background: white;
    color: #43464C;
    text-decoration: underline;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    padding: 0; }
    .Configuration_header__3Adve button:focus {
      outline: 0; }

.Configuration_table__LjRQI {
  overflow: auto; }
  .Configuration_table__LjRQI table {
    min-width: 600px;
    overflow-x: scroll; }
    .Configuration_table__LjRQI table tbody {
      display: block;
      max-height: 300px;
      overflow-x: hidden;
      overflow-y: scroll; }
    .Configuration_table__LjRQI table thead,
    .Configuration_table__LjRQI table tbody tr,
    .Configuration_table__LjRQI table tfoot {
      display: table;
      width: 100%;
      table-layout: fixed; }
    .Configuration_table__LjRQI table th:first-child,
    .Configuration_table__LjRQI table td:first-child {
      width: 40%; }
    .Configuration_table__LjRQI table th:last-child,
    .Configuration_table__LjRQI table td:last-child {
      width: 40%; }
    .Configuration_table__LjRQI table tr:hover .Configuration_date__button-container__jjq0M {
      -webkit-animation: Configuration_slide-in-right__umTEq 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
              animation: Configuration_slide-in-right__umTEq 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

.Configuration_url__Fd54e {
  line-height: 1.4;
  font-weight: 500; }
  .Configuration_url__Fd54e:hover {
    text-decoration: underline; }

.Configuration_keywords__3PdCk {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -10px; }
  .Configuration_keywords__keyword__3qUFe {
    padding: 2px 10px;
    background: rgba(238, 182, 86, 0.2);
    color: #c38d30;
    border-radius: 3px;
    margin-bottom: 10px;
    font-size: 12px; }
    .Configuration_keywords__keyword__3qUFe:not(:last-child) {
      margin-right: 10px; }

@media (max-width: 767px) {
  .Configuration_header__3Adve h2 {
    font-size: 25px; } }

@media (max-width: 580px) {
  .Configuration_header__3Adve {
    flex-direction: column;
    align-items: flex-start; }
    .Configuration_header__3Adve h2 {
      margin-bottom: 10px; } }

@charset "UTF-8";
.Content_Toastify__toast__1ALqf {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.Content_Toastify__toast--success__1pmP3 {
  background: #27A49B; }

.Content_Toastify__toast--error__2k2U_ {
  background: #F24D4B; }

.Content_container__3FrFS {
  max-width: 920px;
  margin: 20px auto 50px auto;
  padding: 20px; }

section:not(:last-child) {
  margin-bottom: 60px; }

section h3 {
  margin-bottom: 10px; }

section p {
  line-height: 2; }

section p + p {
  margin-top: 10px; }

.Content_intro__1m2fT {
  display: flex;
  flex-wrap: wrap;
  margin-right: -30px;
  margin-top: -30px; }
  .Content_intro__1m2fT > div {
    padding-top: 30px;
    padding-right: 30px; }
  .Content_intro__img__27IT2 {
    position: relative;
    flex: 1 1 260px;
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content; }
    .Content_intro__img__27IT2 img {
      display: block;
      box-sizing: border-box;
      width: 100%; }
  .Content_intro__text__2p_R- {
    flex: 1.5 1 300px;
    display: flex;
    flex-direction: column; }
    .Content_intro__text__2p_R- button {
      align-self: flex-start;
      margin-top: 20px; }

.Content_trustpilot__6FdA- {
  padding-top: 50px; }

.Content_citation__1RccS {
  display: flex;
  justify-content: center; }
  .Content_citation__1RccS blockquote {
    max-width: 700px;
    margin: 0;
    font-size: 35px;
    color: #27A49B;
    font-weight: 600;
    text-align: center;
    position: relative; }
    .Content_citation__1RccS blockquote:before {
      position: absolute;
      font-size: 150px;
      color: #e9e9e9;
      z-index: -1;
      content: "\201C";
      top: -70px;
      left: -50px; }
    .Content_citation__1RccS blockquote:after {
      position: absolute;
      font-size: 150px;
      color: #e9e9e9;
      z-index: -1;
      content: "\201D";
      bottom: -160px;
      right: -20px; }

.Content_highlight__3YEoM {
  color: #27A49B;
  font-weight: 600; }

.Content_cta__1gBQZ {
  position: relative; }
  .Content_cta__text__382XV {
    position: absolute;
    left: 250px;
    bottom: -70px; }
    .Content_cta__text__382XV p {
      display: flex;
      align-items: center;
      position: relative;
      font-size: 14px;
      font-weight: 500;
      color: #43464C; }
      .Content_cta__text__382XV p::before {
        position: absolute;
        left: -50px;
        top: 0px;
        content: "";
        display: block;
        width: 33px;
        height: 28px;
        background: url("data:image/svg+xml,%0A%3Csvg width='33px' height='28px' viewBox='0 0 33 28' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='17---SEO-LOCAL' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg transform='translate(-822.000000, -731.000000)' fill='%23F24D4B' fill-rule='nonzero' id='Para-1'%3E%3Cg transform='translate(365.000000, 418.000000)'%3E%3Cg id='arrow' transform='translate(452.000000, 312.000000)'%3E%3Cg id='Group-18' transform='translate(21.508519, 14.944761) scale(-1, 1) rotate(-110.000000) translate(-21.508519, -14.944761) translate(13.508519, -4.555239)'%3E%3Cpath id='Line' d='M0.53179585,-0.124221324 L1.4970124,0.137230423 L1.36628652,0.619838697 C0.58805657,3.49287527 0.885523691,8.54720918 2.27785017,15.7419482 C3.30838487,21.0671564 6.06502839,26.5648466 10.556038,32.2333965 L10.199,31.778 L13.1645815,29.145726 L15.7772342,38.8629296 L6.43587972,35.1227296 L9.4495588,32.4436424 C5.05478958,26.8008382 2.33439682,21.2974418 1.29606513,15.9319434 C-0.123384222,8.59704893 -0.428103367,3.41949418 0.401069976,0.35838695 L0.401069976,0.35838695 L0.53179585,-0.124221324 Z'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E") no-repeat center center; }

.Content_promo__2uMW- {
  background-color: white;
  color: #27A49B;
  border: 2px dashed #27A49B;
  margin-bottom: 50px !important;
  text-align: center;
  border-radius: 6px;
  padding: 20px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.14); }
  .Content_promo__2uMW- p {
    color: #43464C; }

@media (max-width: 1050px) {
  .Content_cta__text__382XV {
    display: none; } }

@media (max-width: 650px) {
  section:not(:last-child) {
    margin-bottom: 50px; }
  .Content_citation__1RccS blockquote {
    font-size: 21px; } }

.Toastify__toast {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.Toastify__toast--success {
  background: #27A49B; }

.Toastify__toast--error {
  background: #F24D4B; }

#root {
  height: 100%; }

body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  background-color: #FAFDFD;
  font-size: 16px;
  color: #666B73;
  font-weight: 300; }

b, strong {
  font-weight: 600; }

p, h1, h2, h3, h4, h5, h6 {
  margin: 0px; }

h1 {
  font-weight: 600;
  font-size: 45px; }

h2 {
  font-weight: 600;
  font-size: 35px; }

h3 {
  font-weight: 600;
  font-size: 25px; }

h4 {
  font-weight: 600;
  font-size: 20px; }

a {
  color: inherit;
  text-decoration: none; }

