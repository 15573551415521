@import "src/style/partials";

.tabs {
  &__list {
    border-bottom: 2px solid $color-border;
    padding-left: 0;
    margin: 0 0 20px;
    &--active {
      background-color: white;
      border: solid $color-border;
      border-width: 2px 2px 0 2px;
      color: $color-grey !important; } }

  &__list-item {
    display: inline-block;
    list-style: none;
    cursor: pointer;
    padding: 10px 20px;
    border-radius: 6px 6px 0 0;
    margin-bottom: -2px;
    color: $color-light-grey;
    font-weight: $font-medium; } }
