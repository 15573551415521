@import "src/style/partials";

.wrapper {
  background: $color-background;
  z-index: 1;
  @media #{$media-max-width} {
    padding: 0 20px; } }

.kpis {
  padding: 20px 0;
  max-width: $max-width;
  margin: 0 auto;
  z-index: 2;
  display: flex;
  justify-content: flex-end;
  flex-wrap: nowrap;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none; }
  top: -20px;
  right: 0;
  @media #{$media-max-width} {
    justify-content: flex-start; }
  >div {
    flex: 0 0 auto;
    &:not(:first-child) {
     margin-left: 30px; } } }
