@import "src/style/partials";

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px 20px 20px;
  box-sizing: border-box;
  max-width: $max-width;
  margin: 0 auto; }

.form {
  display: flex;
  flex-direction: column;
  width: 50%;
  >label, >span {
    &:not(:last-child) {
      margin-bottom: 10px; } }
  @media #{$media-tablet-portrait} {
    width: 100%; }

  &__url {
    position: relative;
    .url__change {
      position: absolute;
      right: 0;
      bottom: 10px;
      cursor: pointer;
      font-size: 12px;
      color: $color-grey;
      &:hover {
        text-decoration: underline; } } }

  &__buttons {
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;

    .buttons__login {
      margin-top: 10px;
      cursor: pointer;
      font-size: 12px;
      color: $color-grey;
      &:hover {
        text-decoration: underline; } }

    .buttons__or {
      margin: 30px 0;
      span {
        background: $color-background; } } } }

.success {
  padding: 0 20px 20px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  max-width: $max-width;
  margin: 0 auto;
  p {
    margin-bottom: 10px; }
  h3 {
    color: $color-complementary;
    font-weight: $font-medium;
    margin-bottom: 50px;
    text-align: center; }
  button {
    margin-top: 20px; }

  &__container {
    display: flex;
    width: 60%;
    @media #{$media-tablet-portrait} {
      width: 100%; }

    .container__module {
      padding-right: 20px;
      @media #{$media-tablet-portrait} {
        display: none; } }

    .container__description {
      padding-left: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      @media #{$media-tablet-portrait} {
        text-align: center;
        padding: 0;
        margin: 0 auto;
        button {
          align-self: center; } } } } }

.error {
  color: white;
  text-align: center;
  font-size: 14px;
  margin-top: 20px;
  background: $color-primary;
  padding: 10px; }
