@import "../../style/partials";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  max-width: $max-width;
  padding: 50px 20px;
  @media #{$media-max-width} {
    padding: 20px; }

  >span {
    margin-bottom: 10px; } }
