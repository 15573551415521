.wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;

  &__loading {
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.9);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 0.3s ease-in-out, z-index 0.3s;

    &--show {
      opacity: 1; }

    &--hide {
      opacity: 0;
      z-index: -1; } } }
