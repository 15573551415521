@import "src/style/partials";

.wrapper {
  background: white;
  border-top: 1px solid $color-border;
  padding: 45px 0 20px 0;
  z-index: 1;
  &:nth-child(even) {
    background: #FDFDFD; } }

.container {
  max-width: $max-width;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  position: relative;
  @media #{$media-max-width} {
    padding: 0 20px; }

  &__arrow {
    position: absolute;
    top: -150px;
    left: -40px;
    @media (max-width: 1300px) {
      display: none; } }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media #{$media-tablet-landscape} {
      &:nth-child(1) {
        flex-direction: column-reverse;
        align-items: flex-start; } }

    .header__buttonWrapper {
      position: relative;
      display: flex;
      justify-content: center;
      @media #{$media-tablet-landscape} {
        margin-bottom: 20px;
        width: 100%; }
      &:hover {
        .header__price {
          opacity: 1;
          transform: translate(0, -10px); } }

      .header__price {
        position: absolute;
        font-size: 14px;
        color: $color-complementary;
        opacity: 0;
        top: -20px;
        transition: opacity 0.1s ease-in-out, transform .1s ease-in-out; } } }

  &__list {
    display: flex;
    justify-content: flex-start;
    flex-wrap: nowrap;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    padding: 25px 0;
    &::-webkit-scrollbar {
      display: none; }
    >div {
      z-index: 1;
      flex: 0 0 auto;
      &:not(:first-child) {
        margin-left: 20px; } } } }

.title {
  font-size: 25px;
  display: flex;
  @media #{$media-medium} {
    font-size: 19px; }

  &__text {
    font-weight: $font-medium; } }
