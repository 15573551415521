@import "src/style/partials";
@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,400i,500,600,900');
// -----FONTS-----
// 300 light
// 400 regular
// 400i regular italic
// 500 medium
// 600 semi-bold
// 900 black
// ---------------

#root {
  height: 100%; }

body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  background-color: $color-background;
  font-size: 16px;
  color: $color-grey;
  font-weight: $font-light; }

b, strong {
  font-weight: $font-semi-bold; }

p, h1, h2, h3, h4, h5, h6 {
  margin: 0px; }

h1 {
  font-weight: $font-semi-bold;
  font-size: 45px; }

h2 {
  font-weight: $font-semi-bold;
  font-size: 35px; }

h3 {
  font-weight: $font-semi-bold;
  font-size: 25px; }

h4 {
  font-weight: $font-semi-bold;
  font-size: 20px; }

a {
  color: inherit;
  text-decoration: none; }
