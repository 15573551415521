@import "src/style/partials";

.container {
  border: 1px solid $color-border;
  display: flex;
  flex-direction: column;
  width: 250px;
  height: 100%;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
  background: #9aa9ab45;

  &__score {
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 75px;
    background: white;
    border-radius: 0 6px 0 0;
    border-top: 1px solid $color-border;
    border-right: 1px solid $color-border;
    span {
      font-size: 4em;
      color: $color-dark-grey;
      font-weight: $font-black; } } }

%dot {
  min-height: 12px;
  min-width: 12px;
  margin-right: 7px;
  border-radius: 50%; }

.header {
  display: flex;
  align-items: center;
  background: white;
  padding: 10px 15px;
  border-bottom: 1px solid $color-border;
  font-size: small;

  &__dot--red {
    @extend %dot;
    background-color: $color-primary; }


  &__dot--yellow {
    @extend %dot;
    background-color: $color-secondary; }

  &__dot--green {
    @extend %dot;
    background-color: #34C749; }

  &__domain {
    color: $color-light-grey;
    margin-left: 5px;
    display: flex;
    align-items: center;

    .domain__url {
      color: $color-dark-grey;
      font-weight: $font-regular;
      display: inline-block;
      width: 120px;
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis; } } }

.image {
  display: flex;
  width: 100%;
  height: 160px;

  &__screenshot {
    height: auto;
    margin: auto;
    min-height: 100%;
    width: 100%; }

  &--add {
    justify-content: center;
    align-items: center;
    cursor: pointer;
    svg {
      width: 50px;
      height: 50px;
      fill: $color-light-grey; } } }
