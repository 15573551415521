@import "src/style/partials";

.DateRangePicker {
  .DateRangePickerInput {
    border: none;
    background: none; }

  .DateInput, input {
    box-sizing: border-box;
    background: none;
    color: $color-grey;
    cursor: pointer;
    padding: 0;
    width: 85px;
    font-size: 16px; }

  // border on click
  .DateInput_input__focused {
    border-bottom: 2px solid $color-complementary; }

  // arrow
  .DateRangePickerInput_arrow_svg {
    width: 15px;
    margin: 0 10px;
 }    //display: none

  // window with dates
  .DateRangePicker_picker {
    z-index: 3; } }
