@import "src/style/partials";

/**
 * ----------------------------------------
 * animation slide-in-blurred-right
 * ---------------------------------------- */
@-webkit-keyframes slide-in-blurred-right {
  0% {
    -webkit-transform: translateX(1000px) scaleX(2.5) scaleY(0.2);
    transform: translateX(1000px) scaleX(2.5) scaleY(0.2);
    -webkit-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0; }

  100% {
    -webkit-transform: translateX(0) scaleY(1) scaleX(1);
    transform: translateX(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1; } }


@keyframes slide-in-blurred-right {
  0% {
    -webkit-transform: translateX(1000px) scaleX(2.5) scaleY(0.2);
    transform: translateX(1000px) scaleX(2.5) scaleY(0.2);
    -webkit-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0; }

  100% {
    -webkit-transform: translateX(0) scaleY(1) scaleX(1);
    transform: translateX(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1; } }

.container {
  position: absolute;
  right: 100px;
  top: 100px;
  animation: slide-in-blurred-right 0.5s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;

  &__close {
    position: absolute;
    right: 12px;
    top: 6px;
    svg {
      width: 10px;
      fill: $color-very-light-grey; } }

  // text
  p {
    background: white;
    border: 3px solid $color-complementary;
    color: $color-complementary;
    padding: 20px 30px;
    box-sizing: border-box;
    border-radius: 6px;
    box-shadow: 0 10px 20px 0 rgba(0,0,0,0.1); }

  // arrow
  img {
    position: absolute;
    right: -50px;
    top: -15px;
    transform: rotate(-120deg);
    cursor: default;
    pointer-events: none; } }
