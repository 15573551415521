@import "src/style/partials";
@import "utils";

.container {
  display: flex;
  flex-direction: column;
  height: 335px;
  overflow: hidden;
  * {
    box-sizing: border-box; } }

.header {
  min-height: 70px;
  background: $box-background;
  border: 2px solid $box-border;
  border-radius: 6px 6px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 1.2em;
  font-weight: $font-semi-bold;
  padding: 20px; }

@media #{$media-phone} {
  .container {
    height: auto; } }
