@import "src/style/partials";

.container {
  max-width: $max-width;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;

  &__title {
    margin-bottom: 20px; }

  &__text {
    margin-top: 20px; }

  &__img {
    width: 75%;
    box-shadow: 0 10px 15px 0 rgba(0,0,0,0.1);
    @media #{$media-tablet-portrait} {
      width: 100%; } } }

