@import "src/style/partials";

.page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  button {
    margin-top: 50px; } }

.message {
  position: absolute;
  left: 50px;
  top: 190px;
  @media #{$media-tablet-portrait} {
    display: none; }
  &:before {
    position: absolute;
    content: url("../../../components/Arrow/arrow.svg");
    transform: scaleX(-1) rotate(-55deg);
    bottom: -70px; } }
