@import "src/style/partials";

.table {
  border-spacing: 0;
  font-size: 14px;
  line-height: 1;
  width: 100%;

  thead {
    border-bottom: 1px solid $color-border; }

  tbody {
    tr:not(:last-child) {
      border-bottom: 1px solid $color-border; } }


  th, td {
    &:not(:first-child) {
      padding-left: 20px; } }

  th {
    color: $color-complementary;
    font-weight: $font-medium;
    text-align: left;
    padding-bottom: 10px; }


  td {
    padding: 10px 0;
    line-height: 1.5; } }

.table__wrapper {
  position: relative; }

.loading {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.9);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease-in-out, z-index 0.3s;

  &--show {
    z-index: 1;
    opacity: 1; }

  &--hide {
    opacity: 0;
    z-index: -1; } }

.no-data {
  display: flex;
  justify-content: center;
  width: 100% !important;
  padding: 30px 0 !important;
  font-style: italic;
  color: $color-light-grey; }
