@import "src/style/partials";

.select-audit-container {
  z-index: 2;
  display: block !important;
  margin-left: 5px;

  .select-audit {
    &__control {
      padding: 0px 10px;
      &--is-focused, &--menu-is-open {
        box-shadow: none !important;
        border-color: hsl(0,0%,80%) !important; } }

    &__value-container {
      padding: 0 !important;
      width: 90px; }

    &__placeholder {
      font-size: 14px;
      color: $color-light-grey; }

    &__single-value {
      font-size: 14px;
      color: $color-grey;
      font-weight: $font-regular; }

    &__menu {
      width: 200px; }

    &__option {
      color: $color-grey;
      &--is-selected {
        background: $color-complementary !important;
        color: white !important;
        text-decoration: none !important; }

      &--is-focused {
        background: rgba($color-complementary, 0.2); }

      &--is-disabled {
        color: $color-very-light-grey;
        text-decoration: line-through; }

      &:active {
        background: rgba($color-complementary, 0.4); } }

    &__indicator-separator {
      display: none; }

    &__indicator {
      padding: 0;
      >svg {
        fill: $color-grey;
        width: 15px; } } } }
