@import "colors";

// ==================TOASTIFY==================
.Toastify__toast {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.Toastify__toast--success {
  background: $color-complementary; }
.Toastify__toast--error {
  background: $color-primary; }
