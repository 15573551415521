@value colors: "../../../style/colors.css";
@value primary-color, complementary-color from colors;

.button {
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  font-weight: bold;
  outline: none;
  padding: 0.6rem;
}

.regular {
  color: complementary-color;
}

.regular svg {
  fill: complementary-color;
}

.emphasized {
  background: primary-color;
  border-radius: 2rem;
  box-shadow: 0 0 3px 0 rgba(242, 77, 75, 0.4);
  color: white;
  padding: 0.8rem 1.2rem;
  transition: all 0.3s ease;
}

.emphasized:hover {
  box-shadow: 0 0 3px 0 rgba(242, 77, 75, 0.8);
}

.emphasized svg {
  fill: white;
}

.disabled {
  color: lightgrey;
}

.disabled svg {
  fill: lightgrey;
}

.icon {
  display: flex;
  margin-left: 0.6rem;
}

.icon svg {
  height: 12px;
  width: 12px;
}
