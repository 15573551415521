@import "src/style/partials";

.container {
  display: flex;
  flex-direction: column;
  width: 100%; }

.downloadReport {
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid $color-border;
  padding: 20px;

  &__button {
    background: none;
    border: none;
    padding: 0;
    text-align: center;
    text-decoration: underline;
    font-size: 16px;
    display: flex;
    align-items: center;
    color: $color-grey;
    cursor: pointer;
    &:focus {
      outline: 0; }

    >svg {
      width: 16px;
      fill: $color-grey;
      margin-right: 10px; }

    &--loading {
      pointer-events: none;
      opacity: 0.4;
      cursor: default; }

    &--disabled {
      cursor: default; } } }
