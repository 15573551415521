@import "../../../../style/partials";

%box {
  position: relative;
  background: white;
  border: 2px solid $color-border;
  border-radius: 6px;
  padding: 20px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  &:hover {
    * {
      color: $color-complementary; } } }

.container--selected {
  @extend %box;
  border: 2px solid $color-complementary;
  * {
    color: $color-complementary !important; } }

.container {
  @extend %box;

  &__subtitle {
    font-size: small;
    color: $color-light-grey; }

  &__content {
    margin-top: 30px;
    * {
      color: $color-grey !important; } } }

.selected {
  position: absolute;
  right: 20px;
  top: 25px;
  display: flex;
  align-items: center;
  p {
    padding-left: 10px; }
  &__check {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: $color-complementary;
    width: 25px;
    height: 25px;
    svg {
      stroke: white;
      fill: white;
      width: 50%; } } }

@media #{$media-tablet-landscape} {
  .selected {
    p {
      display: none; } } }

@media #{$media-medium} {
  .selected__check {
    display: none; } }
