@import "src/style/partials";

.wrapper {
  display: flex;
  flex-direction: column;
  padding: 0 20px; }

.stop-subscription {
  margin: 20px auto 0 auto;
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
  &--disabled {
    opacity: 0.4;
    pointer-events: none; } }

%title-bloc {
  h3 {
    display: flex;
    align-items: center;
    svg {
      width: 20px;
      margin-right: 15px;
      fill: $color-primary; } } }

.table {
  background: white;
  max-width: $max-width;
  margin: 0 auto;
  width: 100%;
  border-collapse: collapse;

  colgroup {
    col {
      &:not(:first-child) {
        width: 250px; } } }

  &__col-selected {
    background: rgba($color-complementary, 0.05); }

  &__header {
    padding: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    h2 {
      position: relative; }
    .table__badge {
      margin-right: 20px; }
    .table__arrow {
      position: absolute;
      top: 20px;
      right: -80px;
      transform: scaleX(-1) rotate(70deg);
      width: 35px; } }

  &__corner {
    font-size: 14px;
    color: $color-light-grey; }

  &__centered {
    display: flex;
    justify-content: center;
    align-items: center; }

  &__subscription {
    display: flex;
    flex-direction: column;
    p {
      font-weight: $font-medium;
      &:last-child {
        font-size: 14px;
        color: $color-primary; } }
    &--selected {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50px;
      p {
        font-size: 14px;
        text-decoration: underline;
        font-weight: $font-semi-bold; } }
    &--cta {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      button {
        margin-bottom: 5px; }
      &:nth-child(1) {
        color: $color-complementary; } } }


  &__line {
    &--title {
      @extend %title-bloc; }
    &--responsive {
      display: none;
      text-align: center;
      border-top: 1px solid $color-border;
      @extend %title-bloc; }
    &--no-border {
      &:last-child {
        border-bottom: 1px solid $color-border; }
      >td {
        border-bottom: none !important; } } }

  th, td {
    padding: 10px;
    border: 1px solid $color-border; }

  tr {
    td {
      &:first-child {
        padding-left: 20px; }
      &:not(:first-child) {
        font-weight: $font-medium;
        font-size: 14px; } } }

  td {
    border-top: none !important;
    &:not(:first-child) {
      text-align: center; } }


  @media #{$media-tablet-landscape} {
    .table__arrow {
      display: none; } }

  @media #{$media-medium} {
    tbody {
      tr:not(.table__line--responsive) {
        font-size: 14px;
        td {
          &:first-child {
            display: none; } } }
      tr {
        td {
          width: 50%;
          &:not(:first-child) {
            font-weight: $font-regular; } } } }

    colgroup {
      col {
        width: auto !important; } }

    .table__header {
      .table__badge {
        width: 30px;
        margin-right: 10px; }
      h2 {
        font-size: 20px; } }

    .table__line--responsive {
      background: rgba($color-border, 0.3);
      display: table-row;
      font-size: 14px;
      font-weight: $font-medium;
      h3 {
        font-size: 18px;
        display: flex;
        align-items: center;
        justify-content: center; } }

    .table__line--title {
      display: none; }

    .table__col-selected {
      background: none !important; } } }

.check-mark {
  border-radius: 50%;
  width: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  @mixin size ($size) {
    height: $size;
    width: $size;
    min-width: $size;
    min-height: $size; }

  &--checked {
    @include size(18px);
    background: $color-complementary;
    >svg {
      width: 60%;
      fill: white; } }

  &--uncheked {
    @include size(8px);
    border: 1px solid $color-light-grey; } }

.subscription {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  input {
    margin-bottom: 20px;
    color: $color-grey; }

  &__button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .subscription__coupon {
      $size: 20px;
      position: absolute;
      right: 0;
      cursor: pointer;
      width: $size;
      height: $size;
      > svg {
        width: $size;
        height: $size;
        fill: $color-grey; } } } }
