@import "src/style/partials";

$padding: 20px;

%kpiCard {
  height: 162px;
  width: 257px;
  background: white;
  border: 2px solid $color-border;
  border-radius: 6px;
  overflow: hidden; // so the button has a border radius
  position: relative;
  transition: box-shadow 0.3s ease;
  padding: $padding;
  box-sizing: border-box;
  display: grid;
  grid-template-rows: 60px 1fr;
  grid-template-columns: 60px 2fr;
  grid-template-areas: "icon kpi" "desc desc";
  grid-gap: 5px;
  //&:hover
 }  //  box-shadow: 0 10px 15px 0 rgba(0,0,0,0.1)

.kpiCard--disabled {
  @extend %kpiCard;
  opacity: 0.6;
  .kpiCard__desc {
    text-align: center; } }

.kpiCard {
  @extend %kpiCard;

  &--clickable {
    cursor: pointer;
    border-color: $color-complementary;
    border-style: dashed;
    &:hover {
      border-style: solid;
      svg {
        transform: translateX(5px); } }

    svg {
      transition: transform 0.1s ease-in-out;
      position: absolute;
      bottom: 15px;
      right: 20px;
      width: 15px;
      fill: $color-complementary; } }

  &__icon {
    grid-area: icon;
    display: flex;
    justify-content: center;
    align-items: center; }

  &__kpi {
    grid-area: kpi;
    line-height: 1;
    .kpiCard__number {
      display: flex;
      align-items: center;
      overflow-wrap: break-word;
      font-size: 30px;
      font-weight: $font-semi-bold;
      color: $color-complementary;
      >* {
        &:nth-child(1) {
          margin-left: 5px; } } }

    .kpiCard__text {
      font-size: 14px;
      line-height: 1.2;
      font-weight: $font-regular; } }

  &__desc {
    grid-area: desc;
    display: grid;
    align-items: flex-end;
    font-size: 15px;
    color: $color-blueish-grey; } }

$base-color: #F4F4F4;
$shine-color: rgba(229,229,229,0.8);
$animation-duration: 2.0s;
$icon-offset: 50 + 5;

@mixin background-gradient {
  background-image: linear-gradient(90deg, $base-color 0px, $shine-color 40px, $base-color 80px);
  background-size: 600px; }

@keyframes shine-icon {
  0% {
    background-position: -100px; }
  40%, 100% {
    background-position: 148px + $icon-offset; } }

@keyframes shine-lines {
  0% {
    background-position: -(100px + $icon-offset); }
  40%, 100% {
    background-position: 148px; } }

@keyframes shine-desc {
  0% {
    background-position: -100px; }
  40%, 100% {
    background-position: 217px; } }

.skeleton {
  @extend %kpiCard;

  &__icon {
    grid-area: icon;
    width: 50px;
    height: 50px;
    margin: auto;
    border-radius: 50%;
    @include background-gradient;
    animation: shine-icon $animation-duration infinite ease-out; }

  &__kpi {
    grid-area: kpi;
    display: flex;
    flex-direction: column;
    div {
      border-radius: 6px;
      @include background-gradient;
      animation: shine-lines $animation-duration infinite ease-out; }
    div:first-child {
      height: 30px; }
    div:last-child {
      margin-top: 5px;
      height: 17px; } }

  &__desc {
    grid-area: desc;
    border-radius: 6px;
    @include background-gradient;
    animation: shine-desc $animation-duration infinite ease-out; } }

