@import "../../../../style/partials";

.container {
  padding: 20px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    color: $color-complementary;
    align-self: flex-start;
  }

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    label {
      textarea {
        min-height: 100px;
      }
    }

    button {
      margin: 30px 0 10px 0;
    }
  }
}

.month {
  margin: 30px 0;
  width: 100%;
  box-sizing: border-box;
  padding: 20px 30px;
  background: rgba($color-complementary, 0.1);
  border-radius: 6px;
  h4 {
    color: $color-dark-grey;
    margin-bottom: 10px;
  }
  span {
    color: $color-dark-grey;
    font-weight: $font-semi-bold;
    font-size: 21px;
  }
  input[type="number"] {
    font-size: 21px;
    width: 50px;
    color: $color-dark-grey;
    font-weight: $font-semi-bold;
    border: 1px solid $color-light-grey;
    border-radius: 3px;
  }
}

.article {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  flex-direction: column;
  margin: 30px 0;
  padding: 20px 30px;
  background: rgba($color-complementary, 0.1);
  border-radius: 6px;
  h4 {
    color: $color-dark-grey;
    margin-bottom: 5px;
  }
  a {
    line-height: 1.4;
    text-decoration: underline;
  }
}

.configuration {
  margin-top: 30px;
}

.information {
  box-sizing: border-box;
  font-size: 14px;
  background: rgba($color-secondary, 0.2);
  color: #c38d30;
  border-radius: 6px;
  padding: 10px 20px;
  margin-top: 10px;
  width: 100%;
}

@media #{$media-tablet-portrait} {
  .container {
    padding: 0;
  }
  .month {
    max-width: 100%;
    align-self: flex-start;
    text-align: left;
  }
}
