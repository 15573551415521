@import "../../../style/partials";

.container {
  background: white;
  border: 2px solid $color-border;
  border-radius: 6px;
  display: flex;
}

.campaign {
  box-sizing: border-box;
  padding: 15px 30px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__date {
    margin-top: 5px;
    font-size: 12px;
    color: $color-blueish-grey;
    span {
      font-weight: $font-semi-bold;
    }
  }

  &__current {
    width: 100%;
    display: flex;
    align-items: center;
  }

  &__status {
    line-height: 1;
    font-size: 12px;
    font-weight: $font-medium;
    margin-left: 20px;
    &--done {
      color: $color-primary;
    }
    &--running {
      color: $color-complementary;
    }
    &--pending {
      color: $color-secondary;
    }
  }

  &__left {
    display: flex;
    flex-direction: column;
  }

  &__right {
    display: flex;
    flex-direction: column;
    text-align: right;
    font-size: 14px;

    p:first-child {
      font-weight: $font-medium;
    }
    p:last-child {
      font-size: 14px;
      color: $color-blueish-grey;
    }
  }
}

.cta {
  box-sizing: border-box;
  background: $color-primary;
  padding: 0 30px;
  color: white;
  font-weight: $font-medium;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  cursor: pointer;
  min-width: 220px;
  border-radius: 0 4px 4px 0;

  &:hover {
    img {
      transform: translateX(10px);
      opacity: 0.5;
    }
  }

  &__content {
    width: 100%;
    display: flex;
    font-size: 14px;
    justify-content: space-between;
    align-items: center;

    img {
      transition: transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1),
        opacity 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
      filter: brightness(0) invert(1);
      width: 20px;
      height: 20px;
    }
  }
}

@media (max-width: 1000px) {
  .container {
    flex-direction: column;
  }
  .cta {
    min-width: 100%;
    min-height: 80px;
    border-radius: 0 0 4px 4px;
    p > br {
      display: none;
    }
  }
}

@media (max-width: 550px) {
  .campaign__left {
    width: 100%;
  }
  .campaign__right {
    display: none;
  }
  .campaign__current {
    p {
      margin-left: auto;
    }
  }
}

@media (max-width: 375px) {
  .campaign__current {
    flex-direction: column;
    align-items: flex-start;
    p {
      margin-top: 10px;
      margin-left: 0;
    }
  }
}
