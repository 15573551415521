@import "src/style/partials";

.wrapper {
  background: white;
  padding: 50px 0 30px 0;
  border-top: 1px solid $color-border;
  @media #{$media-max-width} {
    padding: 50px 20px 30px 20px; }

  .container {
    display: grid;
    grid-template-columns: 250px 1fr;
    grid-template-rows: auto 1fr;
    max-width: $max-width;
    margin: 0 auto;
    grid-column-gap: 80px;
    grid-row-gap: 20px;
    grid-template-areas: "image text" ". price";
    @media #{$media-max-width} {
      grid-gap: 20px;
      grid-template-columns: 1fr;
      grid-template-rows: 100px auto auto;
      grid-template-areas: "image" "text" "price"; }

    &__image {
      grid-area: image;
      display: flex;
      align-items: center;
      justify-content: center;
      >svg {
        stroke: $color-complementary;
        width: 100%;
        @media #{$media-max-width} {
          height: 100%; } } }

    &__text-container {
      grid-area: text;
      .container__title {
        @media #{$media-max-width} {
          text-align: center; }
        @media #{$media-medium} {
          font-size: 20px; } }
      .container__subtitle {
        font-weight: normal;
        font-size: 18px;
        color: $color-light-grey;
        margin-bottom: 20px;
        @media #{$media-max-width} {
          text-align: center; } }
      .container__desc {
        text-align: justify;
        margin-bottom: 30px;
        @media #{$media-max-width} {
          margin-bottom: 0; }
        @media #{$media-medium} {
          font-size: 14px; } } }

    &__price {
      grid-area: price;
      display: flex;
      align-items: center;
      font-weight: $font-semi-bold;
      height: 40px;
      font-size: 40px;
      @media #{$media-max-width} {
        display: none; } }

    &__price--complete {
      grid-area: price;
      height: 50px;
      display: flex;
      align-items: center;
      font-weight: $font-semi-bold;
      color: $color-secondary;
      font-size: 16px;
      @media #{$media-max-width} {
        text-align: center;
        justify-content: center; } } } }

