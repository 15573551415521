@import "src/style/partials";

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  max-width: 700px;
  padding: 50px;
  @media #{$media-max-width} {
    padding: 20px; }
  h3 {
    margin-bottom: 20px; }

  &__desc {
    text-align: justify;
    font-size: 14px;
    color: $color-light-grey; } }

.wrapper {
  &--disabled {
    opacity: 0.1;
    pointer-events: none; } }

.subscriptionContainer {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;

  p {
    max-width: 500px;
    font-size: 20px;
    font-weight: $font-medium;
    span {
      background: $color-secondary;
      padding: 2px 12px;
      color: white;
      border-radius: 6px;
      text-transform: uppercase; } }

  button {
    margin-top: 50px; } }
