@charset "UTF-8";
.Toastify__toast {
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.Toastify__toast--success {
  background: #27A49B; }

.Toastify__toast--error {
  background: #F24D4B; }

.container {
  max-width: 920px;
  margin: 20px auto 50px auto;
  padding: 20px; }

section:not(:last-child) {
  margin-bottom: 60px; }

section h3 {
  margin-bottom: 10px; }

section p {
  line-height: 2; }

section p + p {
  margin-top: 10px; }

.intro {
  display: flex;
  flex-wrap: wrap;
  margin-right: -30px;
  margin-top: -30px; }
  .intro > div {
    padding-top: 30px;
    padding-right: 30px; }
  .intro__img {
    position: relative;
    flex: 1 260px;
    height: min-content;
    margin-top: 10px; }
    .intro__img img {
      display: block;
      box-sizing: border-box;
      width: 100%;
      border: 2px solid #F2F2F2; }
    .intro__img span {
      padding-right: 30px;
      position: absolute;
      bottom: -20px;
      left: 0;
      right: 0;
      text-align: center;
      font-size: 12px;
      color: #9AA9AB; }
  .intro__text {
    flex: 1.5 300px;
    display: flex;
    flex-direction: column; }
    .intro__text button {
      align-self: flex-start;
      margin-top: 20px; }

.citation {
  display: flex;
  justify-content: center; }
  .citation blockquote {
    max-width: 650px;
    margin: 0;
    font-size: 35px;
    color: #27A49B;
    font-weight: 600;
    text-align: center;
    position: relative; }
    .citation blockquote:before {
      position: absolute;
      font-size: 150px;
      color: #e9e9e9;
      z-index: -1;
      content: "“";
      top: -50px;
      left: -20px; }
    .citation blockquote:after {
      position: absolute;
      font-size: 150px;
      color: #e9e9e9;
      z-index: -1;
      content: "”";
      bottom: -160px;
      right: -20px; }

.highlight {
  color: #27A49B;
  font-weight: 600; }

.cta {
  position: relative; }
  .cta__text {
    position: absolute;
    left: 200px;
    bottom: -70px; }
    .cta__text p {
      display: flex;
      align-items: center;
      position: relative;
      font-size: 14px;
      font-weight: 500;
      color: #43464C; }
      .cta__text p::before {
        position: absolute;
        left: -50px;
        top: 0px;
        content: "";
        display: block;
        width: 33px;
        height: 28px;
        background: url("data:image/svg+xml,%0A%3Csvg width='33px' height='28px' viewBox='0 0 33 28' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='17---SEO-LOCAL' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg transform='translate(-822.000000, -731.000000)' fill='%23F24D4B' fill-rule='nonzero' id='Para-1'%3E%3Cg transform='translate(365.000000, 418.000000)'%3E%3Cg id='arrow' transform='translate(452.000000, 312.000000)'%3E%3Cg id='Group-18' transform='translate(21.508519, 14.944761) scale(-1, 1) rotate(-110.000000) translate(-21.508519, -14.944761) translate(13.508519, -4.555239)'%3E%3Cpath id='Line' d='M0.53179585,-0.124221324 L1.4970124,0.137230423 L1.36628652,0.619838697 C0.58805657,3.49287527 0.885523691,8.54720918 2.27785017,15.7419482 C3.30838487,21.0671564 6.06502839,26.5648466 10.556038,32.2333965 L10.199,31.778 L13.1645815,29.145726 L15.7772342,38.8629296 L6.43587972,35.1227296 L9.4495588,32.4436424 C5.05478958,26.8008382 2.33439682,21.2974418 1.29606513,15.9319434 C-0.123384222,8.59704893 -0.428103367,3.41949418 0.401069976,0.35838695 L0.401069976,0.35838695 L0.53179585,-0.124221324 Z'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E") no-repeat center center; }

.promo {
  background-color: white;
  color: #27A49B;
  border: 2px dashed #27A49B;
  margin-bottom: 50px !important;
  text-align: center;
  border-radius: 6px;
  padding: 20px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.14); }
  .promo p {
    color: #43464C; }

@media (max-width: 1050px) {
  .cta__text {
    display: none; } }

@media (max-width: 650px) {
  section:not(:last-child) {
    margin-bottom: 50px; }
  .citation blockquote {
    font-size: 21px; } }
