@import "../../../style/partials";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  max-width: 700px;
  padding: 50px 20px;
  >p {
    margin-top: 10px;
    color: $color-blueish-grey; }
  >button {
    margin-top: 20px;
    align-self: center; } }

.budget {
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
  max-width: 350px;
  width: 100%;
  margin-top: 20px;

  &__slider {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
    p {
      font-size: 12px;
      margin-bottom: 10px;
      color: $color-light-grey; }
    input {
      width: 100%; } }

  &__result {
    display: flex;
    flex-direction: column;
    border: 1px solid $color-border;
    border-radius: 3px;
    padding: 8px 30px;
    >p {
      font-size: 12px;
      color: $color-blueish-grey;
      font-weight: $font-medium; }
    >div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: max-content;
      p:first-child {
        color: $color-light-grey; }
      svg {
        width: 15px;
        height: 15px;
        stroke: $color-grey;
        margin: 0 10px; }
      p:last-child {
        font-weight: $font-medium;
        color: $color-complementary; } } } }

.question {
  margin-top: 10px;
  align-self: center; }

@media #{$media-phone} {
  .budget {
    max-width: 100%;
    &__result {
      padding: 8px 20px; } } }

@media #{$media-max-width} {
  .container {
    padding: 20px; } }
