@import "src/style/partials";

.button {
  font-weight: $font-semi-bold;
  font-size: 14px;
  border: none;
  color: $color-primary;
  padding: 0;
  cursor: pointer;
  background: none;
  display: flex;
  align-items: center;
  &:focus {
    outline: 0; }
  >svg {
    margin-left: 10px;
    fill: $color-primary;
    width: 15px; }
  >span,svg {
    pointer-events: none; }

  &--cta {
    background: $color-primary;
    color: white;
    padding: 10px 20px;
    border-radius: 360px;
    >svg {
      fill: white; }

    &-disabled {
      cursor: default;
      background: $color-very-light-grey; } }

  &--disabled {
    cursor: default;
    color: $color-very-light-grey;
    >svg {
      fill: $color-very-light-grey; } } }
