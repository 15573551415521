$color-primary: #F24D4B;
$color-secondary: #EEB656;
$color-complementary: #27A49B;

$color-dark-grey: #43464C;
$color-grey: #666B73;
$color-blueish-grey: #78909C;
$color-light-grey: #9AA9AB;
$color-very-light-grey: #C3C3C3;
$color-text-menu: #C3C8D0;

$color-border: #F2F2F2;
$color-background: #FAFDFD;
