@import "src/style/partials";

.menu {
  height: 100%;
  width: 100%;
  border-right: 1px solid $color-border;
  display: grid;
  grid-template-rows: 80px 1fr;
  grid-template-areas: "top" "items";
  font-weight: normal;
  overflow: hidden;
  @media #{$media-tablet-portrait} {
    grid-template-rows: 70px;
    grid-template-areas: "top"; }

  &__top {
    grid-area: top;
    padding: 0 20px;
    background: $color-primary;
    color: white;
    display: flex;
    align-items: center;
    font-size: 11px;
    letter-spacing: 1.5px;

    .top__logo {
      $size: 40px;

      // TODO:  remove when logo is here
      color: $color-primary;
      display: flex;
      cursor: pointer;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-weight: $font-semi-bold;
      text-transform: uppercase;
      // --------------------------------
      background: white;
      border-radius: 50%;
      min-width: $size;
      min-height: $size;
      overflow: hidden;
      margin-right: 10px;
      >img {
        margin: 0 auto;
        height: 100%;
        width: auto; } }

    .top__icons {
      display: none;
      margin-left: auto;
      cursor: pointer;
      height: 100%;
      >a { // Link (shopping cart)
        display: flex;
        align-items: center; }
      >* {
        &:not(:last-child) {
          margin-right: 25px; } }
      svg {
        fill: white;
        width: 25px; }
      @media #{$media-tablet-portrait} {
        display: flex;
        align-items: center; } } }

  %items {
    background: white;
    padding: 50px 0 0 20px;
    box-sizing: border-box;
    @media #{$media-tablet-portrait} {
      overflow-y: scroll; // has to be scroll, not auto
      -webkit-overflow-scrolling: touch; // smooth scroll for iOS
      height: calc(100vh - 70px);
      width: 100%;
      padding: 20px 20px 70px 20px;
      flex-direction: column; } }

  &__items {
    grid-area: items;
    @extend %items;
    @media #{$media-tablet-portrait} {
      display: none; }
    &--toggle-show {
      @extend %items; } }

  %item {
    height: 50px;
    display: flex;
    align-items: center;
    color: $color-grey;
    fill: $color-grey;
    cursor: pointer;
    font-size: 14px;
    font-weight: $font-medium;
    >svg {
      width: 20px;
      margin-right: 10px; }
    &:hover {
      color: $color-primary;
      >svg {
        fill: $color-primary; } }
    @media #{$media-tablet-portrait} {
      font-size: 18px;
      height: 75px;
      width: 100%;
      display: flex;
      justify-content: center; } }

  &__item {
    @extend %item;
    &--selected {
      color: $color-primary !important;
      >svg {
        fill: $color-primary !important; } } }

  .item__badge {
    border: 1px solid $color-secondary;
    color: white;
    background: $color-secondary;
    border-radius: 20px;
    padding: 2px 10px;
    font-size: 10px;
    text-transform: uppercase;
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: $font-semi-bold; }

  &__item--disabled {
    @extend %item;
    cursor: default !important;
    color: $color-text-menu !important;
    fill: $color-text-menu !important;
    &:hover {
      color: $color-text-menu !important;
      >svg {
        fill: $color-text-menu !important; } }
    .item__badge {
      opacity: 0.6; } }

  &__logo {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    @media #{$media-tablet-portrait} {
      display: none; }
    >svg {
      width: 50%;
      fill: $color-primary; } } }

.mobile-profile-buttons {
  display: none;
  @media #{$media-tablet-portrait} {
    display: flex;
    flex-direction: column;
    align-items: center; }

  &__item {
    @extend %item; }

  &__disconnect {
    z-index: 20;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 0;
    background: $color-primary;
    color: white;
    height: 50px;
    width: 100%;
    text-transform: uppercase;
    font-weight: $font-medium; } }
