@import "src/style/partials";

%competitor {
  font-weight: $font-medium; }

.competitor {
  &--easy {
    @extend %competitor;
    color: $color-complementary; }

  &--medium {
    @extend %competitor;
    color: $color-secondary; }

  &--hard {
    @extend %competitor;
    color: $color-primary; } }
