@import "../../../style/partials";

.container {
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    font-size: 14px; }

  &rdv {
    display: flex; }

  .form {
    display: flex;
    flex-direction: column;
    align-items: center;
    &__input-container {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin: 0 -25px;
      >label {
        margin: 20px 25px;
        flex: 1 0 300px; } }
    &__button {
      margin: 20px auto 0 auto; } } }

.question {
  margin-top: 10px; }
