@import "src/style/partials";

// trick to change text on hover with css
.container {
  font-size: 14px;
  font-weight: $font-light;
  cursor: pointer;
  font-style: italic;
  &:hover {
    .added {
      display: none; }
    .remove {
      display: inline; } }

  .added {
    display: inline;
    color: $color-complementary;
    @media #{$media-max-width} { // because can not hover on mobile so added is not shown
      display: none; } }
  .remove {
    font-style: normal;
    color: $color-light-grey;
    display: none;
    @media #{$media-max-width} { // because can not hover on mobile so remove is shown
      display: block; } } }
