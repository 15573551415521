@import "src/style/partials";

.container {
  width: 100%;
  border: 2px solid $color-border;
  background: white;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px; }

.left {
  display: flex;
  flex-direction: column;
  padding: 20px;

  span {
    color: $color-blueish-grey;
    font-size: 14px;
    margin-top: 5px; } }

.budget {
  padding: 20px 30px;
  border-left: 1px solid $color-border;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    background: rgba($color-blueish-grey, 0.05);
    .budget__edit {
      border: 1px solid $color-light-grey;
      svg {
        stroke: $color-grey; } } }

  &__num {
    display: flex;
    flex-direction: column;
    margin-right: 50px;
    font-weight: $font-medium;
    span:first-child {
      font-size: 12px;
      color: $color-blueish-grey; }
    span:last-child {
      width: max-content;
      color: $color-complementary; } }

  &__edit {
    min-height: 40px;
    min-width: 40px;
    border-radius: 50%;
    border: 1px solid $color-very-light-grey;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background: rgba($color-very-light-grey, 0.1); }
    svg {
      stroke: $color-light-grey;
      width: 14px;
      height: 14px; } } }

@media #{$media-medium} {
  .container {
    flex-direction: column; }

  .budget {
    border-left: none;
    border-top: 1px solid $color-border;
    justify-content: space-between; } }
