@import "src/style/partials";

.container {
  height: 100%;
  width: 100%;
  max-width: 250px;
  margin: 20px auto 0 auto;
  display: flex;
  flex-direction: column;
  text-align: center;

  &__button {
    margin: 40px auto 0 auto; } }
